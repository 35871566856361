import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {Button} from '@mui/material'
import {Modal} from 'react-bootstrap'
import {StyledTableCell, StyledTableRow} from '.'
import MobileMakePaymentsTable from './MobileTable'
import TableCellText from './TableCellText'
import Spinner from 'react-bootstrap/Spinner'
import EnterCreditCard from '../../pages/forms/EnterCreditCard'

export interface MakePayment {
  id: number
  policy: string
  duedate: string
  amount: string
  prefix: string
}

interface MakePaymentsTableProps {
  displayForMobile?: boolean
}

export default function MakePaymentsTable({displayForMobile = false}: MakePaymentsTableProps) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Member.actions.fetchPaymentsOutstanding())
  }, [dispatch])

  // Define some state
  const [show, setShow] = useState<boolean>(false)
  const [currentAmount, setcurrentAmount] = useState<string>('')
  const [prefix, setPrefix] = useState<string>('')

  // Map Outstanding Payments to Redux State
  const paymentsOutstanding = useSelector((state: any) => state.member.PaymentsOutstanding)
  const paymentsOutstandingCheck = useSelector((state: any) => state.member.PaymentsOutstandingCheck)

  function handleClose() {
    setShow(false)
    dispatch(Member.actions.fetchPaymentsOutstanding())
  }

  const payPolicy = (policy: MakePayment) => {
    setPrefix(policy.prefix)
    setcurrentAmount(parseFloat(policy.amount).toFixed(2))
    setShow(true)
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  // Check if data still be retrieved
  if (Object.keys(paymentsOutstanding).length === 0 && !paymentsOutstandingCheck) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <p className='claim-text mb-5'>
          Please wait while we retrieve your outstanding payments... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </p>
      </span>
    )
  }

  // No data is found
  if (Object.keys(paymentsOutstanding).length === 0 && paymentsOutstandingCheck) {
    return (
      <p className='claim-text mb-10'>
        <strong>No amounts outstanding that can be paid via credit/debit card</strong>
      </p>
    )
  }

  if (paymentsOutstanding) {
    if (displayForMobile) {
      return <MobileMakePaymentsTable incentiveType='MakePayment' onClickDownload={payPolicy} data={paymentsOutstanding} />
    } else {
      return (
        <>
          <TableContainer component={Paper} style={{minHeight: 500}}>
            <Table sx={{minWidth: 700}} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Policy</StyledTableCell>
                  <StyledTableCell>Due Date</StyledTableCell>
                  <StyledTableCell>Amount</StyledTableCell>
                  <StyledTableCell>Pay</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentsOutstanding.map((row: MakePayment, i: number) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component='th' scope='row' style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                      <TableCellText text={row.policy} />
                    </StyledTableCell>
                    <StyledTableCell>{row.duedate}</StyledTableCell>
                    <StyledTableCell>{currencyFormatter.format(Number(row.amount))}</StyledTableCell>
                    <StyledTableCell>
                      <div style={{width: '120px'}}>
                        <Button variant='contained' size='medium' disableElevation className='rounded-full' onClick={() => payPolicy(row)}>
                          Pay via Card
                        </Button>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Modal show={show} onHide={handleClose} dialogClassName='edit-code-dialog-modal'>
            <Modal.Header closeButton className='edit-code-modal'>
              <Modal.Title>Enter Payment Details</Modal.Title>
            </Modal.Header>
            <Modal.Body className='edit-code-modal'>
              <EnterCreditCard closeModal={handleClose} currentAmount={currentAmount} prefix={prefix} />
            </Modal.Body>
          </Modal>
        </>
      )
    }
  } else {
    return (
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 700}} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>You have no Payments Outstanding.</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
    )
  }
}
