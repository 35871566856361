import {useSelector} from 'react-redux'
import clsx from 'clsx'

interface DashboardBoxProps {
  children: any
  hasMobileFullWidth?: boolean
}
/** Says to contact the IT support if the user hasn't access. */
export default function DashboardBox({children, hasMobileFullWidth}: DashboardBoxProps) {
  // @ts-ignore Add TS type
  const userRole = useSelector((state) => state.auth.roleDesc)
  // @ts-ignore Add TS type
  const userName = useSelector((state) => state.auth.userName)
  return <div className={clsx('dashboard-box', 'min-h-dvh', 'w-full', hasMobileFullWidth ? 'mobileFullWidth' : 'px-[30px]')}>{children}</div>
}
