/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {sidebarData} from '../SideBarButton'
import {sidebarDataNonFSBP} from '../SideBarButton'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {useSelector} from 'react-redux'

interface SidebarMenuMainProps {
  isMobile?: boolean
  mobileOnClose?: () => void
}

const SidebarMenuMain = ({isMobile, mobileOnClose}: SidebarMenuMainProps) => {
  const intl = useIntl()
  const FSBP = useSelector((state: any) => state.member.FSBP)
  const disability = useSelector((state: any) => state.member.Disability)

  const renderMenuItems = () => {
    const unreadCount: string = useSelector((state: any) => state.auth.Unread)

    const menuItemSource = FSBP ? sidebarData : sidebarDataNonFSBP

    return menuItemSource.map((item, index) => {
      if (item.subItems && item.subItems.length > 0) {
        return (
          <SidebarMenuItemWithSub key={`menu-item-${index}`} to={item.href} title={intl.formatMessage({id: item.text})} icon={item.icon} fontIcon={item.icon}>
            {item.subItems.map((subItem, index) => {
              return (
                <SidebarMenuItem
                  key={`menu-item-${index}`}
                  to={subItem.href}
                  title={intl.formatMessage({id: subItem.text})}
                  icon={subItem.icon}
                  fontIcon={subItem.icon}
                  hasSeparator={false}
                  className={'pl-5'}
                  refresh={subItem.refresh}
                  disability={disability && Object.keys(disability.Primary).length > 0}
                  onClick={isMobile ? mobileOnClose : undefined}
                />
              )
            })}
          </SidebarMenuItemWithSub>
        )
      } else {
        const badge = item.text === 'Messages' ? unreadCount : item.badge
        return (
          <SidebarMenuItem
            key={`menu-item-${index}`}
            to={item.href}
            title={intl.formatMessage({id: item.text})}
            icon={item.icon}
            fontIcon={item.icon}
            badge={isMobile ? undefined : badge}
            hasSeparator={true}
            disability={disability && disability.length > 0}
            onClick={isMobile ? mobileOnClose : undefined}
          />
        )
      }
    })
  }

  return <>{renderMenuItems()}</>
}

export {SidebarMenuMain}
