import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ThemeProvider, StyledEngineProvider} from '@mui/material/styles'
import {ThemeModeProvider} from '../_metronic/partials'
import {Flip, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MuiTheme from '../_metronic/assets/mui/MuiTheme'

const App = () => {
  console.log('Rendering: App.tsx *******************************************')

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={MuiTheme}>
            <I18nProvider>
              <LayoutProvider>
                <ThemeModeProvider>
                  <AuthInit>
                    <Outlet />
                    <MasterInit />
                  </AuthInit>
                </ThemeModeProvider>
              </LayoutProvider>
            </I18nProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Suspense>
      <ToastContainer transition={Flip} position='bottom-right' autoClose={2000} hideProgressBar={true} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  )
}

export {App}
