import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import IncentiveForm from '../forms/IncentiveForm.js'

export default function IncentiveFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Submit Your Incentive Claim'}>
        <IncentiveForm />
      </DashboardSection>
    </DashboardBox>
  )
}
