import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {Grid, Button, TextField, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Select, MenuItem, Radio, RadioGroup, Box} from '@mui/material'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import PlacesAutoComplete from '../../components/AddressAutoComplete'
import getDiff from '../../components/getDiff'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
import {styled} from '@mui/material/styles'
import TableCellText from '../../components/Tables/TableCellText'

// import Spinner from 'react-bootstrap/Spinner'
import {Formik} from 'formik'
import * as Member from '../../store/ducks/member.duck'
import * as Yup from 'yup'
import {States} from '../../components/Misc/States'
import {MuiTelInput} from 'mui-tel-input'
import {FormLabel} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'

const cellHeadStyle = {
  backgroundColor: '#F7FCFF',
  color: '#121619',
  fontFamily: 'Roboto',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: 1.1
}

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: cellHeadStyle,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    backgroundColor: 'white',
    border: 0,
    padding: 5,
    marginLeft: 10
  }
}))

export const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export default function ProfileForm() {
  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    dispatch(Member.actions.fetchMemberProfile())
  }, [dispatch])

  useEffect(() => {
    dispatch(Member.actions.fetchCountries())
  }, [dispatch])

  const profileInit = {
    first_name: '',
    middle_i: '',
    last_name: '',
    address: '',
    address_1: '',
    address_1_part1: '',
    address_1_part2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    country_of_residence: '',
    email: '',
    fax_phone: '',
    home_phone: '',
    i_phone: '',
    mob_phone: '',
    go_green: '',
    sal: '',
    work_ext_phone: '',
    work_phone: '',
    fsbpBrochure: '',
    username: '',
    password: '',
    recoveryEmail: '',
    aip_gogreen: '',
    primary_phone: '',
    secondary_phone: '',
    primary_type: 'Home',
    secondary_type: 'Other',
    alt_email: ''
  }

  const profile = useSelector((state) => state.member.Profile)
  const uspsProfile = useSelector((state) => state.member.USPSProfile)
  const [uspsUpdate, setUspsUpdate] = useState(false)
  const [uspsUpdateInProgress, setUspsUpdateInProgress] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  // const uspsProfileModal = useSelector((state) => state.member.USPSProfileModal)
  const addresslocked = useSelector((state) => state.auth.addressLocked)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)
  const countries = useSelector((state) => state.member.Countries)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [profileDefault, setProfileDefault] = useState(profileInit)
  const [longName, setLongName] = useState()
  // const [oldProfileDefault, setOldProfileDefault] = useState(profile)

  function Savesubmit(data) {
    // Submit form
    // Open Submitting modal
    setOpen(true)
    setIsLoading(true)
    // Get diff of changes used for Auditing in home
    var data2 = {...data, diff: getDiff(profile, data)}
    if (!uspsProfile || Object.keys(uspsProfile).length === 0) {
      data2['verified'] = '0'
    } else {
      data2['verified'] = '1'
    }
    // console.log(data2);
    dispatch(Member.actions.putMemberProfile(data2))
    setUspsUpdateInProgress(false)
    setDataChanged(true)
    //
    setProfileDefault(data)
    // console.log('Profile Default')
    // console.log(data)
  }

  function finishedEditing() {
    // Clear any USPS data
    dispatch(Member.actions.clearUSPSProfile())
    // Close Submitting Modal
    setOpen(false)
    //
    setDataChanged(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  function cleanupUSPSEditing() {
    // Clear any USPS data
    dispatch(Member.actions.clearUSPSProfile())
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
    //
    setDataChanged(false)
    // Send the member to home
    navigate('/home')
  }

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  function ignoreUPSPSuggestion() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  function allowMoreUSPSEditing() {
    setUspsUpdate(true)
    setUspsUpdateInProgress(true)
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  useEffect(() => {
    if (uspsUpdate && uspsUpdateInProgress) {
      //
      // Do the USPS fields need to be copied over the fields entered by the member?
      if (uspsProfile && uspsProfile.uspsUpdateAddress && uspsProfile.uspsUpdateAddress) {
        // Update fields with new information
        var updatedProfile = profileDefault
        updatedProfile.address = uspsProfile.uspsAddress
        updatedProfile.address_1 = uspsProfile.uspsAddress_1
        updatedProfile.city = uspsProfile.uspsCity
        updatedProfile.state = uspsProfile.uspsState
        updatedProfile.zip = uspsProfile.uspsZip
        setProfileDefault(updatedProfile)
        // console.log('Updated based on USPS')
        // console.log(updatedProfile)
      }
      //
      setUspsUpdate(false)
    }
  }, [dispatch, uspsUpdate, setUspsUpdate, uspsProfile, profileDefault, setProfileDefault, uspsUpdateInProgress])

  // console.log('ProfileDefault')
  // console.log(profileDefault)

  useEffect(() => {
    if (profile !== profileDefault && !uspsUpdateInProgress && !dataChanged) {
      //
      setProfileDefault(profile)
      if (profile && profile.middle_i) {
        if (profile.middle_i) {
          setLongName(profile.sal + ' ' + profile.first_name + ' ' + profile.middle_i + ' ' + profile.last_name)
        } else {
          setLongName(profile.sal + ' ' + profile.first_name + ' ' + profile.last_name)
        }
      } else {
        setLongName(profile.sal + ' ' + profile.first_name + ' ' + profile.last_name)
      }
      // console.log('Profile State Updated')
    }
  }, [dispatch, uspsUpdate, setUspsUpdate, uspsProfile, profileDefault, setLongName, profile, setProfileDefault, uspsUpdateInProgress, dataChanged])

  const phoneTooltip = (props) => (
    <Tooltip id='button-tooltip' {...props}>
      Click the flag icon to change countries
    </Tooltip>
  )

  const validationSchema = Yup.object().shape({
    email: Yup.string().min(2, 'Too Short').max(200, 'Too Long').email('Enter a valid email').required('Email is required'),
    alt_email: Yup.string().min(2, 'Too Short').max(200, 'Too Long').email('Enter a valid alternate email or leave blank'),
    address: Yup.string().min(2, 'Too Short').max(255, 'Too Long').required('Address is required'),
    address_1: Yup.string().nullable().max(255, 'Too Long'),
    city: Yup.string().min(2, 'Too Short').max(31, 'Too Long - 30 Character Maximum').required('City is required'),
    state: Yup.string()
      .ensure()
      .when('country', {
        is: '1',
        then: (schema) => schema.required('State is required')
      }),
    zip: Yup.string().min(4, 'Too Short').max(6, 'Too Long').required('Zip is required'),
    country: Yup.string().required('Country is required'),
    country_of_residence: Yup.string().required('Country is required'),
    primary_phone: Yup.string()
      .required('Primary Phone is required')
      .transform((value) => value.replace(/[^\d]/g, ''))
      .min(8, 'Too Short')
      .max(25, 'Too Long')
  })

  function ErrorsNotification({errors, touched}) {
    if (errors && Object.keys(errors).length > 0 && touched && Object.keys(touched).length > 0) {
      return (
        <>
          <Card.Body>
            <div className='errors-list'>
              <h4 className='errors-list-heading'>Please fix the following fields before you submit:</h4>
              <ul>
                {Object.keys(errors).map((msg) => (
                  <React.Fragment key={msg}>{touched[msg] ? <li>{errors[msg]}</li> : null}</React.Fragment>
                ))}
              </ul>
            </div>
          </Card.Body>
        </>
      )
    } else {
      return null
    }
  }

  return (
    <>
      <Grid container gap={3} justify='center'>
        <Grid item xs={12} md={12}>
          Update personal details and preferences here. Save your changes using the button at the bottom of the page.
        </Grid>
        <Grid item xs={12} md={12}>
          <Card className='flex justify-center p-5'>
            <Card.Body>
              <Grid container>
                <Grid item className={'items-center flex'} xs={12} sm={6}>
                  <Typography variant={'h5'} className={'font-bold'}>
                    Security
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={'flex justify-end'}>
                  <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => navigate('/passwordupdateform')}>
                    Change Password
                  </Button>
                </Grid>
              </Grid>
            </Card.Body>
          </Card>
        </Grid>
        <Formik
          enableReinitialize={true}
          initialValues={profileDefault}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            Savesubmit(values)
            actions.setSubmitting(false)
          }}
        >
          {(props) => {
            const {values, errors, touched, handleChange, handleSubmit, setFieldValue} = props
            const change = (name, e) => {
              e.persist()
              handleChange(e)
            }
            return (
              <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
                <Grid container justifyContent='center'>
                  <Row className='mb-5'>
                    <Col xs={12} md={12}>
                      <Card className={'p-5 mt-3'}>
                        <Card.Body>
                          <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xs={12} md={12}>
                              <Typography variant={'h5'} className={'font-bold'}>
                                Personal Details
                              </Typography>
                            </Grid>
                            <Grid flexDirection={'column'} item xs={12} md={12}>
                              <FormLabel className='gray-1-text flex' htmlFor={'longName'}>
                                Name
                              </FormLabel>
                              <TextField
                                id='longName'
                                type='text'
                                variant='outlined'
                                className={'mt-0 w-100'}
                                inputProps={{'aria-label': 'Member Name'}}
                                margin='normal'
                                name='longName'
                                readOnly
                                value={longName}
                                autoComplete='new-password'
                              />
                            </Grid>
                            {addresslocked ? (
                              <Grid style={{marginTop: 25, marginBottom: 25, fontWeight: 600}}>
                                Profile Update is currently under maintenance, please contact the Plan directly for any address updates securely via the Enrollment Update Form
                                <Link
                                  className='nav-link pr-3 pl-0'
                                  to={{
                                    pathname: '/forms',
                                    state: {
                                      requestTypeID: '1003'
                                    }
                                  }}
                                >
                                  <Button variant='outlined' color='secondary' style={{background: '#daeaf5', marginTop: 15}}>
                                    Enrollment Update Form
                                  </Button>
                                </Link>
                              </Grid>
                            ) : (
                              <Grid item xs={12} md={12}>
                                <PlacesAutoComplete currentID={0} currentfulladdress='' setFieldValue={setFieldValue} />
                              </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'address'}>
                                Address
                              </FormLabel>
                              <TextField
                                id='address'
                                type='text'
                                variant='outlined'
                                margin='normal'
                                name='address'
                                className={'mt-0 w-100'}
                                value={values.address || ''}
                                helperText={touched.address ? errors.address : ''}
                                error={touched.address && Boolean(errors.address)}
                                autoComplete='new-password'
                                onChange={change.bind(null, 'address')}
                                inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Address Line'}}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'address_1'}>
                                Unit/Apartment etc.
                              </FormLabel>
                              <TextField
                                id='address_1'
                                type='text'
                                variant='outlined'
                                margin='normal'
                                name='address_1'
                                className={'mt-0 w-100'}
                                value={values.address_1 || ''}
                                helperText={touched.address_1 ? errors.address_1 : ''}
                                error={touched.address_1 && Boolean(errors.address_1)}
                                autoComplete='new-password'
                                inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Unit or Apartment'}}
                                onChange={change.bind(null, 'address_1')}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'city'}>
                                City
                              </FormLabel>
                              <TextField
                                type='text'
                                variant='outlined'
                                margin='normal'
                                id='city'
                                name='city'
                                className={'mt-0 w-75'}
                                value={values.city || ''}
                                helperText={touched.city ? errors.city : ''}
                                error={touched.city && Boolean(errors.city)}
                                autoComplete='new-password'
                                inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'City'}}
                                onChange={change.bind(null, 'city')}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'state'}>
                                State
                              </FormLabel>
                              {/* state */}
                              <Autocomplete
                                id='statelookup'
                                disableClearable
                                options={States}
                                value={States.find((v) => v.id === values.state) || null}
                                onChange={(e, value) => {
                                  setFieldValue('state', value.id)
                                }}
                                getOptionLabel={(option) => option.name}
                                // style={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    type='text'
                                    variant='outlined'
                                    margin='normal'
                                    id='state'
                                    name='state'
                                    className={'mt-0 w-100'}
                                    helperText={touched.state ? errors.state : ''}
                                    error={touched.state && Boolean(errors.state)}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password',
                                      'aria-label': 'State'
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'zip'}>
                                Zip
                              </FormLabel>
                              <TextField
                                id='zip'
                                type='text'
                                variant='outlined'
                                margin='normal'
                                name='zip'
                                className={'mt-0 w-50'}
                                value={values.zip || ''}
                                helperText={touched.zip ? errors.zip : ''}
                                error={touched.zip && Boolean(errors.zip)}
                                autoComplete='new-password'
                                inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Zip Code'}}
                                onChange={change.bind(null, 'zip')}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'country_of_residence'}>
                                Country
                              </FormLabel>
                              {/* countries */}
                              <Autocomplete
                                id='countrylookup'
                                disableClearable
                                options={countries}
                                value={countries.find((v) => v.id === values.country) || null}
                                onChange={(e, value) => {
                                  setFieldValue('country', value.id)
                                }}
                                getOptionLabel={(option) => option.country}
                                // style={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    type='text'
                                    variant='outlined'
                                    margin='normal'
                                    name='country'
                                    className={'mt-0 w-100'}
                                    helperText={touched.country ? errors.country : ''}
                                    error={touched.country && Boolean(errors.country)}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password',
                                      'aria-label': 'Country'
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              {/* country of residence */}
                              <FormLabel className='gray-1-text flex' htmlFor={'country_of_residence'}>
                                Country of Residence
                              </FormLabel>
                              <Autocomplete
                                id='countryofresidencelookup'
                                disableClearable
                                options={countries}
                                value={countries.find((v) => v.id === values.country_of_residence) || null}
                                onChange={(e, value) => {
                                  setFieldValue('country_of_residence', value.id)
                                }}
                                getOptionLabel={(option) => option.country}
                                // style={{ width: 300 }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id={'country_of_residence'}
                                    type='text'
                                    variant='outlined'
                                    margin='normal'
                                    name='country_of_residence'
                                    className={'mt-0 w-100'}
                                    helperText={touched.country_of_residence ? errors.country_of_residence : ''}
                                    error={touched.country_of_residence && Boolean(errors.country_of_residence)}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password',
                                      'aria-label': 'Country of Residence'
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'email'}>
                                Email
                              </FormLabel>
                              <TextField
                                type='text'
                                variant='outlined'
                                margin='normal'
                                name='email'
                                className={'mt-0 w-100'}
                                value={values.email || ''}
                                helperText={touched.email ? errors.email : ''}
                                error={touched.email && Boolean(errors.email)}
                                autoComplete='new-password'
                                inputProps={{'aria-label': 'Email Address'}}
                                InputProps={{classes: {input: 'input-profile'}}}
                                onChange={change.bind(null, 'email')}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormLabel className='gray-1-text flex' htmlFor={'alt_email'}>
                                Alternate Email (Leave Blank if not applicable)
                              </FormLabel>
                              <TextField
                                type='text'
                                variant='outlined'
                                margin='normal'
                                name='alt_email'
                                className={'mt-0 w-100'}
                                value={values.alt_email || ''}
                                helperText={touched.alt_email ? errors.alt_email : ''}
                                error={touched.alt_email && Boolean(errors.alt_email)}
                                autoComplete='new-password'
                                inputProps={{'aria-label': 'Alternate Email Address'}}
                                InputProps={{classes: {input: 'input-profile'}}}
                                onChange={change.bind(null, 'alt_email')}
                              />
                            </Grid>

                            <Grid item xs={9} md={9}>
                              <FormLabel className='gray-1-text flex' htmlFor={'email'}>
                                Primary Phone
                              </FormLabel>
                              <OverlayTrigger placement='top' delay={{show: 250, hide: 400}} overlay={phoneTooltip}>
                                <MuiTelInput
                                  name='primary_phone'
                                  variant='outlined'
                                  margin='normal'
                                  defaultCountry={'us'}
                                  className={'mt-0 w-100'}
                                  placeholder='Enter full International phone number'
                                  value={values.primary_phone || ''}
                                  helperText={touched.primary_phone ? errors.primary_phone : ''}
                                  error={touched.primary_phone && Boolean(errors.primary_phone)}
                                  inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Enter Primary Full International Phone Number'}}
                                  data-cy='user-phone'
                                  autoComplete='OFF'
                                  onChange={(e) => setFieldValue('primary_phone', e)}
                                />
                              </OverlayTrigger>
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <FormControl variant='outlined' style={{minWidth: '100%'}}>
                                <FormLabel className='gray-1-text flex' htmlFor={'primary_phone_type'}>
                                  Type
                                </FormLabel>
                                <Select aria-label='Primary Phone Type' id='primary_phone_type' labelId='primary_phone_type' name='primary_type' value={values.primary_type} onChange={handleChange}>
                                  return (
                                  <MenuItem key='Home' value='Home'>
                                    Home
                                  </MenuItem>
                                  <MenuItem key='Mobile' value='Mobile'>
                                    Mobile
                                  </MenuItem>
                                  <MenuItem key='Work' value='Work'>
                                    Work
                                  </MenuItem>
                                  <MenuItem key='Fax' value='Fax'>
                                    Fax
                                  </MenuItem>
                                  <MenuItem key='Other' value='Other'>
                                    Other
                                  </MenuItem>
                                  )
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={9} md={9}>
                              <FormLabel className='gray-1-text flex' htmlFor={'email'}>
                                Secondary Phone
                              </FormLabel>
                              <OverlayTrigger placement='top' delay={{show: 250, hide: 400}} overlay={phoneTooltip}>
                                <MuiTelInput
                                  name='secondary_phone'
                                  variant='outlined'
                                  margin='normal'
                                  defaultCountry={'us'}
                                  className={'mt-0 w-100'}
                                  placeholder='Enter full International phone number'
                                  value={values.secondary_phone || ''}
                                  helperText={touched.secondary_phone ? errors.secondary_phone : ''}
                                  error={touched.secondary_phone && Boolean(errors.secondary_phone)}
                                  autoComplete='OFF'
                                  inputProps={{classes: {input: 'input-profile'}, 'aria-label': 'Enter Secondary International Phone Number'}}
                                  data-cy='user-phone'
                                  onChange={(e) => setFieldValue('secondary_phone', e)}
                                />
                              </OverlayTrigger>
                            </Grid>
                            <Grid item xs={3} md={3}>
                              <FormControl variant='outlined' style={{minWidth: '100%'}}>
                                <FormLabel className='gray-1-text flex' htmlFor={'secondary_phone_type'}>
                                  Type
                                </FormLabel>
                                <Select
                                  aria-label='Secondary Phone Type'
                                  id='secondary_phone_type'
                                  labelId='secondary_phone_type'
                                  name='secondary_type'
                                  value={values.secondary_type}
                                  onChange={handleChange}
                                >
                                  return (
                                  <MenuItem key='Home' value='Home'>
                                    Home
                                  </MenuItem>
                                  <MenuItem key='Mobile' value='Mobile'>
                                    Mobile
                                  </MenuItem>
                                  <MenuItem key='Work' value='Work'>
                                    Work
                                  </MenuItem>
                                  <MenuItem key='Fax' value='Fax'>
                                    Fax
                                  </MenuItem>
                                  <MenuItem key='Other' value='Other'>
                                    Other
                                  </MenuItem>
                                  )
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Card.Body>
                      </Card>
                      <Card className={'p-5 mt-3'}>
                        <Card.Body>
                          <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xs={12} md={12}>
                              <Typography variant={'h5'} className={'font-bold'}>
                                Communication Preferences: General
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} style={{padding: 10}}>
                              <FormControl component='fieldset'>
                                <RadioGroup aria-label='Communication Preferences' name='go_green' value={values.go_green.toString()} onChange={handleChange}>
                                  <FormControlLabel value='' disabled control={<Radio />} label='Unselected' />
                                  <FormControlLabel value='1' control={<Radio />} label='USPS Mail' />
                                  <FormControlLabel value='2' control={<Radio />} label='Email' />
                                  <FormControlLabel value='3' control={<Radio />} label='Please remove me from your mailings, except those required by regulation.' />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Card.Body>
                      </Card>
                      <Card className={'p-5 mt-3'}>
                        <Card.Body>
                          <Grid container columnSpacing={2} rowSpacing={2}>
                            <Grid item xs={12} md={12}>
                              <Typography variant={'h5'} className={'font-bold'}>
                                Communication Preferences: Ancillary Insurance Programs
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} style={{padding: 10}}>
                              <FormControl component='fieldset'>
                                <RadioGroup aria-label='AIP Communication Preferences' name='aip_gogreen' value={values.aip_gogreen.toString()} onChange={handleChange}>
                                  <FormControlLabel value='' disabled control={<Radio />} label='Unselected' />
                                  <FormControlLabel value='1' control={<Radio />} label='USPS Mail' />
                                  <FormControlLabel value='2' control={<Radio />} label='Email' />
                                  <FormControlLabel value='3' control={<Radio />} label='Both' />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Card.Body>
                      </Card>
                      <Card className={'mt-3'}>
                        <ErrorsNotification errors={errors} touched={touched} />
                        <Card.Body>
                          <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                            <Grid container xs={12} gap={3} justifyContent={'end'}>
                              <Link to='/home'>
                                <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                  Cancel
                                </Button>
                              </Link>
                              <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation disabled={addresslocked || isLoading} style={{background: '#0074A6'}}>
                                Save Changes
                              </Button>
                            </Grid>
                          </Box>

                          <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                            <Grid container xs={12} justifyContent={'space-between'}>
                              <Grid item className={'pr-2'} xs={6}>
                                <Link to='/home'>
                                  <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                    Cancel
                                  </Button>
                                </Link>
                              </Grid>
                              <Grid item className={'pl-2'} xs={6}>
                                <Button
                                  variant='contained'
                                  size={'large'}
                                  className={'w-100'}
                                  color='primary'
                                  type='submit'
                                  disableElevation
                                  disabled={addresslocked || isLoading}
                                  style={{background: '#0074A6'}}
                                >
                                  Save Changes
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Grid>
              </form>
            )
          }}
        </Formik>
      </Grid>

      <Dialog open={open} aria-labelledby='Submitting Claim Please Wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Submitting Profile Updates</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Saved Successfully</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container gap={3} justifyContent={'end'}>
                        <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => finishedEditing()}>
                          Dismiss
                        </Button>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => finishedEditing()}>
                            Dismiss
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <>
                  {returnStatus === 'VERIFY' ? (
                    <>
                      {uspsProfile && uspsProfile.uspsComment && uspsProfile.uspsComment === 'USPS has suggested address changes' ? (
                        <Card>
                          <Card.Body>
                            <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                              <div className='text-danger'>
                                <p>
                                  <strong>{uspsProfile && uspsProfile.uspsComment && uspsProfile.uspsComment}</strong>
                                </p>
                                <p>
                                  <strong>{uspsProfile && uspsProfile.uspsNextAction && uspsProfile.uspsNextAction}</strong>
                                </p>
                              </div>
                            </div>
                            <TableContainer component={Paper}>
                              <Table sx={{minWidth: 400}} aria-label='customized table'>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>&nbsp;</StyledTableCell>
                                    <StyledTableCell>Your Entry</StyledTableCell>
                                    <StyledTableCell>USPS Suggestion</StyledTableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  <StyledTableRow key='Address'>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text='Address' />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={profileDefault && profileDefault.address} />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={uspsProfile && uspsProfile.uspsAddress} />
                                    </StyledTableCell>
                                  </StyledTableRow>

                                  <StyledTableRow key='Address_1'>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text='Unit/Apartment etc.' />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={profileDefault && profileDefault.address_1} />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={uspsProfile && uspsProfile.uspsAddress_1} />
                                    </StyledTableCell>
                                  </StyledTableRow>

                                  <StyledTableRow key='City'>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text='City' />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={profileDefault && profileDefault.city} />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={uspsProfile && uspsProfile.uspsCity} />
                                    </StyledTableCell>
                                  </StyledTableRow>

                                  <StyledTableRow key='State'>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text='State' />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={profileDefault && profileDefault.state} />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={uspsProfile && uspsProfile.uspsState} />
                                    </StyledTableCell>
                                  </StyledTableRow>

                                  <StyledTableRow key='Zip'>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text='Zip' />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={profileDefault && profileDefault.zip} />
                                    </StyledTableCell>
                                    <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                                      <TableCellText text={uspsProfile && uspsProfile.uspsZip} />
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Card.Body>
                          <Card.Body>
                            <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                              <Grid container gap={3} justifyContent={'end'}>
                                <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => ignoreUPSPSuggestion()}>
                                  Keep my changes and Review
                                </Button>
                                <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreUSPSEditing()}>
                                  Use USPS Suggestion and Review
                                </Button>
                              </Grid>
                            </Box>

                            <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                              <Grid container justifyContent={'space-between'}>
                                <Grid item className={'pr-2'} xs={6}>
                                  <Button
                                    variant='contained'
                                    size={'large'}
                                    className={'w-100'}
                                    style={{background: '#E6F5FF', color: '#0074A6'}}
                                    disableElevation
                                    onClick={() => cleanupUSPSEditing()}
                                  >
                                    Keep my changes and Review
                                  </Button>
                                </Grid>
                                <Grid item className={'pl-2'} xs={6}>
                                  <Button
                                    variant='contained'
                                    size={'large'}
                                    className={'w-100'}
                                    color='primary'
                                    disableElevation
                                    style={{background: '#0074A6'}}
                                    onClick={() => allowMoreUSPSEditing()}
                                  >
                                    Use USPS Suggestion and Review
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Card.Body>
                        </Card>
                      ) : (
                        <Card>
                          <Card.Body>
                            <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                              <div className='text-danger'>
                                <p>
                                  <strong>{uspsProfile && uspsProfile.uspsComment && uspsProfile.uspsComment}</strong>
                                </p>
                                <p>
                                  <strong>{uspsProfile && uspsProfile.uspsNextAction && uspsProfile.uspsNextAction}</strong>
                                </p>
                              </div>
                            </div>
                          </Card.Body>
                          <Card.Body>
                            <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                              <Grid container gap={3} justifyContent={'end'}>
                                <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => cleanupUSPSEditing()}>
                                  Return to Home
                                </Button>
                                <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreUSPSEditing()}>
                                  Review Edit and Resubmit
                                </Button>
                              </Grid>
                            </Box>

                            <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                              <Grid container justifyContent={'space-between'}>
                                <Grid item className={'pr-2'} xs={6}>
                                  <Button
                                    variant='contained'
                                    size={'large'}
                                    className={'w-100'}
                                    style={{background: '#E6F5FF', color: '#0074A6'}}
                                    disableElevation
                                    onClick={() => cleanupUSPSEditing()}
                                  >
                                    Return to Home
                                  </Button>
                                </Grid>
                                <Grid item className={'pl-2'} xs={6}>
                                  <Button
                                    variant='contained'
                                    size={'large'}
                                    className={'w-100'}
                                    color='primary'
                                    disableElevation
                                    style={{background: '#0074A6'}}
                                    onClick={() => allowMoreUSPSEditing()}
                                  >
                                    Edit and Resubmit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Card.Body>
                        </Card>
                      )}
                    </>
                  ) : (
                    <Card>
                      <Card.Body>
                        <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                          <div className='text-danger'>
                            <strong>{returnStatus}</strong>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Body>
                        <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                          <Grid container gap={3} justifyContent={'end'}>
                            <Link to='/home'>
                              <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                Return to Home
                              </Button>
                            </Link>
                            <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                              Edit and Resubmit
                            </Button>
                          </Grid>
                        </Box>

                        <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                          <Grid container justifyContent={'space-between'}>
                            <Grid item className={'pr-2'} xs={6}>
                              <Link to='/home'>
                                <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                  Return to Home
                                </Button>
                              </Link>
                            </Grid>
                            <Grid item className={'pl-2'} xs={6}>
                              <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                                Edit and Resubmit
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card.Body>
                    </Card>
                  )}
                </>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we save your changes... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
