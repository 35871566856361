import {SVGProps} from 'react'

export default function HomeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M15 8H17M15 12H17M12 16H7M21 12V6C21 4.89543 20.1046 4 19 4H5C3.89543 4 3 4.89543 3 6V18C3 19.1046 3.89543 20 5 20H12M7 8V12H11V8H7Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
      />
    </svg>

    // <path d="M19.2678 19.2678C19.7202 18.8154 20 18.1904 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20C18.1904 20 18.8154 19.7202 19.2678 19.2678ZM19.2678 19.2678L21 21" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>

    // <svg
    //   xmlns='http://www.w3.org/2000/svg'
    //   viewBox='0 0 24 24'
    //   width='24'
    //   height='24'
    //   className='main-grid-item-icon'
    //   fill='none'
    //   stroke='currentColor'
    //   strokeLinecap='round'
    //   strokeLinejoin='round'
    //   strokeWidth='2'
    //   {...props}
    // >
    //   <circle cx='12' cy='12' r='10' />
    //   <line x1='2' x2='22' y1='12' y2='12' />
    //   <path d='M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z' />
    // </svg>
  )
}
