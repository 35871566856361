import {SVGProps} from 'react'

export default function NotesStarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width='24'
      height='24'
      className='main-grid-item-icon'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      {...props}
    >
      <g clipPath='url(#clip0_63_7790)'>
        <path
          d='M17.947 11.68L19.488 15.75H22.488C22.6393 15.7441 22.7887 15.785 22.9159 15.8672C23.043 15.9494 23.1417 16.0689 23.1984 16.2093C23.255 16.3497 23.267 16.5042 23.2326 16.6516C23.1981 16.799 23.119 16.9322 23.006 17.033L20.406 18.849L21.848 22.162C21.911 22.3137 21.9248 22.4814 21.8876 22.6414C21.8504 22.8014 21.764 22.9458 21.6405 23.0541C21.517 23.1625 21.3627 23.2294 21.1992 23.2456C21.0357 23.2617 20.8713 23.2262 20.729 23.144L17.24 21.183L13.753 23.144C13.6107 23.2262 13.4463 23.2617 13.2828 23.2456C13.1193 23.2294 12.965 23.1625 12.8415 23.0541C12.718 22.9458 12.6316 22.8014 12.5944 22.6414C12.5572 22.4814 12.571 22.3137 12.634 22.162L14.077 18.849L11.477 17.033C11.3636 16.9327 11.2841 16.7997 11.2493 16.6524C11.2144 16.505 11.226 16.3505 11.2824 16.21C11.3388 16.0695 11.4373 15.9499 11.5643 15.8676C11.6913 15.7852 11.8407 15.7442 11.992 15.75H14.992L16.536 11.68C16.6043 11.5523 16.706 11.4455 16.8303 11.371C16.9545 11.2965 17.0966 11.2572 17.2415 11.2572C17.3864 11.2572 17.5285 11.2965 17.6527 11.371C17.777 11.4455 17.8787 11.5523 17.947 11.68Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.25 20.25H2.25C2.05167 20.2549 1.8544 20.2195 1.67018 20.1459C1.48596 20.0723 1.31863 19.9619 1.17834 19.8217C1.03806 19.6814 0.927749 19.514 0.854115 19.3298C0.780482 19.1456 0.74506 18.9483 0.75 18.75V3.857C0.736233 3.4451 0.886439 3.04456 1.16766 2.74329C1.44887 2.44201 1.83813 2.2646 2.25 2.25H17.25C17.6619 2.2646 18.0511 2.44201 18.3323 2.74329C18.6136 3.04456 18.7638 3.4451 18.75 3.857V8.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M5.25 0.75V5.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.75 0.75V5.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.25 0.75V5.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_63_7790'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
