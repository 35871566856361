import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import ProfileForm from '../forms/PasswordUpdateForm.js'

export default function PasswordUpdateFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Update your Password'}>
        <ProfileForm />
      </DashboardSection>
    </DashboardBox>
  )
}
