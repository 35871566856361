import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {Button} from '@mui/material'
import {StyledTableCell, StyledTableRow} from '.'
import {saveAs} from 'file-saver'
import {Buffer} from 'buffer'
import MobileIncentiveTable from './MobileTable'
import TableCellText from './TableCellText'
import Spinner from 'react-bootstrap/Spinner'

export interface Incentive {
  id: number
  created: string
  reason: string
  type: string
  filename: string
}

interface IncentivesHistoryTableProps {
  displayForMobile?: boolean
}
export default function IncentivesHistoryTable({displayForMobile}: IncentivesHistoryTableProps) {
  const [downloadStatus, setDownloadStatus] = useState('Not Started')
  const [downloadFileName, setDownloadFileName] = useState('')
  const incentiveDownload = useSelector((state: any) => state.member.Incentive)

  const dispatch = useDispatch()

  const downloadIncentive = (incentive: Incentive) => {
    // console.log(claim)
    if (downloadStatus === 'Not Started') {
      dispatch(Member.actions.fetchIncentive({incentiveID: incentive.id, filename: incentive.filename}))
      setDownloadFileName(incentive.filename)
      setDownloadStatus('Fetching')
    }
  }

  // grab the blob from state and tell the browser to download
  const downloadIncentiveFile = useCallback(() => {
    if (incentiveDownload && incentiveDownload !== '') {
      const file = new Blob([Buffer.from(incentiveDownload, 'base64')], {type: 'application/pdf'})
      saveAs(file, downloadFileName)
    }
  }, [downloadFileName, incentiveDownload])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect(() => {
    if (downloadStatus === 'Fetching' && incentiveDownload && incentiveDownload !== '') {
      downloadIncentiveFile()
      setDownloadStatus('Not Started')
      dispatch(Member.actions.clearIncentive())
    }
  }, [downloadStatus, downloadIncentiveFile, incentiveDownload, dispatch])

  // Fetch incentives
  useEffect(() => {
    dispatch(Member.actions.fetchIncentives())
  }, [dispatch])

  // Map Incentives to Redux State
  const incentives = useSelector((state: any) => state.member.Incentives)
  const incentiveCheck = useSelector((state: any) => state.member.IncentiveCheck)

  // Check if data still be retrieved
  if (Object.keys(incentives).length === 0 && !incentiveCheck) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <p className='claim-text mb-5'>
          Please wait while we retrieve your incentive claims history... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </p>
      </span>
    )
  }

  // No data is found
  if (Object.keys(incentives).length === 0 && incentiveCheck) {
    return (
      <p className='claim-text mb-10'>
        <strong>No incentives found</strong>
      </p>
    )
  }

  if (displayForMobile) {
    return <MobileIncentiveTable incentiveType='Incentive' onClickDownload={downloadIncentive} data={incentives} />
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 700}} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Incentive Date</StyledTableCell>
            <StyledTableCell>Type</StyledTableCell>
            <StyledTableCell>Reason</StyledTableCell>
            <StyledTableCell>View details</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {incentives.map((row: Incentive, i: number) => (
            <StyledTableRow key={i}>
              <StyledTableCell component='th' scope='row'>
                {row.created}
              </StyledTableCell>
              <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                <TableCellText text={row.type} />
              </StyledTableCell>
              <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                <TableCellText text={row.reason} />
              </StyledTableCell>
              <StyledTableCell>
                <div style={{width: '160px'}}>
                  <Button variant='contained' size='small' disableElevation className='rounded-full' onClick={() => downloadIncentive(row)}>
                    Download Claim
                  </Button>
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
