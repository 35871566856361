import {createTheme} from '@mui/material/styles'
import COLORS from './colors'

const MuiTheme = createTheme({
  typography: {
    fontFamily: ['Poppins'].join(',')
  },
  palette: {
    contrastThreshold: 2,
    primary: {
      main: COLORS.primary
    },
    secondary: {
      main: COLORS.secondary,
      contrastText: '#ffffff'
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: '#fd397a'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    }
  },
  /**
   * @see https://material-ui.com/customization/globals/#default-props
   */
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: 'none'
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        // The properties to apply
        disableRipple: true // No more ripple, on the whole application 💣!
      }
    },
    // Set default elevation to 1 for popovers.
    MuiPopover: {
      defaultProps: {
        elevation: 1
      }
    }
  }
})
export default MuiTheme
