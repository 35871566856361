import {AuthModel} from './_models'

export const BASE_URL = process.env.REACT_APP_APIIP
export const BASE_VHURL = process.env.REACT_APP_VHAPIIP
export const APIDESC = process.env.REACT_APP_APIDESC
export const VHAPIDESC = process.env.REACT_APP_VHAPIDESC

import * as auth from '../../../../app/store/ducks/auth.duck'
import * as member from '../../../../app/store/ducks/member.duck'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      // Need to handle if we are calling the VH API or MP API - using port which isnt' ideal
      if (config.url.search(VHAPIDESC) !== -1) {
        // ok need to see if it is a form post which needs a different content-type
        if (config.url.search('/data/form') !== -1) {
          // ok it is a form post
          // console.log('Its a form PUT');
          const {
            auth: {authVHToken}
          } = store.getState()
          if (authVHToken) {
            config.headers = {Authorization: `Bearer ${authVHToken}`, 'Content-Type': 'multipart/form-data'}
          }
        } else {
          // it is not a form PUT
          const {
            auth: {authVHToken}
          } = store.getState()
          if (authVHToken) {
            config.headers = {Authorization: `Bearer ${authVHToken}`, 'Content-Type': 'application/json'}
          }
        }
      } else {
        if (
          config.url.search('/claimdata') !== -1 ||
          config.url.search('/incentivedata') !== -1 ||
          config.url.search('/disabilitydata') !== -1 ||
          config.url.search('/errorclaim') !== -1 ||
          config.url.search('/billingmessagereply') !== -1
        ) {
          // ok it is a form post
          // console.log('Its a form PUT');
          const {
            auth: {authToken}
          } = store.getState()
          if (authToken) {
            config.headers = {Authorization: `Bearer ${authToken}`, 'Content-Type': 'multipart/form-data'}
          }
        } else {
          // console.log('Auth');
          // console.log('Its a json PUT');
          const {
            auth: {authToken}
          } = store.getState()
          if (authToken) {
            config.headers = {Authorization: `Bearer ${authToken}`, 'Content-Type': 'application/json'}
          }
        }
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    function (error: any) {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        // Determine if a VH or MP API call failed
        if (originalRequest.url.search(VHAPIDESC) !== -1) {
          // Ok it is a VH failure
          originalRequest._retry = true
          const {
            auth: {refreshVHToken}
          } = store.getState()
          // Check if the refreshtoken exists
          if (refreshVHToken) {
            // console.log ('Getting new VH Token');
            return axios
              .post(BASE_VHURL + '/users/reauth', {refreshVHToken})
              .then((res: any) => {
                console.log(res)
                if (res.status === 200) {
                  // 1) put token to LocalStorage
                  // console.log ('Received New VH Token');
                  // console.log (res.data.token);
                  // console.log (res.data.refreshToken);
                  store.dispatch({type: auth.actionTypes.VHReauth, payload: {authVHToken: res.data.token, refreshVHToken: res.data.refreshToken}})
                  // console.log(originalRequest);
                  return axios(originalRequest)
                }
              })
              .catch(() => {
                // Note invalid dispatch calls will stop the logout process
                store.dispatch({type: member.actionTypes.SET_INITIAL})
                store.dispatch({type: auth.actionTypes.Logout})
                // console.log('Logged Out');
              })
          } else {
            // No refreshtoken - force logout
            store.dispatch({type: member.actionTypes.SET_INITIAL})
            store.dispatch({type: auth.actionTypes.Logout})
            // console.log('Logged Out');
          }
        } else {
          // Ok it is a MP failure
          const {
            auth: {refreshToken}
          } = store.getState()
          originalRequest._retry = true
          // Check if the refreshtoken exists
          if (refreshToken) {
            // console.log ('Getting new MP Token');
            return axios
              .post(BASE_URL + '/users/reauth', {refreshToken})
              .then((res: any) => {
                console.log(res)
                if (res.status === 200) {
                  // 1) put token to LocalStorage
                  // console.log ('Received New MP Token');
                  // console.log (res.data.token);
                  // console.log (res.data.refreshToken);
                  store.dispatch({type: auth.actionTypes.Reauth, payload: {authToken: res.data.token, refreshToken: res.data.refreshToken}})
                  // console.log(originalRequest);
                  return axios(originalRequest)
                }
              })
              .catch(() => {
                // Note invalid dispatch calls will stop the logout process
                store.dispatch({type: member.actionTypes.SET_INITIAL})
                store.dispatch({type: auth.actionTypes.Logout})
                // console.log('Logged Out');
              })
          } else {
            // No refreshtoken - force logout
            store.dispatch({type: member.actionTypes.SET_INITIAL})
            store.dispatch({type: auth.actionTypes.Logout})
            // console.log('Logged Out');
          }
        }
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
