import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Grid, Divider, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Alert, Box} from '@mui/material'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Col from 'react-bootstrap/Col'
import {Formik} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import * as Yup from 'yup'
import {FormLabel} from 'react-bootstrap'

export default function Request1095BForm() {
  // Define a few variables
  const dispatch = useDispatch()
  // const [loading] = useState(false)

  // Set form type default
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)
  const authState = useSelector((state) => state.auth)

  // useEffect(() => {
  //   //
  //   // Some Commentary about this
  //   // Essentially when someone creates a submits a form, a processing window appears and disappears once the form is processed
  //   // To do this a Redux variable - <formtype>Form  is set with a number - null is not set, 1 is processing and 2 is an error state
  //   //
  //   if (formStatus) {
  //     checkFormStatus(formStatus)
  //   }
  // })

  // function checkFormStatus(newFormStatus) {
  //   if (newFormStatus === 1) {
  //     // Ok processed finished successfully - lets go
  //     // console.log('Process finished successfully');
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reset form status
  //     dispatch(Member.actions.request1095bformclear())
  //     //
  //     //toast.success("Your request was successfully submitted.  You will receive an email confirmation.",{ autoClose:10000 });
  //     setDisplayForm(true)
  //   }
  //   if (newFormStatus === 2) {
  //     // it has errors - ditch the processing window and stay on page
  //     // console.log('Process finished badly');
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reactivate submit for another attempt
  //     setIsLoading(false)
  //     // Reset form status
  //     dispatch(Member.actions.request1095bformclear())
  //     // Back to the main menu
  //     navigate('/home')
  //   }
  // }

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  function Savesubmit(data) {
    // Open Submitting modal
    setOpen(true)
    setIsLoading(true)
    // Add member email to info packet - as I need to email the member on submission
    data['email'] = authState.email
    // Some debugging
    if (process.env.REACT_APP_DEBUG) {
      console.log(data)
    }
    // Save request/form
    dispatch(Member.actions.putRequest1095BFormData(data))
  }

  function FormRequestEntry(props) {
    //
    // Member can enter a request
    //
    const fieldName = 'RequestEntry'
    //
    return (
      <Grid className={'mt-3'} item xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={fieldName}>
          Please detail year(s) requested
        </FormLabel>
        <TextField
          style={{minWidth: '100%'}}
          type='text'
          variant='outlined'
          label=''
          className={'mt-0 mb-0'}
          margin='normal'
          name={fieldName}
          value={props.values[fieldName]}
          autoComplete='new-password'
          size='small'
          helperText={props.touched[fieldName] ? props.errors[fieldName] : ''}
          error={props.touched[fieldName] && Boolean(props.errors[fieldName])}
          multiline
          rows={4}
          onChange={props.handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
        />
      </Grid>
    )
  }

  const validationSchema = Yup.object().shape({
    RequestEntry: Yup.string().min(2, 'Too Short').max(255, 'Too Long').required('Request is required')
  })

  function DisplayQuestions(props) {
    //
    return (
      <React.Fragment>
        <FormRequestEntry handleChange={props.handleChange} values={props.values} touched={props.touched} errors={props.errors} />
      </React.Fragment>
    )
  }

  // Set defaults
  const defaultV = {}
  defaultV['RequestEntry'] = ''
  return (
    <>
      <Grid container xs={12} md={9} gap={3} justify='center'>
        <Grid item xs={12} md={12} justify='center'>
          <Alert severity='info'>Please allow 5 days to receive your 1095b. If you have not received if after 5 business days, please contact us through secure forms.</Alert>
        </Grid>
        <Grid item xs={12} md={12} justify='center'>
          <Formik
            enableReinitialize={true}
            initialValues={defaultV}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              setIsLoading(true)
              Savesubmit(values)
            }}
          >
            {(props) => {
              const {values, errors, touched, handleChange, handleSubmit, setFieldValue} = props
              return (
                <form className={'flex'} noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
                  <Grid xs={12} md={12} justify='center'>
                    <Row className='mb-5'>
                      <Col xs={12} md={12}>
                        <Card>
                          <Card.Body>
                            <Grid container spacing={1}>
                              <DisplayQuestions setFieldValue={setFieldValue} handleChange={handleChange} values={values} touched={touched} errors={errors} />
                            </Grid>
                          </Card.Body>
                          <Divider />
                          <Card.Body>
                            <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                              <Grid container xs={12} gap={3} justifyContent={'end'}>
                                <Link to='/home'>
                                  <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                    Cancel
                                  </Button>
                                </Link>
                                <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                                  Submit
                                </Button>
                              </Grid>
                            </Box>

                            <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                              <Grid container xs={12} justifyContent={'space-between'}>
                                <Grid item className={'pr-2'} xs={6}>
                                  <Link to='/home'>
                                    <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                      Cancel
                                    </Button>
                                  </Link>
                                </Grid>
                                <Grid item className={'pl-2'} xs={6}>
                                  <Button variant='contained' size={'large'} className={'w-100'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                                    Submit
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Grid>
                </form>
              )
            }}
          </Formik>
        </Grid>
      </Grid>
      <Dialog open={open} aria-labelledby='Submitting 1095B Request - Please wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle id='form-dialog-title'>Submitting Request</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          <DialogContentText>
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
            </span>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={open} aria-labelledby='Submitting 1095B Request - Please wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Submitting Request</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Request Submitted Successfully</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                            Return to Home
                          </Button>
                        </Link>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <>
                  {returnStatus && returnStatus === 'Your request is currently on file. If you do not receive your 1095b within 5 business days, please contact us again. Thank you!' ? (
                    <Card>
                      <Card.Body>
                        <div className='mb-2 bg-light-warning p-8 rounded self-stretch'>
                          <div className='text-warning'>
                            <strong>{returnStatus}</strong>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Body>
                        <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                          <Grid container xs={12} gap={3} justifyContent={'end'}>
                            <Link to='/home'>
                              <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                                Return to Home
                              </Button>
                            </Link>
                          </Grid>
                        </Box>

                        <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                          <Grid container xs={12} justifyContent={'space-between'}>
                            <Grid item className={'pr-2'} xs={6}>
                              <Link to='/home'>
                                <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                                  Return to Home
                                </Button>
                              </Link>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card.Body>
                    </Card>
                  ) : (
                    <Card>
                      <Card.Body>
                        <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                          <div className='text-danger'>
                            <strong>{returnStatus}</strong>
                          </div>
                        </div>
                      </Card.Body>
                      <Card.Body>
                        <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                          <Grid container xs={12} gap={3} justifyContent={'end'}>
                            <Link to='/home'>
                              <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                Return to Home
                              </Button>
                            </Link>
                            <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                              Edit and Resubmit Request
                            </Button>
                          </Grid>
                        </Box>

                        <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                          <Grid container xs={12} justifyContent={'space-between'}>
                            <Grid item className={'pr-2'} xs={6}>
                              <Link to='/home'>
                                <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                  Return to Home
                                </Button>
                              </Link>
                            </Grid>
                            <Grid item className={'pl-2'} xs={6}>
                              <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                                Edit and Resubmit Request
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card.Body>
                    </Card>
                  )}
                </>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
