export default function Footer() {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <p className='footer-app-name-text mb-0'>AFSPA &copy; 2024. All rights reserved.</p>

        <div className='flex items-center justify-end gap-4'>
          <a href='https://www.afspa.org/secure-form-member-portal-support/' className='footer-nav-link'>
            Feedback
          </a>
          <a href='https://www.afspa.org/email-and-privacy/' className='footer-nav-link'>
            Privacy
          </a>
          <a href='https://www.afspa.org/contact-us/' className='footer-nav-link'>
            Contact
          </a>
        </div>
      </div>
    </footer>
  )
}
