import React, {useEffect, useState, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {Grid, Divider, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, Select, MenuItem, Box, Alert} from '@mui/material'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import {FormLabel} from 'react-bootstrap'
import {Formik, useFormikContext} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined'
import Spinner from 'react-bootstrap/Spinner'
import * as Member from '../../store/ducks/member.duck'
import {useDropzone} from 'react-dropzone'
import * as Yup from 'yup'

export default function IncentiveForm() {
  // Define a few variables
  const dispatch = useDispatch()
  // const [loading] = useState(false)

  // Need to load dependents as they are part of a drop down in the claim process
  useEffect(() => {
    dispatch(Member.actions.fetchDependents())
  }, [dispatch])

  // Map Dependents and Auth to Redux State
  const Dependents = useSelector((state) => state.member.Dependents)
  const authState = useSelector((state) => state.auth)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)

  // Set form type default
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  // useEffect(() => {
  //   //
  //   // Some Commentary about this
  //   // Essentially when someone creates a submits a form, a processing window appears and disappears once the form is processed
  //   // To do this a Redux variable - <formtype>Form  is set with a number - null is not set, 1 is processing and 2 is an error state
  //   //
  //   if (formStatus) {
  //     checkFormStatus(formStatus)
  //   }
  // })

  // function checkFormStatus(newFormStatus) {
  //   // console.log(newFormStatus);
  //   if (newFormStatus === 1) {
  //     // Ok processed finished successfully - lets go
  //     // console.log('Process finished successfully');
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reset form status
  //     dispatch(Member.actions.incentiveformclear())
  //     // Back to the main menu
  //     navigate('/home')
  //   }
  //   if (newFormStatus === 2) {
  //     // it has errors - ditch the processing window and stay on page
  //     // console.log('Process finished badly');
  //     // Close Submitting Modal
  //     setOpen(false)
  //     // Reactivate submit for another attempt
  //     setIsLoading(false)
  //     // Reset form status
  //     dispatch(Member.actions.incentiveformclear())
  //   }
  // }

  function Savesubmit(data) {
    setOpen(true)
    setIsLoading(true)
    // Create structure to load file
    const formData = new FormData()
    // Add fields
    formData.append('Declaration', data['Declaration'])
    formData.append('PersonIncentive', data['PersonIncentive'])
    formData.append('ReasonEntry', data['ReasonEntry'])
    // Service Year is Hardcoded
    var currentTime = new Date()
    const thisYear = currentTime.getFullYear()
    formData.append('ServiceYear', thisYear)
    formData.append('IncentiveType', data['IncentiveType'])
    // Add files
    for (const fileUploadItem of data['PersonFiles']) {
      formData.append('files', fileUploadItem)
    }

    // Some debugging
    if (process.env.REACT_APP_DEBUG) {
      console.log(data)
    }
    // Save request/form
    dispatch(Member.actions.putIncentiveData({formData}))
  }

  function FormFiles() {
    const {setFieldValue, values, errors} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'PersonFiles'
    //

    const [myFiles, setMyFiles] = useState([])

    // If form is submitted and fails - re populate the files back into the dropzone
    if (values.PersonFiles && values.PersonFiles.length > 0 && myFiles && myFiles.length === 0) {
      setMyFiles(values.PersonFiles)
    }

    const onDrop = useCallback(
      (acceptedFiles) => {
        setMyFiles([...myFiles, ...acceptedFiles])
        setFieldValue(fieldName, [...myFiles, ...acceptedFiles])
      },
      [myFiles, setFieldValue]
    )

    const {getRootProps, getInputProps} = useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
        'application/pdf': []
      },
      multiple: true,
      maxFiles: 20,
      maxFileSize: 6291457,
      onDrop
    })

    const removeFile = (file) => () => {
      const newFiles = [...myFiles]
      newFiles.splice(newFiles.indexOf(file), 1)
      setMyFiles(newFiles)
      setFieldValue(fieldName, [...newFiles])
    }

    const files = myFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes <button onClick={removeFile(file)}>Remove File</button>
      </li>
    ))
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <section className='container px-0'>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <FileUploadIcon color={'primary'} className={'mt-3 mb-3'} sx={{fontSize: 40}} />
            <p>Use camera to capture documents or upload from device</p>
            <p>(max size 6 MB)</p>
          </div>
          {files.length > 0 && (
            <aside>
              <h4>Files</h4>
              <ul>{files}</ul>
            </aside>
          )}
        </section>

        {/* <DropzoneArea
          acceptedFiles={['image/jpeg,image/png,image/jpg,.pdf']}
          showPreviews={true}
          showFileNamesInPreview={true}
          maxFileSize={6291457}
          showPreviewsInDropzone={false}
          filesLimit={20}
          dropzoneText={'Use Camera to capture documents or upload from device (max size 6 MB) '}
          onChange={(files) => {
            props.setFieldValue(fieldName, files)
          }}
          clearOnUnmount={true}
        /> */}
        {errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
      </Grid>
    )
  }

  function FormPersonSelector() {
    const {values, handleChange} = useFormikContext()
    //
    const fieldName = 'PersonIncentive'
    //
    //
    // Determine list of people (FSBP member plus dependents)
    var people = []
    // Add member to array
    var member = {
      Name: authState.firstName + ' ' + authState.lastName,
      key: 'P' + authState.member_key
    }
    people.push(member)
    // Check if there are dependents
    if (Dependents) {
      // Add dependents to array together with member
      Dependents.forEach((dependents) => {
        var person = {
          Name: dependents.Name,
          key: 'D' + dependents.key
        }
        people.push(person)
      })
    }
    //
    return (
      <Grid item xs={12} md={6}>
        <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0', minWidth: '100%'}}>
          <FormLabel className='gray-1-text' htmlFor={'claimforperson'}>
            This claim is for...
          </FormLabel>
          <Grid item xs={12} md={12}>
            <Select
              aria-label='Claim for Person'
              id='claimforperson'
              className={'w-100'}
              labelId='claimforperson-label'
              name={fieldName}
              value={values[fieldName] || ''}
              onChange={handleChange(fieldName)}
            >
              {people
                ? people.map((People) => {
                    return (
                      <MenuItem key={People.key} value={People.key}>
                        {People.Name}
                      </MenuItem>
                    )
                  })
                : 'None'}
            </Select>
          </Grid>
        </FormControl>
      </Grid>
    )
  }

  function FormReasonEntry() {
    const {values, touched, errors, handleChange} = useFormikContext()
    //
    const fieldName = 'ReasonEntry'
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={'claimforperson'}>
          Diagnosis or Reason for Visit
        </FormLabel>
        <TextField
          style={{minWidth: '100%'}}
          type='text'
          variant='outlined'
          margin='normal'
          className={'mt-0'}
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
          multiline
          minRows={4}
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
        />
      </Grid>
    )
  }

  function FormIncentiveType() {
    const {values, handleChange} = useFormikContext()
    //
    const fieldName = 'IncentiveType'
    //
    //
    return (
      <Grid item xs={12} md={6}>
        <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0', minWidth: '100%'}}>
          <FormLabel className='gray-1-text' htmlFor={'incentivetype'}>
            Incentive Type
          </FormLabel>
          <Select aria-label='Incentive Type' id='incentivetype' labelId='incentivetype-label' name={fieldName} value={values[fieldName] || ''} onChange={handleChange(fieldName)}>
            <MenuItem Key={2} value='Covid19'>
              Covid19{' '}
            </MenuItem>
            <MenuItem Key={3} value='Well-Child and Immunization Overseas Care Documentation'>
              Well-Child and Immunization Overseas Care Documentation{' '}
            </MenuItem>
            <MenuItem Key={4} value='Routine Physical by MED Unit  Attestation Form'>
              Routine Physical by MED Unit Attestation Form{' '}
            </MenuItem>
            <MenuItem Key={5} value='Other'>
              Other{' '}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    )
  }

  function FormDeclarationSelector() {
    const {values, handleChange, touched, errors} = useFormikContext()
    //
    const fieldName = 'Declaration'
    //
    return (
      <>
        <Grid item xs={12} md={12}>
          <div className='claim-text' style={{margin: '14px 0 0 0'}}>
            I certify the information in this incentive submission is complete and accurate.
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl variant='outlined' margin='dense' style={{margin: '0', minWidth: '100%'}}>
            <Select aria-label='Incentive Declaration' id='declaration' name={fieldName} value={values[fieldName]} onChange={handleChange(fieldName)}>
              <MenuItem key='NO' value='0'>
                No
              </MenuItem>
              <MenuItem key='YES' value='1'>
                Yes
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          {touched[fieldName] && !!errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
        </Grid>
        <Grid item xs={12} md={12} className={'mt-3'}>
          <label className='claim-text'>
            WARNING: Any intentional false statement in this application or willful misrepresentation is a violation of the law punished by a fine not more than $10,000, or imprisonment of not more
            than five years, or both. (18 U.S.C. 1001).
          </label>
        </Grid>
      </>
    )
  }

  const validationSchema = Yup.object().shape({
    ReasonEntry: Yup.string().min(2, 'Reason is too Short').max(12000, 'Reason is too Long').required('Reason is required'),
    Declaration: Yup.string().oneOf(['1'], 'Only certified incentives can be processed'),
    PersonFiles: Yup.mixed().test('PersonFiles', 'Please upload at least one attachment', (file) => (file ? true : false))
  })

  function DisplayQuestions() {
    //
    return (
      <React.Fragment>
        <FormPersonSelector />
        <FormIncentiveType />
        <FormReasonEntry />
        <FormFiles />
        <FormDeclarationSelector />
      </React.Fragment>
    )
  }

  function ErrorsNotification({errors, touched}) {
    if ((errors && Object.keys(errors).length > 0 && touched && Object.keys(touched).length > 0) || (errors && errors.PersonFiles)) {
      return (
        <>
          <Card.Body>
            <div className='errors-list'>
              <h4 className='errors-list-heading'>Please fix the following fields before you submit:</h4>
              <ul>
                {Object.keys(errors).map((msg) => (
                  <React.Fragment key={msg}>{touched[msg] || msg === 'PersonFiles' ? <li>{errors[msg]}</li> : null}</React.Fragment>
                ))}
              </ul>
            </div>
          </Card.Body>
        </>
      )
    } else {
      return null
    }
  }

  // Set defaults
  const defaultV = {}
  defaultV['ReasonEntry'] = ''
  defaultV['IncentiveType'] = 'Covid19'
  defaultV['Declaration'] = '0'
  defaultV['PersonIncentive'] = 'P' + authState.member_key
  //
  // Display default items to console log
  if (process.env.REACT_APP_DEBUG) {
    console.log(defaultV)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={defaultV}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true)
          Savesubmit(values)
        }}
      >
        {(props) => {
          const {handleSubmit} = props
          return (
            <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
              <Grid container justify='center'>
                <Row className='mb-5'>
                  <Col xs={12} md={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <p className='claim-text mb-6'>Please select the name of the person who the incentive is being filed for and year incentive was serviced.</p>
                        <p className='claim-text mb-6'>Next, take a picture of, or browse for the documents you wish to upload and click Submit to send your incentive.</p>
                        <Alert severity='info'>
                          All PDF file submissions are converted to grayscale. Please make sure the PDF document uploaded is in Portable Document Format (PDF). If the claim submission is failing, try
                          submitting one document at a time to identify the failing document.{' '}
                        </Alert>
                      </Grid>
                    </Grid>
                    <Card className={'mt-5'}>
                      <Card.Body>
                        <Grid container spacing={1}>
                          <DisplayQuestions />
                        </Grid>
                      </Card.Body>
                      <Divider />
                      <ErrorsNotification />
                      <Card.Body>
                        <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                          <Grid container xs={12} gap={3} justifyContent={'end'}>
                            <Link to='/home'>
                              <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                Cancel
                              </Button>
                            </Link>
                            <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                              Submit Incentive
                            </Button>
                          </Grid>
                        </Box>

                        <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                          <Grid container xs={12} justifyContent={'space-between'}>
                            <Grid item className={'pr-2'} xs={6}>
                              <Link to='/home'>
                                <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                  Cancel
                                </Button>
                              </Link>
                            </Grid>
                            <Grid item className={'pl-2'} xs={6}>
                              <Button variant='contained' size={'large'} className={'w-100'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                                Submit Incentive
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Grid>
            </form>
          )
        }}
      </Formik>

      <Dialog open={open} aria-labelledby='Submitting Incentive Claim Please Wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Submitting Claim</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Incentive Claim Submitted Successfully</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                            Return to Home
                          </Button>
                        </Link>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                      <div className='text-danger'>
                        <strong>{returnStatus}</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                            Return to Home
                          </Button>
                        </Link>
                        <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                          Edit and Resubmit Incentive Claim
                        </Button>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item className={'pl-2'} xs={6}>
                          <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                            Edit and Resubmit Claim
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
