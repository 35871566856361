import React, {useCallback, useEffect, useRef, useState} from 'react'
import {getLayoutFromLocalStorage, ILayout, LayoutSetup} from '../../../_metronic/layout/core'
import {BuilderPagePresentation} from './BuilderPagePresentation'

const BuilderPage: React.FC = () => {
  const [tab, setTab] = useState('Sidebar')
  const [config, setConfig] = useState<ILayout>(getLayoutFromLocalStorage())
  const [configLoading, setConfigLoading] = useState<boolean>(false)
  const [resetLoading, setResetLoading] = useState<boolean>(false)

  const updateConfig = useCallback(() => {
    setConfigLoading(true)
    try {
      LayoutSetup.setConfig(config)
      window.location.reload()
    } catch (error) {
      setConfig(getLayoutFromLocalStorage())
      setConfigLoading(false)
    }
  }, [config])

  // Clean timeout
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const reset = useCallback(() => {
    setResetLoading(true)
    timeoutRef.current = setTimeout(() => {
      setConfig(getLayoutFromLocalStorage())
      setResetLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
    }
  }, [])

  return <BuilderPagePresentation config={config} configLoading={configLoading} reset={reset} resetLoading={resetLoading} setConfig={setConfig} setTab={setTab} tab={tab} updateConfig={updateConfig} />
}

export {BuilderPage}
