import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import HistoryRequests1095BTable from '../../components/Tables/HistoryRequest1095BTable'

export default function Request1095BHistoryDashboard() {
  const title = '1095B Requests history'

  return (
    <DashboardBox hasMobileFullWidth>
      <DashboardSection name={title} className='table_box_xl_device'>
        <HistoryRequests1095BTable />
      </DashboardSection>
      <div className='table_box_mobile w-full'>
        <DashboardSection
          name={title}
          // button={
          //   <NavLink to='/submit-claim'>
          //     <div role='button' className='circle_button_icon hover:brightness-90'>
          //       <AddIcon style={{color: 'white', fontSize: '26px'}} />
          //     </div>
          //   </NavLink>
          // }
        >
          <div className='w-full h-4'></div>
        </DashboardSection>
        <HistoryRequests1095BTable displayForMobile />
      </div>
    </DashboardBox>
  )
}
