import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import ProfileForm from '../forms/ProfileForm.js'

export default function ProfileFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Profile'}>
        <ProfileForm />
      </DashboardSection>
    </DashboardBox>
  )
}
