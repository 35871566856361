import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import {RenderMyPlans} from '../home/Dashboard'

export default function MyPlanDashboard() {
  const dispatch = useDispatch()
  // FSBP Plan Information
  const FSBP = useSelector((state: any) => state.member.FSBP)
  const Dependents = useSelector((state: any) => state.member.Dependents)
  // Immediate Benefit Plan Information
  const IBP = useSelector((state: any) => state.member.IBP)
  const IBPPrimaryBeneficiaries =
    IBP && IBP.Secondary && IBP.Secondary['Primary Beneficiary 1'] && IBP.Secondary['Primary Beneficiary 1'].length > 0
      ? {
          ['Primary Beneficiaries']: '',
          ['First']: IBP.Secondary['Primary Beneficiary 1'],
          ['Second']: IBP.Secondary['Primary Beneficiary 2'],
          ['Third']: IBP.Secondary['Primary Beneficiary 3']
        }
      : ''
  const IBPContingentBeneficiaries =
    IBP && IBP.Secondary && IBP.Secondary['Contingent Beneficiary 1'] && IBP.Secondary['Contingent Beneficiary 1'].length > 0
      ? {
          ['Contingent Beneficiaries']: '',
          ['First']: IBP.Secondary['Contingent Beneficiary 1'],
          ['Second']: IBP.Secondary['Contingent Beneficiary 2']
        }
      : ''
  const IBPOtherBeneficiaries =
    IBP && IBP.Secondary && IBP.Secondary['Other Beneficiary 1'] && IBP.Secondary['Other Beneficiary 1'].length > 0
      ? {
          ['Other Beneficiaries']: '',
          ['First']: IBP.Secondary['Other Beneficiary 1'],
          ['Second']: IBP.Secondary['Other Beneficiary 2']
        }
      : ''
  const IBPSecondary = {...IBPPrimaryBeneficiaries, ...IBPContingentBeneficiaries, ...IBPOtherBeneficiaries}
  // Disability Plan Information
  const Disability = useSelector((state: any) => state.member.Disability)
  // Dental Plan Information
  const Dental = useSelector((state: any) => state.member.Dental)
  // GEL Plan Information
  const Gel = useSelector((state: any) => state.member.Gel)
  // CI Plan Information
  const CI = useSelector((state: any) => state.member.CI)
  // ADND Plan Information
  const ADND = useSelector((state: any) => state.member.Adnd)
  //

  useEffect(() => {
    dispatch(Member.actions.fetchGel())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchFSBP())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchDependents())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchIBP())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchDisability())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchDental())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchCI())
  }, [dispatch])
  useEffect(() => {
    dispatch(Member.actions.fetchAdnd())
  }, [dispatch])

  return (
    <DashboardBox>
      <DashboardSection name={'My Plans'}>{RenderMyPlans(IBP, IBPSecondary, Disability, Dental, Gel, CI, ADND)}</DashboardSection>
    </DashboardBox>
  )
}
