import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import SecureForm from '../forms/SecureForm.js'

export default function IncentiveFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Secure Form'}>
        <SecureForm />
      </DashboardSection>
    </DashboardBox>
  )
}
