import {SVGProps} from 'react'
import EmailIcon from './Email'
import PersonIcon from './Person'
import BurgerMenuIcon from './BurgerMenu'
import PlusIcon from './Plus'
import DownloadIcon from './Download'
import HomeIcon from './Home'
import WorldIcon from './World'
import ChecklistIcon from './CheckList'
import AddDocIcon from './AddDoc'
import NotesStarIcon from './NotesStar'
import NotesClockIcon from './NotesClock'
import CreditCardIcon from './CreditCard'
import ArticleIcon from './Article'
import DocIcon from './Doc'
import LogOutIcon from './LogOut'

export type IconName =
  | 'email'
  | 'person'
  | 'burger-menu'
  | 'plus'
  | 'download'
  // 'Sidebar'
  | 'home'
  | 'world'
  | 'article'
  | 'list'
  | 'add-doc'
  | 'checklist'
  | 'notes-clock'
  | 'notes-star'
  | 'credit-card'
  | 'doc'
  | 'log-out'

interface IconProps {
  name: IconName
  props?: SVGProps<SVGSVGElement>
}
export function Icon({name, props}: IconProps) {
  switch (name) {
    case 'email':
      return <EmailIcon {...props} />
    case 'person':
      return <PersonIcon {...props} />
    case 'burger-menu':
      return <BurgerMenuIcon {...props} />
    case 'plus':
      return <PlusIcon {...props} />
    case 'download':
      return <DownloadIcon {...props} />
    case 'home':
      return <HomeIcon {...props} />
    case 'world':
      return <WorldIcon {...props} />
    case 'article':
      return <ArticleIcon {...props} />
    case 'add-doc':
      return <AddDocIcon {...props} />
    case 'checklist':
      return <ChecklistIcon {...props} />
    case 'notes-clock':
      return <NotesClockIcon {...props} />
    case 'notes-star':
      return <NotesStarIcon {...props} />
    case 'credit-card':
      return <CreditCardIcon {...props} />
    case 'doc':
      return <DocIcon {...props} />
    case 'log-out':
      return <LogOutIcon {...props} />
    default:
      return null
  }
}
