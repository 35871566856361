import {IconName} from '../Icons/Icon'
import CardBlueLink from './CardBlueLink'
import {CardInfoDisplay} from './PolicyCard'

interface PolicyBottomCardProps {
  data: {[key: string]: string}
  buttons: {text: string; onClick: () => any; icon?: IconName}[]
}
/**
 * ## Desktop
 * - Blue Links: Left
 * - Information: Right
 * - Alignment: Horizontal
 *
 * ## Mobile
 * - Information: Top
 * - Blue Links: Bottom
 * - Alignment: Vertical
 */
export default function PolicyBottomCard({data, buttons}: PolicyBottomCardProps) {
  const renderButtons = buttons.map(({text, onClick, icon}, i) => {
    return <CardBlueLink key={i} text={text} onClick={onClick} icon={icon} />
  })
  return (
    <div className='card-box'>
      <div className='w-1/2 gap-4 hide-on-mobile'>{renderButtons}</div>
      <div className='w-1/2'>
        <CardInfoDisplay data={data} infoTextClassname='gray-text' />
      </div>
      <div className='display-on-mobile'>{renderButtons}</div>
    </div>
  )
}
