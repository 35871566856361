import {all, take} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from './ducks/auth.duck'
import * as member from './ducks/member.duck'
import * as entity from './ducks/entity.duck'
import {REHYDRATE} from 'redux-persist/lib/constants'

// const authT = (state) => state.auth.authToken;

export const rootReducer = combineReducers({
  auth: auth.reducer,
  member: member.reducer,
  entity: entity.reducer
})

export function* rootSaga() {
  if (process.env.REACT_APP_DEBUG) {
    console.log('Waiting for ALL rehydration')
  }
  yield take(REHYDRATE) // Wait for rehydrate to prevent sagas from running with empty store
  if (process.env.REACT_APP_DEBUG) {
    console.log('Rehydrated ALL')
  }
  yield all([auth.saga(), member.saga(), entity.saga()])
}
