import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {Button} from '@mui/material'
import {StyledTableCell, StyledTableRow} from '.'
import {saveAs} from 'file-saver'
import {Buffer} from 'buffer'
import MobileIncentiveTable from './MobileTable'
import TableCellText from './TableCellText'
import Spinner from 'react-bootstrap/Spinner'

export interface Claim {
  id: number
  created: string
  comments: string
  trackingInformation: string
  filename: string
}

interface HistoryClaimsTableProps {
  displayForMobile?: boolean
}
export default function HistoryClaimsTable({displayForMobile = false}: HistoryClaimsTableProps) {
  // const [downloadStatus, setDownloadStatus] = useState('Not Started')
  // const [downloadFileName, setDownloadFileName] = useState('')
  // const claimDownload = useSelector((state) => state.member.Claim)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Member.actions.fetchClaims())
  }, [dispatch])

  // Map Claims to Redux State
  const claims = useSelector((state: any) => state.member.Claims)
  const claimCheck = useSelector((state: any) => state.member.ClaimCheck)
  const [downloadStatus, setDownloadStatus] = useState('Not Started')
  const [downloadFileName, setDownloadFileName] = useState('')
  const claimDownload = useSelector((state: any) => state.member.Claim)

  const downloadClaim = (claim: Claim) => {
    // console.log(claim)
    if (downloadStatus === 'Not Started') {
      dispatch(Member.actions.fetchClaim({claimID: claim.id, filename: claim.filename}))
      setDownloadFileName(claim.filename)
      setDownloadStatus('Fetching')
    }
  }

  // grab the blob from state and tell the browser to download
  const downloadClaimFile = useCallback(() => {
    //   var exportDocName = `Project Schedule - ${header.name}`;
    if (claimDownload && claimDownload !== '') {
      const file = new Blob([Buffer.from(claimDownload, 'base64')], {type: 'application/pdf'})
      saveAs(file, downloadFileName)
    }
  }, [downloadFileName, claimDownload])

  // useEffect to fire when status is 'Fetching' and we get the result in redux
  useEffect(() => {
    if (downloadStatus === 'Fetching' && claimDownload && claimDownload !== '') {
      downloadClaimFile()
      setDownloadStatus('Not Started')
      dispatch(Member.actions.clearClaim())
    }
  }, [downloadStatus, downloadClaimFile, claimDownload, dispatch])

  // Check if data still be retrieved
  if (Object.keys(claims).length === 0 && !claimCheck) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <p className='claim-text mb-5'>
          Please wait while we retrieve your claims history... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </p>
      </span>
    )
  }

  // No data is found
  if (Object.keys(claims).length === 0 && claimCheck) {
    return (
      <p className='claim-text mb-10'>
        <strong>No claims found</strong>
      </p>
    )
  }

  if (displayForMobile) {
    return <MobileIncentiveTable incentiveType='Claim' onClickDownload={downloadClaim} data={claims} />
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 700}} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Claim Date</StyledTableCell>
            <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Reason</StyledTableCell>
            <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Tracking Information</StyledTableCell>
            <StyledTableCell>Download</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {claims &&
            claims.map((row: Claim, i: number) => (
              <StyledTableRow key={i}>
                <StyledTableCell component='th' scope='row'>
                  {row.created}
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                  <TableCellText text={row.comments} />
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                  <TableCellText text={row.trackingInformation} />
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{width: '160px'}}>
                    <Button variant='contained' size='small' disableElevation className='rounded-full' onClick={() => downloadClaim(row)}>
                      Download Claim
                    </Button>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
