import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import DisabilityApplication from '../forms/DisabilityApplication.js'

export default function DisabilityApplicationDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Disability Enrollment'}>
        <DisabilityApplication />
      </DashboardSection>
    </DashboardBox>
  )
}
