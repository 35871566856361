import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import ClaimForm from '../forms/ClaimForm.js'

export default function ClaimFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Submit Your Claim'}>
        <ClaimForm />
      </DashboardSection>
    </DashboardBox>
  )
}
