import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest, all} from 'redux-saga/effects'
import axios from 'axios'
import * as Sentry from '@sentry/react'

export const BASE_URL = process.env.REACT_APP_APIIP
export const BASE_URL_VH = process.env.REACT_APP_VHAPIIP

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Reauth: '[Reauth] Action',
  VHReauth: '[VHReauth] Action',
  GET_VHLOGIN: 'GET_VHLOGIN',
  SET_VHLOGIN: 'SET_VHLOGIN',
  PUT_ACCOUNT: 'PUT_ACCOUNT',
  PUT_NEWPASSWORD: 'PUT_NEWPASSWORD',
  SET_EMAIL: 'SET_EMAIL',
  SET_UNREAD: 'SET_UNREAD',
  SET_BILLINGUNREAD: 'SET_BILLINGUNREAD',

  SET_ACCOUNTFORMOK: 'SET_ACCOUNTFORMOK',
  SET_ACCOUNTFORMCLEAR: 'SET_ACCOUNTFORMCLEAR',
  SET_ACCOUNTFORMERROR: 'SET_ACCOUNTFORMERROR',
  SET_SSOOK: 'SET_SSOOK',
  SET_SSOCLEAR: 'SET_SSOCLEAR'
}

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  authVHToken: undefined,
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  username: undefined,
  refreshToken: undefined,
  refreshVHToken: undefined,
  roleID: undefined,
  roleDesc: undefined,
  VHToken: undefined,
  member_key: undefined,
  Unread: undefined,
  BillingUnread: undefined,
  AccountForm: null,
  addressLocked: false,
  SSOStatus: null,
  Filters: {
    fullscreen: false
  }
}

export const reducer = persistReducer(
  {
    storage,
    key: 'MP-auth',
    whitelist: [
      'user',
      'authToken',
      'authVHToken',
      'refreshVHToken',
      'refreshToken',
      'firstName',
      'lastName',
      'email',
      'username',
      'VHToken',
      'roleId',
      'roleDesc',
      'member_key',
      'addressLocked',
      'bannerDisplay',
      'splashDisplay',
      'Unread',
      'BillingUnread'
    ]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        console.log('In Login reducer')
        console.log(action.payload)
        const {token, refreshToken, firstName, lastName, email, username, roleID, roleDesc, member_key, addressLocked, bannerDisplay, splashDisplay} = action.payload
        const authToken = token
        const VHToken = token
        Sentry.setUser({email: member_key, segment: roleDesc})
        return {...state, authToken, refreshToken, firstName, lastName, email, username, roleID, roleDesc, VHToken, member_key, addressLocked, bannerDisplay, splashDisplay, user: undefined}
      }
      case actionTypes.Logout: {
        // console.log('In Logout');
        // routerHelpers.forgotLastLocation()
        return initialAuthState
      }
      case actionTypes.SET_VHLOGIN: {
        if (process.env.REACT_APP_DEBUG) {
          console.log('Logged into Valhalla with this payload' + action.payload)
        }
        const {token, refreshToken} = action.payload
        const authVHToken = token
        const refreshVHToken = refreshToken
        return {...state, authVHToken, refreshVHToken}
      }
      case actionTypes.SET_EMAIL: {
        if (process.env.REACT_APP_DEBUG) {
          console.log('Updated Username and Email ' + action.payload)
        }
        const {email} = action.payload
        return {...state, email}
      }
      case actionTypes.SET_UNREAD: {
        // console.log(action);
        return {
          ...state,
          Unread: action.unreadcount
        }
      }
      case actionTypes.SET_BILLINGUNREAD: {
        // console.log(action);
        return {
          ...state,
          BillingUnread: action.unreadcount
        }
      }
      case actionTypes.Reauth: {
        // console.log ('In Reauth reducer')
        // console.log(action.payload);
        const {authToken, refreshToken} = action.payload
        return {...state, authToken, refreshToken}
      }
      case actionTypes.VHReauth: {
        // console.log ('In Reauth reducer')
        // console.log(action.payload);
        const {authVHToken, refreshVHToken} = action.payload
        return {...state, authVHToken, refreshVHToken}
      }
      case actionTypes.SET_ACCOUNTFORMOK: {
        return {
          ...state,
          AccountForm: 1
        }
      }
      case actionTypes.SET_ACCOUNTFORMCLEAR: {
        return {
          ...state,
          AccountForm: null
        }
      }
      case actionTypes.SET_ACCOUNTFORMERROR: {
        return {
          ...state,
          AccountForm: 2
        }
      }
      case actionTypes.SET_SSOOK: {
        return {
          ...state,
          SSOStatus: true
        }
      }
      case actionTypes.SET_SSOCLEAR: {
        return {
          ...state,
          SSOStatus: null
        }
      }
      default:
        return state
    }
  }
)

export function* LoginVH(action) {
  try {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const deviceName = 'PORTAL'
    const username = action.payload.memberId
    const password = action.payload.password
    const deviceMessage = '9'
    const response = yield axios.post(BASE_URL_VH + '/users/authenticate', {username, password, deviceName, deviceMessage})
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')
      yield put({type: 'SET_VHLOGIN', payload: response.data})
      yield put({type: 'SET_SSOOK'})
    } else {
      yield put({type: 'SET_INITIAL'})
      yield put({type: actionTypes.Logout})
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (error) {
    yield put({type: 'SET_INITIAL'})
    yield put({type: actionTypes.Logout})
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putAccount(action) {
  try {
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const newURL = BASE_URL + '/users/account'
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')
      yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
    } else {
      yield put({type: 'SET_RETURNSTATUS', payload: 'Password Not Updated'})
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Password Not Updated'})
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* putNewPassword(action) {
  try {
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    const newURL = BASE_URL + '/users/newpassword'
    const response = yield axios.put(newURL, action.payload)
    process.env.REACT_APP_DEBUG && console.log(response)
    if (response.status === 200 && response.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log('ok saved')
      yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
    } else {
      yield put({type: 'SET_RETURNSTATUS', payload: 'Password Not Updated'})
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Password Not Updated'})
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export const actions = {
  login: (payload) => ({type: actionTypes.Login, payload: payload}),
  getVHLogin: (memberId, password, email) => ({type: actionTypes.GET_VHLOGIN, payload: {memberId, password, email}}),
  logout: () => ({type: actionTypes.Logout}),
  reauth: (authToken, refreshToken) => ({type: actionTypes.Reauth, payload: {authToken, refreshToken}}),
  putAccountInformation: (payload) => ({type: actionTypes.PUT_ACCOUNT, payload: payload}),
  setAccountFormFlag: () => ({type: actionTypes.OPEN_ACCOUNTFORM}),
  putNewPassword: (payload) => ({type: actionTypes.PUT_NEWPASSWORD, payload: payload}),
  accountformok: () => ({type: actionTypes.SET_ACCOUNTFORMOK}),
  accountformclear: () => ({type: actionTypes.SET_ACCOUNTFORMCLEAR}),
  accountformerror: () => ({type: actionTypes.SET_ACCOUNTFORMERROR}),
  ssook: () => ({type: actionTypes.SET_SSOOK}),
  ssoclear: () => ({type: actionTypes.SET_SSOCLEAR})
}

function* actionWatcher() {
  yield takeLatest('GET_VHLOGIN', LoginVH)
  yield takeLatest('PUT_ACCOUNT', putAccount)
  yield takeLatest('PUT_NEWPASSWORD', putNewPassword)
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {})
  yield all([actionWatcher()])
}
