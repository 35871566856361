import {IconName} from '../../../../app/components/Icons/Icon'

export type SideBarData = {
  icon?: IconName
  text: string
  href: string
  badge?: string
  subItems?: SideBarData[]
  refresh?: boolean
}

// const unreadCount: string = useSelector((state: any) => state.auth.Unread)

export const sidebarData: SideBarData[] = [
  {
    href: '/home',
    icon: 'home',
    text: 'Home'
  },
  {
    href: '/news',
    icon: 'world',
    text: 'News'
  },
  {
    href: '/blogs',
    icon: 'article',
    text: 'Blogs'
  },
  {
    href: '/my-plans',
    icon: 'checklist',
    text: 'My plans'
  },
  {
    href: '/messages',
    icon: 'email',
    text: 'Messages'
    //badge: unreadCount as string
  },
  {
    href: '/request-forms',
    icon: 'add-doc',
    text: 'Request Forms',
    subItems: [
      {
        text: 'Submit a Claim',
        href: '/submit-claim'
      },
      {
        text: 'Submit an Incentive',
        href: '/submit-incentive'
      },
      {
        text: 'Request 1095B',
        href: '/submit-1095B'
      },
      {
        text: 'Disability Enrollment',
        href: '/disabilityenrollment'
      }
    ]
  },
  {
    href: '/claims-history',
    icon: 'notes-clock',
    text: 'Claims History'
  },
  {
    href: '/incentives-history',
    icon: 'notes-star',
    text: 'Incentives History'
  },
  {
    href: '/request1095b-history',
    icon: 'doc',
    text: 'Request 1095B History'
  },
  {
    href: '/payments',
    icon: 'credit-card',
    text: 'Payments History'
  },
  {
    href: '/profileform',
    icon: 'person',
    text: 'Profile'
  },
  {
    href: '/downloads',
    icon: 'doc',
    text: 'Downloads',
    subItems: [
      {
        text: 'Open Season Material',
        href: 'https://www.afspa.org/fsbp/new-for-2024/',
        refresh: true
      },
      {
        text: '2024 FSBP Plan Brochure',
        href: 'https://www.afspa.org/wp-content/uploads/2024/01/2024_FSBP_Brochure_-RI-72-001_Final_1.8.2024.pdf ',
        refresh: true
      },
      {
        text: 'Benefit Brochure',
        href: 'https://www.afspa.org/wp-content/uploads/2023/10/AFSPA_FSBP_2024_Summary_Brochure.pdf',
        refresh: true
      },
      {
        text: 'Prescription Benefit Handbook',
        href: 'https://www.afspa.org/wp-content/uploads/2021/11/2022_FSBP_Rx_Handbook.pdf ',
        refresh: true
      },
      {
        text: 'Member Rights & Responsibilities',
        href: 'https://www.afspa.org/wp-content/uploads/2022/08/FSBP-Member-Rights-Responsibilities-08.22.pdf',
        refresh: true
      },
      {
        text: 'Notice of Privacy Practices',
        href: 'https://www.afspa.org/wp-content/uploads/2023/09/FSBP-Notice-of-Privacy-Practices-Final_08.23.pdf',
        refresh: true
      }
    ]
  },
  {
    href: '/logout',
    icon: 'log-out',
    text: 'Logout'
  }
]

export const sidebarDataNonFSBP: SideBarData[] = [
  {
    href: '/home',
    icon: 'home',
    text: 'Home'
  },
  {
    href: '/news',
    icon: 'world',
    text: 'News'
  },
  {
    href: '/blogs',
    icon: 'article',
    text: 'Blogs'
  },
  {
    href: '/my-plans',
    icon: 'checklist',
    text: 'My plans'
  },
  {
    href: '/messages',
    icon: 'email',
    text: 'Messages'
    //badge: unreadCount as string
  },
  {
    href: '/request-forms',
    icon: 'add-doc',
    text: 'Request Forms',
    subItems: [
      {
        text: 'Disability Enrollment',
        href: '/disabilityenrollment'
      }
    ]
  },
  {
    href: '/payments',
    icon: 'credit-card',
    text: 'Payments History'
  },
  {
    href: '/profileform',
    icon: 'person',
    text: 'Profile'
  },
  {
    href: '/logout',
    icon: 'log-out',
    text: 'Logout'
  }
]
