import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import usePlacesAutocomplete, {getGeocode, getZipCode, getDetails} from 'use-places-autocomplete'
import {Form} from 'react-bootstrap'
import * as Member from '../store/ducks/member.duck'

const PlacesAutoComplete = ({setFieldValue}) => {
  const {
    value,
    suggestions: {status, data},
    clearSuggestions,
    setValue
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      // componentRestrictions : {country: 'us'},
    },
    // defaultValue : props.currentfulladdress,
    debounce: 300
  })

  const fieldOrNull = (value) => {
    return value ? value : ''
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Member.actions.fetchCountries())
  }, [dispatch])

  const countries = useSelector((state) => state.member.Countries)

  const handleSelect =
    ({description}) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setFieldValue('fulladdress', description)
      setValue(description, false)
      clearSuggestions()

      // Get latitude and longitude via utility functions
      // getGeocode({ address: description })
      //   .then((results) => getLatLng(results[0]))
      //   .then(({ lat, lng }) => {
      //     setFieldValue("latitude", lat)
      //     setFieldValue("longitude", lng)
      //   })
      //   .catch((error) => {
      //     console.log(" Error: ", error);
      //   });

      getGeocode({address: description})
        .then((results) => getZipCode(results[0], false))
        .then((zipCode) => {
          setFieldValue('zipcode', zipCode)
        })
        .catch((error) => {
          console.log('Error: ', error)
        })

      getGeocode({address: description})
        .then((results) =>
          getDetails({
            placeId: results[0].place_id,
            fields: ['address_components']
          })
        )
        .then((details) => {
          // split out results for the address components and update locations fields
          var result = {}
          details.address_components.forEach((row) => (result[row.types[0]] = row.short_name))
          console.log(result)
          var country = fieldOrNull(result.country)
          if (country && (country === 'US' || country === 'CA')) {
            setFieldValue('state', fieldOrNull(result.administrative_area_level_1))
          } else {
            setFieldValue('state', '')
          }
          setFieldValue('address', fieldOrNull(result.street_number) + ' ' + fieldOrNull(result.route))
          setFieldValue('city', fieldOrNull(result.locality))
          setFieldValue('zip', fieldOrNull(result.postal_code)) // + '-' + fieldOrNull(result.postal_code_suffix));
          setFieldValue('country', countries.find((v) => v.abbrev === fieldOrNull(result.country)).id)
        })
        .catch((error) => {
          console.log(' Error: ', error)
        })
    }

  const handleInput = (e) => {
    // Place a "string" to update the value of the input element
    setValue(e.target.value)
  }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: {main_text, secondary_text}
      } = suggestion

      return (
        <div key={place_id} className='pac-item' onClick={handleSelect(suggestion)}>
          <span className='pac-main'>{main_text}</span> <span className='pac-secondary'>{secondary_text}</span>
        </div>
      )
    })

  return (
    <div>
      <Form.Label>Start typing here to search for your address or enter your address manually below</Form.Label>
      <Form.Control type='text' name='profilefulladdress' value={value} onChange={handleInput} autoComplete='new-password' />
      {/* <input value={value} onChange={handleInput} /> */}
      {status === 'OK' && <div className='pac-container'>{renderSuggestions()}</div>}
    </div>
  )
}

export default PlacesAutoComplete
