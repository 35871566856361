import {useEffect, useState, useCallback} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {Button} from '@mui/material'
import {StyledTableCell, StyledTableRow} from '.'
import {saveAs} from 'file-saver'
import {Buffer} from 'buffer'
import MobileIncentiveTable from './MobileTable'
import TableCellText from './TableCellText'
import Spinner from 'react-bootstrap/Spinner'

export interface Request1095B {
  id: number
  created: string
  request: string
}

interface HistoryRequest1095BTableProps {
  displayForMobile?: boolean
}
export default function HistoryRequest1095BTable({displayForMobile = false}: HistoryRequest1095BTableProps) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Member.actions.fetchRequest1095B())
  }, [dispatch])

  // Map 1095B Requests to Redux State
  const request1095B = useSelector((state: any) => state.member.Request1095B)
  const request1095BCheck = useSelector((state: any) => state.member.Request1095BCheck)
  // Check if data still be retrieved
  if (Object.keys(request1095B).length === 0 && !request1095BCheck) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <p className='claim-text mb-5'>
          Please wait while we retrieve your 1095B Request history... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </p>
      </span>
    )
  }

  // No data is found
  if (Object.keys(request1095B).length === 0 && request1095BCheck) {
    return (
      <p className='claim-text mb-10'>
        <strong>No 1095B Requests found</strong>
      </p>
    )
  }

  if (displayForMobile) {
    return <MobileIncentiveTable incentiveType='Request1095B' onClickDownload={undefined} data={request1095B} />
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 700}} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Request Date</StyledTableCell>
            <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>Request</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {request1095B &&
            request1095B.map((row: Request1095B, i: number) => (
              <StyledTableRow key={i}>
                <StyledTableCell component='th' scope='row'>
                  {row.created}
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                  <TableCellText text={row.request} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
