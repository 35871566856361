import {put, all, takeLatest} from 'redux-saga/effects' // takeLatest, select
import axios from 'axios'

export const BASE_URL = process.env.REACT_APP_APIIP
export const BASE_URL_VH = process.env.REACT_APP_VHAPIIP

// Action Types
export const actionTypes = {
  GET_FORM: 'GET_FORM',
  SET_FORM: 'SET_FORM',
  SET_INITIAL: 'SET_INITIAL'
}

// Initial state for this slice of the store
const initialState = {
  form: []
}

// Reducer with action payloads
export function reducer(state = initialState, {type, ...action}) {
  switch (type) {
    case 'SET_INITIAL': {
      return initialState
    }
    case 'SET_FORM': {
      return {
        ...state,
        [action.requestTypeID]: action.form
      }
    }
    default:
      return state
  }
}

export function* fetchForm(action) {
  try {
    process.env.REACT_APP_DEBUG && console.log(JSON.stringify(action))
    if (action.payload.authVHToken) {
      const newURL = BASE_URL_VH + '/entity/form/' + action.payload.requestTypeID
      const response = yield axios.get(newURL)
      process.env.REACT_APP_DEBUG && console.log(response)
      if (response.status === 200 && response.returnStatus !== 'Error') {
        // Check if something was found - this check is rudimentary - may need a better one
        if (response.data.length > 0) {
          const requestValues = response.data
          yield put({type: 'SET_FORM', requestTypeID: action.payload.requestTypeID, form: requestValues[0]})
        }
      } else {
        yield put({type: 'SET_FORM', requestTypeID: action.payload.requestTypeID, form: ''})
        yield put({
          type: 'PUT_ERROR',
          payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
        })
      }
    }
  } catch (error) {
    yield put({type: 'SET_FORM', requestTypeID: action.payload.requestTypeID, form: ''})
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

// Action Creators, return action objects with payloads
export const actions = {
  setEntityInitialState: () => ({type: actionTypes.SET_INITIAL}),
  fetchForm: (payload) => ({type: actionTypes.GET_FORM, payload: payload})
}

// Action Watchers
function* actionWatcher() {
  yield takeLatest('GET_FORM', fetchForm)
}

// Saga
export function* saga() {
  yield all([actionWatcher()])
}
