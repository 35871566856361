import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import MakePaymentsTable from '../../components/Tables/MakePaymentsTable'

export default function MakePaymentsDashboard() {
  const title = 'Make Payment'

  return (
    <DashboardBox hasMobileFullWidth>
      <DashboardSection name={title} className='table_box_xl_device'>
        <MakePaymentsTable />
      </DashboardSection>
      <div className='table_box_mobile w-full'>
        <DashboardSection name={title}>
          <div className='w-full h-4'></div>
        </DashboardSection>
        <MakePaymentsTable displayForMobile />
      </div>
    </DashboardBox>
  )
}
