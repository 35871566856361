import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Outlet, useLocation} from 'react-router-dom'
import {HeaderWrapper} from './components/header'
// import {RightToolbar} from '../partials/layout/RightToolbar'
// import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {Sidebar} from './components/sidebar'
import {DrawerMessenger, ThemeModeProvider} from '../partials'
import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'
import useReinitialiseDrawer from '../../app/hooks/useReinitialiseDrawer'
import Footer from '../../app/components/Footer'
// import APIMessages from '../../app/components/APIMessages';
// import {ToolbarWrapper} from './components/toolbar'

const MasterLayout = () => {
  useReinitialiseDrawer()
  const fullscreen = useSelector((state: any) => state.auth.Filters.fullscreen)
  const location = useLocation()

  useEffect(() => {
    reInitMenu()
  }, [location.key, fullscreen])

  if (fullscreen) {
    return (
      <PageDataProvider>
        <ThemeModeProvider>
          {/* <APIMessages /> */}
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <Outlet />
            </div>
          </div>
        </ThemeModeProvider>
      </PageDataProvider>
    )
  } else {
    return (
      <PageDataProvider>
        <ThemeModeProvider>
          <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
            <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper />
              <div className='app-wrapper flex-row flex-row-fluid w-full' id='kt_app_wrapper'>
                <Sidebar />
                <div className='overflow-y-auto h-100 w-full'>
                  <div className='app-main flex-column flex-row-fluid ' id='kt_app_main'>
                    <div className='d-flex flex-column flex-column-fluid'>
                      {/* <ToolbarWrapper /> */}
                      <Content>
                        <Outlet />
                      </Content>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
          <DrawerMessenger />
        </ThemeModeProvider>
      </PageDataProvider>
    )
  }
}

export {MasterLayout}
