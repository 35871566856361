import reduce from 'lodash/reduce'
import isEqual from 'lodash/isEqual'

// compares objects and returns a formatted difference object
export default function getDiff(old, changed) {
  let diff = reduce(
    changed,
    function (result, value, key) {
      return isEqual(value, old[key]) ? result : {...result, [key]: `${old[key]} -> ${value}`}
    },
    undefined
  )

  return diff ? diff : ''
}
