import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import Request1095BForm from '../forms/Request1095BForm.js'

export default function Request1095BFormDashgboard() {
  return (
    <DashboardBox>
      <DashboardSection name={'Request your 1095B'}>
        <Request1095BForm />
      </DashboardSection>
    </DashboardBox>
  )
}
