import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {Icon, IconName} from '../../../../../app/components/Icons/Icon'

type Props = {
  to: string
  title: string
  icon?: IconName
  fontIcon?: string
  hasBullet?: boolean
  hasSeparator?: boolean
  badge?: string
  className?: string
  refresh?: boolean
  disability?: boolean
  onClick?: () => void
}

const SidebarMenuItem: FC<Props & WithChildren> = ({children, to, title, icon, fontIcon, hasBullet = false, hasSeparator = false, badge, className, refresh = false, disability, onClick}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config

  if (!(disability && title === 'Disability Enrollment')) {
    return (
      <div className={clsx('menu-item', {'menu-item-w-separator': hasSeparator}, className)} onClick={onClick}>
        <Link target={refresh ? '_blank' : undefined} className={clsx('menu-link without-sub', {active: isActive})} to={to}>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
            <span className='menu-icon'>
              {' '}
              <Icon name={icon} props={{color: 'white'}} />
            </span>
          )}
          {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
          <span className='menu-title ml-1 text-white-primary'>{title}</span>
          {badge && <span className='badge bg-white rounded-4 text-primary'>{badge}</span>}
        </Link>
        {children}
      </div>
    )
  } else {
    return null
  }
}

export {SidebarMenuItem}
