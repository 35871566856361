import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
// Apps
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './index.scss'

// import {App} from './app/App'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import store, {persistor} from './app/store/store'
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
// import * as Sentry from '@sentry/react'

// const setupUserSession = async (message: any) => {
//   process.env.REACT_APP_DEBUG && console.log('index.tsx: setupUserSession')

//   if (message.payload) {
//     store.dispatch({type: 'RESET_REAUTH'})
//     store.dispatch({type: 'SET_AUTHTOKEN', payload: message.payload.idToken})

//     // configure loggers with user details
//     const {username, name, homeAccountId} = message.payload.account
//     // Identify user to Sentry
//     Sentry.setUser({email: username})
//     // Identify user to LogRocket
//     // LogRocket.identify(homeAccountId, {
//     //   name: name ? name : '',
//     //   email: username,
//     //   env: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'unknown'
//     // })
//   }
// }

// if (process.env.REACT_APP_ENV !== 'dev') {
// Setup Sentry
// Sentry.init({
//   dsn: 'https://8d0a1ca6bf8f496c8b060df9734f5c1b@o4504359075840000.ingest.sentry.io/4504359141638144',
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: ['localhost', /^.*bidcalcapi.*$/, /^.*hpm-api.*$/]
//     })
//   ],
//   environment: process.env.REACT_APP_ENV,
//   release: process.env.REACT_APP_VERSION,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0
// })

// }

// Add auth helper to authenticate api requests
setupAxios(axios, store)

setTimeout(() => {
  console.log('Rendering: index.tsx *******************************************')
  const container = document.getElementById('root')
  if (container) {
    createRoot(container).render(
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor}>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </PersistGate>
      </Provider>
    )
  }
})
