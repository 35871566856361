import {Icon, IconName} from '../Icons/Icon'

interface CardBlueLinkProps {
  text: string
  onClick: () => any
  icon?: IconName
}
const CardBlueLink = ({text, onClick, icon = 'add-doc'}: CardBlueLinkProps) => {
  return (
    <div className='card-blue-link' onClick={onClick}>
      <Icon name={icon} />
      <span>{text}</span>
    </div>
  )
}

export default CardBlueLink
