import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Chip from '@mui/material/Chip'
import {StyledTableCell, StyledTableRow} from '.'
import {Claim} from './HistoryClaimsTable'
import {Incentive} from './IncentivesHistoryTable'
import {Payment} from './PaymentsTable'
import {MakePayment} from './MakePaymentsTable'
import {Request1095B} from './HistoryRequest1095BTable'
import {useCallback} from 'react'

interface ClaimMobileTableProps {
  incentiveType: 'Claim'
  onClickDownload: (_: any) => any
  data: Array<Claim>
}

interface IncentiveMobileTableProps {
  incentiveType: 'Incentive'
  onClickDownload: (_: any) => any
  data: Array<Incentive>
}

interface PaymentMobileTableProps {
  incentiveType: 'Payment'
  onClickDownload: undefined
  data: Array<Payment>
}

interface Request1095BMobileTableProps {
  incentiveType: 'Request1095B'
  onClickDownload: undefined
  data: Array<Request1095B>
}

interface MakePaymentMobileTableProps {
  incentiveType: 'MakePayment'
  onClickDownload: (_: any) => any
  data: Array<MakePayment>
}

const renderClaimTable = (row: Claim) => {
  return (
    <table className={'w-100'}>
      <tr>
        <th className={'align-top'}>Date</th>
        <td className='text-end'>{row.created}</td>
      </tr>
      <tr>
        <th className={'align-top'}>Reason</th>
        <td className='text-end'>{row.comments}</td>
      </tr>
      <tr>
        <th className={'min-w-[80px] lg:min-w-[150px] align-top'}>Tracking</th>
        <td className='text-end'>{row.trackingInformation}</td>
      </tr>
    </table>
  )
}

const renderIncentiveTable = (row: Incentive) => {
  return (
    <table className={'w-100'}>
      <tr>
        <th className={'align-top'}>Date</th>
        <td className='text-end'>{row.created}</td>
      </tr>
      <tr>
        <th className={'align-top'}>Type</th>
        <td className='text-end'>{row.type}</td>
      </tr>
      <tr>
        <th className={'min-w-[80px] lg:min-w-[150px] align-top'}>Reason</th>
        <td className='text-end'>{row.reason}</td>
      </tr>
    </table>
  )
}

const renderPaymentTable = (row: Payment) => {
  return (
    <table className={'w-100'}>
      <tr>
        <th className={'align-top'}>Date</th>
        <td className='text-end'>{row.entry_date}</td>
      </tr>
      <tr>
        <th className={'align-top'}>Policy</th>
        <td className='text-end'>{row.policy}</td>
      </tr>
      <tr>
        <th className={'min-w-[80px] lg:min-w-[150px] align-top'}>Amount</th>
        <td className='text-end'>{row.amount}</td>
      </tr>
    </table>
  )
}

const renderRequest1095BTable = (row: Request1095B) => {
  return (
    <table className={'w-100'}>
      <tr>
        <th className={'align-top'}>Date</th>
        <td className='text-end'>{row.created}</td>
      </tr>
      <tr>
        <th className={'align-top'}>Request</th>
        <td className='text-end'>{row.request}</td>
      </tr>
    </table>
  )
}

const renderMakePaymentTable = (row: MakePayment) => {
  return (
    <table className={'w-100'}>
      <tr>
        <th className={'align-top'}>Policy</th>
        <td className='text-end'>{row.policy}</td>
      </tr>
      <tr>
        <th className={'align-top'}>Due Date</th>
        <td className='text-end'>{row.duedate}</td>
      </tr>
      <tr>
        <th className={'min-w-[80px] lg:min-w-[150px] align-top'}>Amount</th>
        <td className='text-end'>{row.amount}</td>
      </tr>
    </table>
  )
}

type MobileTableProps = ClaimMobileTableProps | IncentiveMobileTableProps | PaymentMobileTableProps | MakePaymentMobileTableProps | Request1095BMobileTableProps

export default function MobileTable({onClickDownload, incentiveType, data}: MobileTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
              {incentiveType === 'Incentive'
                ? 'Incentive Claims'
                : incentiveType === 'Request1095B'
                ? '1095B Request'
                : incentiveType === 'Payment'
                ? 'Payment'
                : incentiveType === 'MakePayment'
                ? 'Select which Policy amount to Pay'
                : 'Claims'}
            </StyledTableCell>
            <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, i: number) => {
            return <ContentTableRow key={i} i={i} incentiveType={incentiveType} row={row} onClickDownload={onClickDownload || undefined} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface TableRowProps {
  i: number
  incentiveType: 'Claim' | 'Incentive' | 'Payment' | 'MakePayment' | 'Request1095B'
  row: Claim | Incentive | Payment | MakePayment | Request1095B
  onClickDownload?: (_: any) => any
}

function ContentTableRow({i, row, incentiveType, onClickDownload}: TableRowProps) {
  const renderTableData = useCallback(() => {
    if (incentiveType === 'Claim') {
      return renderClaimTable(row as Claim)
    } else if (incentiveType === 'Incentive') {
      return renderIncentiveTable(row as Incentive)
    } else if (incentiveType === 'Payment') {
      return renderPaymentTable(row as Payment)
    } else if (incentiveType === 'MakePayment') {
      return renderMakePaymentTable(row as MakePayment)
    } else if (incentiveType === 'Request1095B') {
      return renderRequest1095BTable(row as Request1095B)
    }
  }, [incentiveType, row])
  return (
    <>
      {/* <TableRow key={i}>
        <div className='flex-col items-start justify-start gap-0 text-start pt-[16px] pl-[16px]'>
          <p className='text-black'>{row.created}</p>
        </div>
      </TableRow> */}
      <StyledTableRow key={i} onClick={onClickDownload ? () => onClickDownload(row) : undefined}>
        <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>{renderTableData()}</StyledTableCell>
        <StyledTableCell className={'mx-0 px-2'} style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
          {onClickDownload ? <ChevronRightIcon /> : null}
          {/* {onClickDownload ? <Chip label={incentiveType === 'MakePayment' ? 'Pay' : 'Download'} color='primary' onClick={() => onClickDownload(row)} /> : null} */}
        </StyledTableCell>
      </StyledTableRow>
    </>
  )
}
