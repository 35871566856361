interface PolicyCardProps {
  title: string
  children: any
  cardClassname?: string
  titleClassname?: string
}
export default function PolicyCard({title, children, cardClassname = '', titleClassname = ''}: PolicyCardProps) {
  return (
    <div className={['blue-info-card', cardClassname].join(' ')}>
      <div className='blue-info-card-container'>
        <h2 className={['blue-info-card-title', titleClassname].join(' ')}>{title}</h2>
        {children}
      </div>
    </div>
  )
}

interface CardInfoDisplayProps {
  data: {[key: string]: string}
  title?: string
  infoTextClassname?: string
}

export function CardInfoDisplay({data, title = '', infoTextClassname = ''}: CardInfoDisplayProps) {
  //
  if (data) {
    const keys = Object.keys(data)
    return (
      <div className='flex flex-col items-start justify-start gap-4 py-4 w-full'>
        {title && <p className='card-info-display-title'>{title}</p>}
        {keys.map((label) => {
          // Check if key or one of the fields listed in a different part of the page
          if (label !== 'key' && label !== 'Balance Due' && label !== 'modified') {
            const value = data[label]
            // Check for null or 0 dollar amount
            if (value && value !== '$0.00') {
              return <Row label={label} key={label} value={value} infoTextClassname={infoTextClassname} />
            } else {
              return null
            }
          } else {
            return null
          }
        })}
      </div>
    )
  } else {
    return null
  }
}

interface RowProps {
  label: string
  value: string
  infoTextClassname?: string
}
function Row({label, value, infoTextClassname = ''}: RowProps) {
  const labelTextClassname = `info-card-row-text min-w-[140px] w-[140px] ${infoTextClassname}`
  const valueTextClassname = `info-card-row-text flex-grow ${infoTextClassname}`
  return (
    <div className='info-card-row'>
      <p className={labelTextClassname}>{label}</p>
      <p className={valueTextClassname}>{value}</p>
    </div>
  )
}
