import {SVGProps} from 'react'

export default function NotesClockIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width='24'
      height='24'
      className='main-grid-item-icon'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      {...props}
    >
      <g clipPath='url(#clip0_63_7789)'>
        <path
          d='M17.25 23.25C20.5637 23.25 23.25 20.5637 23.25 17.25C23.25 13.9363 20.5637 11.25 17.25 11.25C13.9363 11.25 11.25 13.9363 11.25 17.25C11.25 20.5637 13.9363 23.25 17.25 23.25Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M19.902 17.25H17.25V14.598' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path
          d='M8.25 20.25H2.25C2.05167 20.2549 1.8544 20.2195 1.67018 20.1459C1.48596 20.0723 1.31862 19.9619 1.17834 19.8217C1.03805 19.6814 0.927747 19.514 0.854113 19.3298C0.78048 19.1456 0.745058 18.9483 0.749998 18.75V3.857C0.736497 3.44517 0.886794 3.04479 1.16796 2.74357C1.44912 2.44235 1.83822 2.26486 2.25 2.25H17.25C17.6618 2.26486 18.0509 2.44235 18.332 2.74357C18.6132 3.04479 18.7635 3.44517 18.75 3.857V8.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M5.25 0.75V5.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.75 0.75V5.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M14.25 0.75V5.25' stroke='currentColor' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
      </g>
      <defs>
        <clipPath id='clip0_63_7789'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
