interface DashboardSectionProps {
  name: string
  children: any
  className?: string
  button?: any
}
export default function DashboardSection({name, children, className, button}: DashboardSectionProps) {
  const boxClassName = `dashboard-section ${className}`
  return (
    <div className={boxClassName}>
      <div className='flex items-center justify-between w-full'>
        <h1 className='dashboard-title'>{name}</h1>
        {button && button}
      </div>
      {children}
    </div>
  )
}
