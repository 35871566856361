import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'
import {DrawerComponent} from '../../_metronic/assets/ts/components'

const useReinitialiseDrawer = () => {
  const {pathname} = useLocation()
  const isFirstRun = useRef(true)

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
    } else {
      DrawerComponent.reinitialization()
    }
  }, [pathname])
}

export default useReinitialiseDrawer
