import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Grid, Divider, InputAdornment, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Select, Checkbox, MenuItem, Box} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Card from 'react-bootstrap/Card'
import {States} from '../../components/Misc/States'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
// import FormHelperText from '@mui/material/FormHelperText'
import Spinner from 'react-bootstrap/Spinner'
import {Formik, useFormikContext} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import * as Yup from 'yup'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import dayjs from 'dayjs'
import sample_check from '../../../_metronic/assets/images/sample_check.gif'
import {FormLabel} from 'react-bootstrap'

export default function Disability() {
  //
  // Define a few variables
  const dispatch = useDispatch()

  const returnStatus = useSelector((state) => state.member.ReturnStatus)
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  function Savesubmit(data) {
    // Open Submitting modal
    setOpen(true)
    setIsLoading(true)
    // Create structure to load file
    const formData = new FormData()
    // Add fields
    formData.append('Agency', data['Agency'])
    formData.append('Coverage_requested', data['Coverage_requested'])
    formData.append('Coverage_requested2', data['Coverage_requested2'])
    formData.append('DD_ach_bank_routing_number', data['DD_ach_bank_routing_number'])
    formData.append('DD_bank_acct_holder1_name', data['DD_bank_acct_holder1_name'])
    formData.append('DD_bank_acct_holder1_ssn', data['DD_bank_acct_holder1_ssn'])
    formData.append('DD_bank_acct_holder2_name', data['DD_bank_acct_holder2_name'])
    formData.append('DD_bank_acct_holder2_ssn', data['DD_bank_acct_holder2_ssn'])
    formData.append('DD_bank_acct_number', data['DD_bank_acct_number'])
    formData.append('DD_bank_acct_type', data['DD_bank_acct_type'])
    formData.append('DD_bank_branch', data['DD_bank_branch'])
    formData.append('DD_bank_city', data['DD_bank_city'])
    formData.append('DD_bank_name', data['DD_bank_name'])
    formData.append('DD_bank_state', data['DD_bank_state'])
    formData.append('DD_bank_zip', data['DD_bank_zip'])
    formData.append('Domestic_partner', data['Domestic_partner'])
    formData.append('Drug_treatment', data['Drug_treatment'])
    formData.append('Drug_treatment_details', data['Drug_treatment_details'])
    formData.append('Enroll_Action', data['Enroll_Action'])
    formData.append('Hazard', data['Hazard'])
    formData.append('Hazard_details', data['Hazard_details'])
    formData.append('Height_feet', data['Height_feet'])
    formData.append('Height_inches', data['Height_inches'])
    formData.append('Lump_sum_benefit_requested', data['Lump_sum_benefit_requested'])
    formData.append('Major_duties', data['Major_duties'])
    formData.append('Monthly_benefit_requested', data['Monthly_benefit_requested'])
    formData.append('Occupation', data['Occupation'])
    formData.append('Physician_condition', data['Physician_condition'])
    formData.append('Physician_condition_details', data['Physician_condition_details'])
    formData.append('Plan_name', data['Plan_name'])
    formData.append('Salary', data['Salary'])
    formData.append('Weight', data['Weight'])
    formData.append('Work_location', data['Work_location'])
    formData.append('e_signature', data['e_signature'])
    formData.append('e_signature_accept', data['e_signature_accept'])
    formData.append('e_signature_date', data['e_signature_date'])
    // formData.append('void_check',data['attachment']);
    // Some debugging
    if (process.env.REACT_APP_DEBUG) {
      console.log(data)
    }
    // Save request/form
    dispatch(Member.actions.putDisabilityEnrolmentData({formData}))
  }

  function SelectAction() {
    //
    const {handleChange, values} = useFormikContext()
    //
    // For some (as yet unknown) reason the client is asked if this is a new application for cover or resuming an existing cover
    //
    const fieldName = 'Enroll_Action'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' id='enrollaction-label'>
            Type of Enrollment
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={6}>
          <Select
            aria-label='Enrollment Action'
            id='iwishto'
            className={'w-100'}
            labelId='iwishto-label'
            label='I wish to...'
            name={fieldName}
            value={values[fieldName]}
            onChange={handleChange(fieldName)}
          >
            <MenuItem key='I wish to enroll in the Disability Insurance Plan as a New Enrollment.' value='I wish to enroll in the Disability Insurance Plan as a New Enrollment.'>
              Enroll as a NEW ENROLLMENT
            </MenuItem>
            <MenuItem key='I wish to Re-enroll in the Disability Insurance Plan.' value='I wish to Re-enroll in the Disability Insurance Plan.'>
              RE-ENROLL
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function SelectAgency() {
    //
    const {handleChange, values, errors, touched} = useFormikContext()
    const fieldName = 'Agency'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' id='claimforperson-label'>
            Select your agency
          </FormLabel>
          <Grid item xs={6} md={6}>
            <Select aria-label='Agency Selection' id='agency' className={'w-100'} labelId='agency-label' label='Agency' name={fieldName} value={values[fieldName]} onChange={handleChange(fieldName)}>
              <MenuItem key='Blank' value=' '></MenuItem>
              <MenuItem key='AFSPA' value='AFSPA'>
                AFSPA
              </MenuItem>
              <MenuItem key='Broadcasting Board of Governors' value='Broadcasting Board of Governors'>
                Broadcasting Board of Governors
              </MenuItem>
              <MenuItem key='Department of Defense' value='Department of Defense'>
                Department of Defense
              </MenuItem>
              <MenuItem key='Executive Branch' value='Executive Branch'>
                Executive Branch
              </MenuItem>
              <MenuItem key='Foreign Agricultural Service' value='Foreign Agricultural Service'>
                Foreign Agricultural Service
              </MenuItem>
              <MenuItem key='Foreign Commercial Service' value='Foreign Commercial Service'>
                Foreign Commercial Service
              </MenuItem>
              <MenuItem key='Peace Corps' value='Peace Corps'>
                Peace Corps
              </MenuItem>
              <MenuItem key='State' value='State'>
                State
              </MenuItem>
              <MenuItem key='USAID - Civil Service' value='USAID - Civil Service'>
                USAID - Civil Service
              </MenuItem>
              <MenuItem key='USAID - Foreign Service' value='USAID - Foreign Service'>
                USAID - Foreign Service
              </MenuItem>
            </Select>
            {touched[fieldName] && !!errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
          </Grid>
        </Grid>
      </>
    )
  }

  function InputSalary() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'Salary'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          <FormLabel className='gray-1-text' id='annualsalary-label'>
            Enter your Annual Salary
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            type='text'
            className={'mt-0'}
            variant='outlined'
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={({classes: {input: 'input-profile'}}, {startAdornment: <InputAdornment position='start'>$</InputAdornment>})}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function InputOccupation() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'Occupation'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          <FormLabel className='gray-1-text' id='claimforperson-label'>
            Enter your Occupation
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            style={{margin: '14px 0 6px 0', minWidth: '50%'}}
            type='text'
            variant='outlined'
            className={'mt-0'}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function InputLocation() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'Work_location'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          <FormLabel className='gray-1-text' id='worklocation-label'>
            Enter your Work Location
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            type='text'
            style={{margin: '14px 0 6px 0', minWidth: '50%'}}
            variant='outlined'
            className={'mt-0'}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function InputDuties() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'Major_duties'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          <FormLabel className='gray-1-text' id='majorduties-label'>
            Enter your major duties
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            type='text'
            variant='outlined'
            style={{margin: '14px 0 6px 0', minWidth: '90%'}}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            multiline
            rows={4}
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function SelectPlans() {
    // Check Enrollment formType
    //
    // This is a dynamic call to disability plans in the current Portal - not sure why as there are only three plans in the table - two of them are covered in this form
    // The value is set to the id's from the table as this part of an FK when this record is written to the db
    //
    const {handleChange, values} = useFormikContext()
    const fieldName = 'Plan_name'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' id='planname-label'>
            Please select your plan
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select aria-label='Disability Plan Name' id='planname' className={'w-100'} labelId='planname-label' name={fieldName} value={values[fieldName]} onChange={handleChange(fieldName)}>
            <MenuItem key='Cigna 2 Year (30 Day)' value='14'>
              Cigna 2 Year (30 Day)
            </MenuItem>
            <MenuItem key='Cigna 2 Year (90 Day)' value='16'>
              Cigna 2 Year (90 Day)
            </MenuItem>
            <MenuItem key='Cigna 5 Year (30 Day)' value='15'>
              Cigna 5 Year (30 Day)
            </MenuItem>
            <MenuItem key='Cigna 5 Year (90 Day)' value='17'>
              Cigna 5 Year (90 Day)
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function ConfirmTotalDisability() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'Coverage_requested'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={5}>
          <p className='claim-text'>Require Total Disability</p>
        </Grid>
        <Grid item className={'mt-5'} xs={12} md={1}>
          <Checkbox aria-label='Total Disability Coverage Requested' name={fieldName} checked={values[fieldName]} onChange={handleChange(fieldName)} />
          {touched[fieldName] && !!errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
        </Grid>
      </>
    )
  }

  function InputBenefitRequested() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'Monthly_benefit_requested'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          <FormLabel className='gray-1-text' id='annualsalary-label'>
            Monthly Benefit Requested
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            type='text'
            variant='outlined'
            className={'mt-0'}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={({classes: {input: 'input-profile'}}, {startAdornment: <InputAdornment position='start'>$</InputAdornment>})}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
          <label style={{marginLeft: 10}}>(60% of salary, up to $5,000)</label>
        </Grid>
      </>
    )
  }

  function SelectHazardous() {
    //
    const {handleChange, values} = useFormikContext()
    const fieldName = 'Hazard'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          Do you engage in any hazardous sport, hobby or avocation such as mountain biking, auto racing, roller blading, bungee jumping, scuba diving, hang gliding, parachuting, technical climbing,
          etc?
        </Grid>
        <Grid item xs={12} md={3}>
          <Select aria-label='Hazardous Description' id='planname' name={fieldName} value={values[fieldName]} onChange={handleChange(fieldName)}>
            <MenuItem key='No' value='No'>
              No
            </MenuItem>
            <MenuItem key='Yes' value='Yes'>
              Yes
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function InputHazardous() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // Check if Hazard select is Yes
    if (values.Hazard !== 'Yes') {
      return null
    }
    //
    const fieldName = 'Hazard_details'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          <FormLabel className='gray-1-text' id='hazarddetails-label'>
            Please include details
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            type='text'
            variant='outlined'
            style={{minWidth: '90%'}}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            multiline
            rows={4}
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function DisplayChequeImage() {
    //
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <Alert severity='info'>
            NOTE: Premiums must be paid by electronic debit from your bank account. We do not accept premium payments using any other method. Deductions will be on a monthly basis from your bank
            account. If you currently use the direct debit process for another program with AFSPA, we will use that banking information for this policy. To set up direct debit please fill out the
            information below. You will need to know your Routing Number (ABA) and your Account Number. See the image for clarification.
          </Alert>
        </Grid>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <img src={sample_check} alt='Sample Check' style={{maxWidth: '100%'}} />
        </Grid>
      </>
    )
  }

  function InputDepositoryName() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_name'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={9}>
          <FormLabel className='gray-1-text' id='depositoryname-label'>
            Depository Name and Details
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            type='text'
            style={{margin: '14px 0 6px 0', minWidth: '50%'}}
            variant='outlined'
            className={'mt-0'}
            margin='normal'
            label='Depository Name'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function InputDDBranch() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_branch'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={6} md={6}>
          <TextField
            type='text'
            style={{margin: '14px 0 6px 0', minWidth: '90%'}}
            variant='outlined'
            className={'mt-0'}
            margin='normal'
            label={'Branch'}
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </>
    )
  }

  function InputDDCity() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_city'
    //
    return (
      <Grid item className={'mt-5'} xs={12} md={6}>
        <TextField
          type='text'
          style={{margin: '14px 0 6px 0', minWidth: '90%'}}
          variant='outlined'
          className={'mt-0'}
          label={'City'}
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          size='medium'
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
        />
      </Grid>
    )
  }

  function SelectDDState() {
    //
    const {setFieldValue, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_state'
    //
    return (
      <Grid item xs={12} md={6}>
        <Autocomplete
          id='statelookup'
          disableClearable
          options={States}
          value={States.find((v) => v.id === values.fieldName) || null}
          onChange={(e, value) => {
            setFieldValue(fieldName, value.id)
          }}
          getOptionLabel={(option) => option.name}
          // style={{minWidth: '70%'}}
          // style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              type='text'
              variant='outlined'
              margin='normal'
              id='DD_bank_state'
              name='DD_bank_state'
              label='State'
              className={'mt-5 w-80'}
              helperText={touched.DD_bank_state ? errors.DD_bank_state : ''}
              error={touched.DD_bank_state && Boolean(errors.DD_bank_state)}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
                'aria-label': 'Direct Debit Bank StateD'
              }}
            />
          )}
        />
      </Grid>
    )
  }

  function InputDDZip() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_zip'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <TextField
            type='text'
            style={{margin: '14px 0 6px 0', minWidth: '90%'}}
            variant='outlined'
            className={'mt-0'}
            label={'Zip'}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
        <Grid item xs={3} md={3}></Grid>
      </>
    )
  }

  function InputDDRoutingNumber() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_ach_bank_routing_number'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <TextField
            type='text'
            style={{margin: '14px 0 6px 0', minWidth: '50%'}}
            variant='outlined'
            className={'mt-0'}
            label={'Routing Number'}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <Alert severity='info'>
            This is the 9-digit number which appears in the lower left corner of your checks. If unsure, contact your financial institution and ask for the Routing Transit Number for your account.
            format: #########, like this: 123456789
          </Alert>
        </Grid>
      </>
    )
  }

  function SelectDDAccountType() {
    //
    const {handleChange, values} = useFormikContext()
    const fieldName = 'DD_bank_acct_type'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' id='accountType-label'>
            Account Type and Details
          </FormLabel>
        </Grid>
        <Grid item xs={5} md={12}>
          <Select
            aria-label='Direct Deposit Account Type'
            id='DD_bank_acct_type'
            className={'w-50'}
            labelId='DD_bank_acct_type-label'
            name={fieldName}
            value={values[fieldName]}
            onChange={handleChange(fieldName)}
          >
            <MenuItem key={'savings'} value={'savings'}>
              Savings
            </MenuItem>
            <MenuItem key={'checking'} value={'checking'}>
              Checking
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function InputDDAccountNumber() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_acct_number'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <TextField
            type='text'
            style={{margin: '14px 0 6px 0', minWidth: '50%'}}
            variant='outlined'
            className={'mt-0'}
            label={'Account Number'}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <Alert severity='info'>
            Important! Please verify your bank information (above) and make necessary corrections. Incorrect information will result in delays and processing of your direct deposit request with AFSPA.
          </Alert>
        </Grid>
      </>
    )
  }

  function InputDDAccountHolder() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_acct_holder1_name'
    //
    return (
      <Grid item className={'mt-5'} xs={12} md={6}>
        <TextField
          type='text'
          style={{margin: '14px 0 6px 0', minWidth: '100%'}}
          variant='outlined'
          className={'mt-0'}
          label={'Account Holder'}
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          size='medium'
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
        />
      </Grid>
    )
  }

  function InputDDAccountHolderSSN() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_acct_holder1_ssn'
    //
    return (
      <Grid item className={'mt-5'} xs={12} md={6}>
        <TextField
          type='text'
          style={{margin: '14px 0 6px 0', minWidth: '100%'}}
          variant='outlined'
          className={'mt-0'}
          label={'Account Holder SSN'}
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          size='medium'
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
        />
      </Grid>
    )
  }

  function InputDDJointAccountHolder() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_acct_holder2_name'
    //
    return (
      <Grid item className={'mt-5'} xs={12} md={6}>
        <TextField
          type='text'
          style={{margin: '14px 0 6px 0', minWidth: '100%'}}
          variant='outlined'
          className={'mt-0'}
          label={'Joint Accnt. Holder'}
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          size='medium'
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
        />
      </Grid>
    )
  }

  function InputDDJointAccountHolderSSN() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'DD_bank_acct_holder2_ssn'
    //
    return (
      <Grid item className={'mt-5'} xs={12} md={6}>
        <TextField
          type='text'
          style={{margin: '14px 0 6px 0', minWidth: '100%'}}
          variant='outlined'
          className={'mt-0'}
          label={'Joint Accnt. Holder SSN'}
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          size='medium'
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
        />
      </Grid>
    )
  }

  function InputSignatureName() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'e_signature'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' id='esignature-label'>
            E-Signature
          </FormLabel>
        </Grid>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <TextField
            type='text'
            style={{margin: '14px 0 6px 0', minWidth: '90%'}}
            variant='outlined'
            className={'mt-0'}
            label={'Full Name'}
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            size='medium'
            onChange={handleChange(fieldName)}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
            InputProps={{classes: {input: 'input-profile'}}}
          />
        </Grid>
      </>
    )
  }

  function InputSignatureDate() {
    //
    // Needs to be a date control - the API will not handle a non date entry well
    //
    const {setFieldValue, values, touched, errors} = useFormikContext()
    const fieldName = 'e_signature_date'
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={6}>
          <Grid item xs={12} md={9}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id={fieldName}
                name={fieldName}
                className={'w-100'}
                onChange={(value) => setFieldValue(fieldName, value, true)}
                value={values[fieldName] ? dayjs(values[fieldName]) : dayjs()}
                slotProps={{
                  textField: {
                    fullWidth: false,
                    variant: 'outlined',
                    error: !!errors[fieldName],
                    helperText: errors[fieldName]?.message
                  }
                }}
                error={errors[fieldName]}
                touched={touched[fieldName]}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </>
    )
  }

  function SelectSignature() {
    //
    const {handleChange, values, touched, errors} = useFormikContext()
    const fieldName = 'e_signature_accept'
    console.log(values[fieldName])
    //
    return (
      <>
        <Grid item className={'mt-5'} xs={12} md={12}>
          <FormLabel className='gray-1-text' id='declaration-label'>
            Declaration
          </FormLabel>
        </Grid>
        <Grid item className={'mt-5'} xs={2} md={2}>
          <Checkbox aria-label='Signature Declaration' name={fieldName} checked={values[fieldName]} onChange={handleChange(fieldName)} />
          {touched[fieldName] && !!errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
        </Grid>
        <Grid item className={'mt-5'} xs={10} md={10}>
          <p className='claim-text'>
            I understand that checking this box constitutes a legal signature confirming I declare that the above statements are true and complete, and that, apart from the matters declared above, I
            am in good health and ordinarily enjoy good health.
          </p>
          <p className='claim-text'>I agree to the Underwriters obtaining medical information from any doctor who has attended me and authorize such doctor to give this information.</p>
          <p className='claim-text'>
            I agree that this proposal shall form the basis of the contract should the insurance company be affected and any misstatements or omissions above may be ground for rescission.
          </p>
          <p className='claim-text'>I understand that pre-existing conditions are not covered unless disclosed on this application, and agree to be covered by the underwriters.</p>
        </Grid>
      </>
    )
  }

  function ErrorsNotification({errors, touched}) {
    if ((errors && Object.keys(errors).length > 0 && touched && Object.keys(touched).length > 0) || (errors && errors.PersonFiles)) {
      return (
        <>
          <Card.Body>
            <div className='errors-list'>
              <h4 className='errors-list-heading'>Please fix the following fields before you submit:</h4>
              <ul>
                {Object.keys(errors).map((msg) => (
                  <React.Fragment key={msg}>{touched[msg] || msg === 'PersonFiles' ? <li>{errors[msg]}</li> : null}</React.Fragment>
                ))}
              </ul>
            </div>
          </Card.Body>
        </>
      )
    } else {
      return null
    }
  }

  // Set defaults
  const defaultV = {}
  defaultV['Enroll_Action'] = 'I wish to enroll in the Disability Insurance Plan as a New Enrollment.'
  defaultV['Agency'] = ''
  defaultV['Occupation'] = ''
  defaultV['Work_location'] = ''
  defaultV['Major_duties'] = ''
  defaultV['Plan_name'] = '14'
  defaultV['Coverage_requested'] = false
  defaultV['Monthly_benefit_requested'] = ''
  defaultV['Coverage_requested2'] = false
  defaultV['Lump_sum_benefit_requested'] = ''
  defaultV['Physician_condition'] = 'No'
  defaultV['Physician_condition_details'] = ''
  defaultV['Drug_treatment'] = 'No'
  defaultV['Drug_treatment_details'] = ''
  defaultV['Hazard'] = 'No'
  defaultV['Hazard_details'] = ''
  defaultV['DD_bank_name'] = ''
  defaultV['DD_bank_branch'] = ''
  defaultV['DD_bank_city'] = ''
  defaultV['DD_bank_state'] = ''
  defaultV['DD_bank_zip'] = ''
  defaultV['DD_ach_bank_routing_number'] = ''
  defaultV['DD_bank_acct_number'] = ''
  defaultV['DD_bank_acct_holder1_name'] = ''
  defaultV['DD_bank_acct_holder1_ssn'] = ''
  defaultV['DD_bank_acct_holder2_name'] = ''
  defaultV['DD_bank_acct_holder2_ssn'] = ''
  defaultV['DD_bank_acct_type'] = 'checking'
  defaultV['e_signature'] = ''
  defaultV['e_signature_date'] = null
  defaultV['e_signature_accept'] = false
  //
  // Display default items to console log
  if (process.env.REACT_APP_DEBUG) {
    // console.log(defaultV);
  }

  const validationSchema = Yup.object().shape({
    Agency: Yup.string()
      .oneOf(
        [
          'AFSPA',
          'Broadcasting Board of Governors',
          'Department of Defense',
          'Executive Branch',
          'Foreign Agricultural Service',
          'Foreign Commercial Service',
          'Peace Corps',
          'State',
          'USAID - Civil Service',
          'USAID - Foreign Service'
        ],
        'Agency is required'
      )
      .required('Agency is required'),
    Salary: Yup.string()
      .required('Salary is required')
      .matches(/^[0-9]+$/, 'Salary must be numeric')
      .min(5, 'Salary must be over $10,000')
      .max(7, 'Salary is required'),
    Occupation: Yup.string().min(3, 'Too Short').required('Occupation is required'),
    Work_location: Yup.string().min(3, 'Too Short').required('Location is required'),
    Major_duties: Yup.string().min(3, 'Too Short').required('Major Duties are required'),
    Drug_treatment_details: Yup.string()
      .ensure()
      .when('Drug_treatment', {
        is: 'Yes',
        then: Yup.string().required('Drug Treatment Details are required').min(5, 'Drug Treatment Details are required')
      }),
    Hazard_details: Yup.string()
      .ensure()
      .when('Hazard', {
        is: (Hazard) => Hazard === 'Yes',
        then: (schema) => schema.required('Hazard Details are required').min(5, 'Hazard Details are required')
      }),
    Physician_condition_details: Yup.string()
      .ensure()
      .when('Physician_condition', {
        is: 'Yes',
        then: Yup.string().required('Health Condition Details are required').min(5, 'Health Condition are required')
      }),
    DD_ach_bank_routing_number: Yup.string()
      .required('Routing Number is required')
      .matches(/^[0-9]+$/, 'Routing Number must be numeric')
      .min(8, 'Too Short')
      .max(10, 'Too Long'),
    DD_bank_acct_number: Yup.string()
      .required('Account Number is required')
      .matches(/^[0-9]+$/, 'Account Number must be numeric')
      .min(3, 'Too Short'),
    DD_bank_branch: Yup.string().min(3, 'Too Short').required('Bank Branch is required'),
    DD_bank_city: Yup.string().min(3, 'Too Short').required('Bank Branch City is required'),
    DD_bank_name: Yup.string().min(3, 'Too Short').required('Bank Branch Name is required'),
    DD_bank_state: Yup.string().required('Bank Branch State is required'),
    DD_bank_zip: Yup.string().min(3, 'Too Short').min(5, 'Too Long').required('Bank Branch Zip is required'),
    DD_bank_acct_holder1_name: Yup.string().min(3, 'Too Short').required('Account Holder Name is required'),
    DD_bank_acct_holder1_ssn: Yup.string()
      .required('Account Holder SSN is required')
      .matches(/^[0-9]+$/, 'Account Holder SSN must be numeric')
      .min(8, 'Too Short')
      .max(10, 'Too Long'),
    DD_bank_acct_holder2_name: Yup.string().min(3, 'Too Short'),
    DD_bank_acct_holder2_ssn: Yup.string()
      .matches(/^[0-9]+$/, 'Joint Account Holder SSN must be numeric')
      .min(8, 'Too Short')
      .max(10, 'Too Long'),
    Lump_sum_benefit_requested: Yup.string()
      .ensure()
      .when('Coverage_requested2', {
        is: true,
        then: Yup.string()
          .required('Lump Sum Benefit is required')
          .matches(/^[0-9]+$/, 'Lump Sum Benefit must be numeric')
          .max(7, 'Lump Sum Benefit is required')
      }),
    Monthly_benefit_requested: Yup.string()
      .required('Monthly Benefit is required')
      .matches(/^[0-9]+$/, 'Benefit Requested must be numeric')
      .max(5, 'Too Long'),
    e_signature: Yup.string().min(2, 'Too Short').max(255, 'Too Long').required('Name is required'),
    e_signature_accept: Yup.boolean().oneOf([true], 'Declaration Required'),
    e_signature_date: Yup.date().typeError('E Signature date must be a date').required('E Signature date required')
  })

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={defaultV}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true)
          Savesubmit(values)
        }}
      >
        {(props) => {
          const {errors, touched, handleSubmit} = props
          return (
            <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
              <Row className='mb-5'>
                <Col xs={12} md={9}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <p className='claim-text'>
                        It takes just a few minutes to enroll on line...simply follow the instructions.After completing the data entry, please review your application for accuracy and then click on
                        the submit button. You will be notified by email when your application has been received.
                      </p>
                      <Alert severity='info'>
                        NOTE: This plan is only payable by Direct Debit (monthly withdrawal) from your designated financial banking institution. We can not start the review/application process until
                        we receive ALL of the required information.
                      </Alert>
                    </Grid>
                  </Grid>
                </Col>
              </Row>
              <Row className='mb-5'>
                <Col xs={12} md={9}>
                  <Card>
                    <Card.Body>
                      <Grid container spacing={1}>
                        <SelectAction />
                        <SelectAgency />
                        <InputSalary />
                        <InputOccupation />
                        <InputLocation />
                        <InputDuties />
                        <SelectPlans />
                        <ConfirmTotalDisability />
                        <InputBenefitRequested />
                        <SelectHazardous />
                        <InputHazardous />
                        <DisplayChequeImage />
                        <InputDepositoryName />
                        <InputDDBranch />
                        <InputDDCity />
                        <SelectDDState />
                        <InputDDZip />
                        <SelectDDAccountType />
                        <InputDDRoutingNumber />
                        <InputDDAccountNumber />
                        <InputDDAccountHolder />
                        <InputDDAccountHolderSSN />
                        <InputDDJointAccountHolder />
                        <InputDDJointAccountHolderSSN />
                        <Divider />
                        <InputSignatureName />
                        <InputSignatureDate />
                        <Divider />
                        <SelectSignature />
                      </Grid>
                    </Card.Body>
                    <Divider />
                    <ErrorsNotification errors={errors} touched={touched} />
                    <Card.Body>
                      <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                        <Grid container xs={12} gap={3} justifyContent={'end'}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Cancel
                            </Button>
                          </Link>
                          <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                            Submit
                          </Button>
                        </Grid>
                      </Box>

                      <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                        <Grid container xs={12} justifyContent={'space-between'}>
                          <Grid item className={'pr-2'} xs={6}>
                            <Link to='/home'>
                              <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                Cancel
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item className={'pl-2'} xs={6}>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )
        }}
      </Formik>

      <Dialog open={open} aria-labelledby='Submitting Enrollment Application Please Wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Submitting Enrollment Application</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Enrollment Application Submitted Successfully</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                            Return to Home
                          </Button>
                        </Link>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                      <div className='text-danger'>
                        <strong>{returnStatus}</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                            Return to Home
                          </Button>
                        </Link>
                        <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                          Edit and Resubmit Claim
                        </Button>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item className={'pl-2'} xs={6}>
                          <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                            Edit and Resubmit Claim
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
