import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import DashboardBox from '../../components/DashboardBox'
import DashboardSection from '../../components/Cards/DashboardSection'
import {Button} from '@mui/material'

export default function NewsDashboard() {
  const news = useSelector((state: any) => state.member.News)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(Member.actions.fetchNews())
  }, [dispatch])

  interface newsitem {
    id: number
    link: string
    story: string
    title: string
    imagelink: string
    newsdate: string
  }

  return (
    <DashboardBox>
      <DashboardSection name={'Latest News'}>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-[30px]'>
          {news &&
            news.map((dataEl: newsitem, i: any) => {
              return <NewsCard key={i} {...dataEl} />
            })}
        </div>
      </DashboardSection>
    </DashboardBox>
  )
}

interface NewsCardProps {
  id: number
  link: string
  story: string
  title: string
  imagelink: string
  newsdate: string
}

function NewsCard({imagelink, title, newsdate, link, story}: NewsCardProps) {
  function handleClick() {}
  return (
    <div className='news-card'>
      <a href={link}>
        <img src={imagelink} alt={title} className='w-full' />
        <div className='py-8 px-4 gap-3'>
          <h3>{title}</h3>
          <p className='publication-text'>{newsdate}</p>
          <p className='news-text line-clamp-3 mb-12'>{story}</p>
          <Button variant='contained' onClick={handleClick}>
            <p className='mb-0 p-1.5'>{'Read more'}</p>
          </Button>
        </div>
      </a>
    </div>
  )
}
