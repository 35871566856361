import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import * as Auth from '../../../store/ducks/auth.duck'
import Spinner from 'react-bootstrap/Spinner'
import {useNavigate, useLocation} from 'react-router-dom'
import {login} from '../core/_requests'

export function DashboardAuth() {
  //

  console.log('In Dashboard Auth')

  const [loading, setLoading] = useState(false)
  const [runOnce, setRunOnce] = useState(false)
  const ssoStatus = useSelector((state) => state.auth.SSOStatus)
  let navigate = useNavigate()

  const enableLoading = () => {
    setLoading(true)
    // setLoadingButtonStyle({ paddingRight: "3.5rem" });
  }

  const disableLoading = () => {
    setLoading(false)
    navigate('/home')
    // setLoadingButtonStyle({ paddingRight: "2.5rem" });
  }

  const searchParams = useLocation().search
  const uid = new URLSearchParams(searchParams).get('uid_bf')
  const mk = new URLSearchParams(searchParams).get('mk_bf')
  const rn = new URLSearchParams(searchParams).get('rn_bf')
  const page = new URLSearchParams(searchParams).get('p')

  // console.log(uid)
  // console.log(mk)
  // console.log(rn)

  const username = 'device'
  const password = mk + ':' + rn + ':' + uid

  const dispatch = useDispatch()

  useEffect(() => {
    //
    // Some Commentary about this
    // Essentially when someone creates a submits a form, a processing window appears and disappears once the form is processed
    // To do this a Redux variable - <formtype>Form  is set with a number - null is not set, 1 is processing and 2 is an error state
    //
    if (ssoStatus) {
      checkFormStatus(ssoStatus)
    }
  })

  function checkFormStatus(ssoStatus) {
    if (ssoStatus) {
      // Ok processed finished successfully - we have logged into VH
      // console.log('Process finished successfully');
      // Turn off loading screen
      setLoading(false)
      // Reset SSO status
      dispatch(Auth.actions.ssoclear())
      // Back to the main menu
      if (page && page.length > 0) {
        navigate(`/${page}`)
      } else {
        navigate('/home')
      }
    }
  }

  async function attemptLogin() {
    try {
      dispatch(Auth.actions.ssoclear())
      setRunOnce(true)
      enableLoading()
      const {data} = await login(username, password)
      // console.log('Logging In')
      // console.log(data)
      dispatch(Auth.actions.login(data))
      // console.log('VH Login')
      await dispatch(Auth.actions.getVHLogin(data.member_key, data.token, data.email))
    } catch (err) {
      disableLoading()
    }
  }

  if (!loading && !runOnce) {
    // Prevent the login if not all parameters are present - there is a googlebot pinging this without a membership number
    if (mk && uid && rn) {
      attemptLogin()
    } else {
      setRunOnce(true)
      // console.log('Variable problems')
      navigate('/home')
    }
  }

  return (
    <>
      {loading ? (
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait while we log you in... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </span>
      ) : null}
    </>
  )
  //
  //
}
