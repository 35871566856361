import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import FeedbackIcon from '@mui/icons-material/Feedback'
import CloseIcon from '@mui/icons-material/Close'
import {useIntl} from 'react-intl'
import {Icon} from '../../components/Icons/Icon'
import {NavLink} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {SidebarMenuMain} from '../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuMain'
import clsx from 'clsx'

interface MobileMenuProps {
  onClose: () => any
  onLogout: () => any
  isShown: boolean
}
// eslint-disable-next-line no-empty-pattern
export default function MobileMenu({onClose, onLogout, isShown}: MobileMenuProps) {
  const intl = useIntl()
  const FSBP = useSelector((state: any) => state.member.FSBP)
  return (
    <div className={clsx('absolute bg-primary top-0 flex-col left-0 right-0 bottom-0 w-full min-h-dvh overflow-y-scroll pt-[20px] mobile-menu app-sidebar', isShown && 'mobile-menu-show')}>
      <div className='flex items-center justify-end pb-5'>
        <Button onClick={onClose}>
          <CloseIcon sx={{color: 'white', fontSize: 24}} />
        </Button>
      </div>

      {FSBP ? (
        <div className='flex items-start px-8' onClick={onClose}>
          <NavLink to='/submit-claim'>
            <Button variant='outlined' size='large' startIcon={<AddIcon />} disableElevation sx={{color: 'white', borderColor: 'white'}} className='px-8'>
              New Claim
            </Button>
          </NavLink>
        </div>
      ) : null}

      <div className='flex items-start px-8 pt-5' onClick={onClose}>
        <a href='https://www.afspa.org/secure-form-member-portal-support/' target='_blank' rel='noopener noreferrer'>
          <Button variant='outlined' size='large' startIcon={<FeedbackIcon />} disableElevation sx={{color: 'white', borderColor: 'white'}} className='px-8'>
            Feedback
          </Button>
        </a>
      </div>

      <div className='flex flex-col items-start justify-start w-full pt-14 pb-20 px-8 gap-4 menu' data-kt-menu='true' data-kt-menu-expand='false'>
        <SidebarMenuMain isMobile mobileOnClose={onClose} />
      </div>

      <div className='flex items-center justify-start px-8 h-10 w-full' onClick={onLogout}>
        <span className='menu-icon pl-4 pr-2'>
          {' '}
          <Icon name={'log-out'} props={{color: 'white'}} />
        </span>
        <span className='menu-title ml-1 font-medium text-white-primary'>{'Logout'}</span>
      </div>
    </div>
  )
}
