interface PolicyPlanBalanceProps {
  balance: number
  lastUpdated: string
}
/**
 * - Current Balance:
 * - $17.95
 * - Last Updated: -
 */
export default function PolicyPlanBalance({balance, lastUpdated}: PolicyPlanBalanceProps) {
  return (
    <div className='plan-balance-box'>
      <p className='gray-text text-base'>{'Current Balance:'}</p>
      <p className='blue-title'>{`${balance}`}</p>
      <p className='gray-text text-base'>{`Last Updated: ${lastUpdated}`}</p>
    </div>
  )
}
