import React, {useState, useEffect, useCallback, useRef} from 'react'
import {Formik} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import {Form, InputGroup} from 'react-bootstrap'
import * as Member from '../../store/ducks/member.duck'
import {Grid, Box, Button, Alert} from '@mui/material'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'

export default function EnterCreditCard({closeModal, currentAmount, prefix}) {
  const dispatch = useDispatch()

  const elavonTokenCheck = useSelector((state) => state.member.ElavonTokenCheck)
  const paymentDetails = useSelector((state) => state.member.PaymentDetails)

  const [amountEntered, setAmountEntered] = useState(false)
  const [gettingToken, setGettingToken] = useState(false)
  const [tokenError, setTokenError] = useState(false)
  // const [newCurrentAmount,setNewCurrentAmount] = useState(0)
  const creditCardFormRef = useRef()

  const checkModal = useCallback(
    (elavonTokenCheck, paymentDetails) => {
      // console.log(newFormStatus);
      if (elavonTokenCheck === 1) {
        //
        // Check to make sure token is populated
        // console.log(paymentDetails);
        // Ok processed finished successfully - lets go
        // Check for Elavon token
        if (!paymentDetails.secureToken) {
          setTokenError(true)
        } else {
          setAmountEntered(true)
          setTokenError(false)
          setGettingToken(false)
        }
        // Reset form status
        dispatch(Member.actions.elavontokencheckclear())
      }
      if (elavonTokenCheck === 2) {
        // it has errors - ditch the processing window and stay on page
        // console.log('Process finished badly');
        // Close Submitting Modal
        setAmountEntered(false)
        setTokenError(true)
        // Reset form status
        dispatch(Member.actions.elavontokencheckclear())
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (elavonTokenCheck) {
      checkModal(elavonTokenCheck, paymentDetails)
    }
  }, [elavonTokenCheck, paymentDetails, checkModal])

  const requestToken = () => {
    // console.log(creditCardFormRef.current.values.amountToPay);
    // Once amount is known get token
    setGettingToken(true)
    setTokenError(false)
    // setNewCurrentAmount(creditCardFormRef.current.values.amountToPay)
    dispatch(Member.actions.fetchElavonToken({amount: creditCardFormRef.current.values.amountToPay, prefix: prefix}))
  }

  // console.log(currentAmount)
  return (
    <>
      <Formik
        initialValues={{
          amountToPay: currentAmount
        }}
        innerRef={creditCardFormRef}
      >
        {({values, handleSubmit, touched, errors, handleChange}) => (
          <form onSubmit={handleSubmit}>
            <Row className='mb-5'>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Card>
                    <Card.Body>
                      <Form.Label className='font-weight-bolder'>Amount to Pay</Form.Label>
                      <InputGroup className='mb-3'>
                        <InputGroup.Text id='basic-addon1'>$</InputGroup.Text>
                        <Form.Control
                          type='number'
                          style={{textAlign: 'right'}}
                          name='amountToPay'
                          value={values.amountToPay}
                          onChange={handleChange}
                          autoComplete='new-password'
                          isInvalid={touched.surname && !!errors.surname}
                          isValid={touched.surname && !errors.surname}
                          disabled={amountEntered}
                        />
                      </InputGroup>
                    </Card.Body>
                  </Card>
                </Grid>
              </Grid>
            </Row>
            {!amountEntered && !gettingToken ? (
              <Card>
                <Card.Body>
                  <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                    <Grid container xs={12} gap={3} justifyContent={'end'}>
                      <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => closeModal()}>
                        Cancel
                      </Button>
                      <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => requestToken()}>
                        Next
                      </Button>
                    </Grid>
                  </Box>

                  <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                    <Grid container xs={12} justifyContent={'space-between'}>
                      <Grid item className={'pr-2'} xs={6}>
                        <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => closeModal()}>
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item className={'pl-2'} xs={6}>
                        <Button variant='contained' size={'large'} className={'w-100'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => requestToken()}>
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Card.Body>
              </Card>
            ) : null}
            {!amountEntered && gettingToken && !tokenError ? (
              <Row className='mb-5'>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card>
                      <Card.Body>
                        <Alert severity='info'>Connecting to Secure Server........</Alert>
                      </Card.Body>
                    </Card>
                  </Grid>
                </Grid>
              </Row>
            ) : null}
            {!amountEntered && gettingToken && tokenError ? (
              <Row className='mb-5'>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card>
                      <Card.Body>
                        <Alert severity='warning'>Error - Unable to Connect. Please Try Again Later</Alert>
                      </Card.Body>

                      <Card.Body>
                        <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                          <Grid container xs={12} gap={3} justifyContent={'end'}>
                            <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => closeModal()}>
                              Cancel
                            </Button>
                          </Grid>
                        </Box>
                        <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                          <Grid container xs={12} justifyContent={'space-between'}>
                            <Grid item className={'pr-2'} xs={6}>
                              <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => closeModal()}>
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Grid>
                </Grid>
              </Row>
            ) : null}

            {amountEntered && !gettingToken && !tokenError ? (
              <Row className='mb-5'>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12}>
                    <Card>
                      <Card.Body>
                        <Alert severity='info'>Proceed To Secure Payment Server</Alert>
                      </Card.Body>
                      <Card.Body>
                        <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                          <Grid container xs={12} gap={3} justifyContent={'end'}>
                            <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => closeModal()}>
                              Cancel
                            </Button>
                            <a href={`${process.env.REACT_APP_ELAVONHPP}?ssl_txn_auth_token=${encodeURIComponent(paymentDetails.secureToken)}`}>
                              <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                                Proceed
                              </Button>
                            </a>
                          </Grid>
                        </Box>

                        <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                          <Grid container xs={12} justifyContent={'space-between'}>
                            <Grid item className={'pr-2'} xs={6}>
                              <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation onClick={() => closeModal()}>
                                Cancel
                              </Button>
                            </Grid>
                            <Grid item className={'pl-2'} xs={6}>
                              <a href={`${process.env.REACT_APP_ELAVONHPP}?ssl_txn_auth_token=${encodeURIComponent(paymentDetails.secureToken)}`}>
                                <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                                  Proceed
                                </Button>
                              </a>
                            </Grid>
                          </Grid>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Grid>
                </Grid>
              </Row>
            ) : null}
          </form>
        )}
      </Formik>
    </>
  )
}
