import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {updatePassword} from '../core/_requests'
import md5 from '../../../components/Misc/Md5'
import {useIntl} from 'react-intl'

const newPasswordSchema = Yup.object().shape({
  userName: Yup.string().required('Username is required').min(5, 'Username must be at least 5 characters').max(100, 'Username must be less than 100 characters'),
  newPass: Yup.string()
    .required('New password is required')
    .max(65, 'Too Long')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Must Contain 8 Characters, One Uppercase, One Lowercase and One Number'),
  confirmPass: Yup.string()
    .oneOf([Yup.ref('newPass')], 'Passwords do not match')
    .required('Password is required')
})

export function LostPasswordUpdate() {
  // Get token
  const {lostpasswordguid} = useParams() as {lostpasswordguid: string}
  const {registerguid} = useParams() as {registerguid: string}
  const {resetguid} = useParams() as {resetguid: string}

  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [expired, setExpired] = useState(false)
  const [linkNotActive, setLinkNotActive] = useState(false)
  const [userNameNotUnique, setUserNameNotUnique] = useState(false)
  const [errorInformation, setErrorInformation] = useState<string | false>()

  const initialValues = {
    userName: '',
    newPass: '',
    confirmPass: '',
    guidType: registerguid ? 'registerguid' : lostpasswordguid ? 'lostpasswordguid' : 'resetguid'
  }

  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        updatePassword(values.userName, md5(values.newPass), registerguid, lostpasswordguid, resetguid)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch((err: any) => {
            setSubmitting(false)
            setLoading(false)
            setHasErrors(true)
            // setStatus('The login detail is incorrect')
            if (err) {
              if (err.response) {
                if (err.response.data.status && err.response.data.status === 'Expired') {
                  setExpired(true)
                  setUserNameNotUnique(false)
                  setLinkNotActive(false)
                } else {
                  if (err.response.data.status && err.response.data.status === 'Reset Not Active') {
                    setExpired(false)
                    setUserNameNotUnique(false)
                    setLinkNotActive(true)
                  } else {
                    if (err.response.data.status && err.response.data.status === 'Username Not Unique') {
                      setUserNameNotUnique(true)
                      setExpired(false)
                      setLinkNotActive(false)
                    } else {
                      setUserNameNotUnique(false)
                      setExpired(false)
                      setLinkNotActive(false)
                      setErrorInformation(err.response.data.status)
                    }
                  }
                }
              } else {
                setExpired(false)
                setUserNameNotUnique(false)
                setLinkNotActive(false)
                setErrorInformation(
                  intl.formatMessage({
                    id: 'AUTH.VALIDATION.SERVER_OUTAGE'
                  })
                )
              }
            } else {
              setExpired(false)
              setUserNameNotUnique(false)
              setLinkNotActive(false)
              setErrorInformation(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.SERVER_OUTAGE'
                })
              )
            }
            // setStatus('The login details are incorrect')
          })
      }, 1000)
    }
  })

  return (
    <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework' noValidate id='kt_login_password_reset_form' onSubmit={formik.handleSubmit}>
      {hasErrors == false ? (
        <div className='mb-10 bg-light-primary p-8 rounded'>
          <Link to='/home'>
            {registerguid ? <div className='text-primary'>Registration Complete - Please login here </div> : <div className='text-primary'>Username and Password Updated - Please login here </div>}
          </Link>
        </div>
      ) : (
        <>
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Enter your new Username and Password</h1>
            {/* end::Title */}
          </div>

          {/* begin::Title */}

          {hasErrors === true && expired === true && userNameNotUnique === false && linkNotActive !== true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {registerguid
                  ? 'For your safety, registration reset URLs expire 3 days after being sent out. The registration reset URL provided was older than 3 days. Please contact AFSPA.'
                  : 'For your safety, password reset URLs expire 3 days after being sent out. The password reset URL provided was older than 3 days. Please contact AFSPA.'}
              </div>
            </div>
          )}

          {hasErrors === true && expired === false && linkNotActive === true && userNameNotUnique === false && (
            <div className='mb-lg-15 alert alert-danger'>
              {registerguid ? (
                <div className='alert-text font-weight-bold'>There is no active registration link. Please contact AFSPA if you need further assistance.</div>
              ) : (
                <div className='alert-text font-weight-bold'>There is no active reset link. Please contact AFSPA if you need further assistance.</div>
              )}
            </div>
          )}

          {hasErrors === true && expired === false && linkNotActive === false && userNameNotUnique === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>The selected username is not unique. Please choose another.</div>
            </div>
          )}

          {hasErrors === true && expired !== true && linkNotActive !== true && userNameNotUnique === false && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{errorInformation}</div>
            </div>
          )}

          {/* end::Title */}

          {/* begin::Form group */}

          <div className='fv-row mb-8'>
            <input
              type='text'
              placeholder='New Username'
              autoComplete='new-password'
              {...formik.getFieldProps('userName')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.userName && formik.errors.userName},
                {
                  'is-valid': formik.touched.userName && !formik.errors.userName
                }
              )}
            />
            {formik.touched.userName && formik.errors.userName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.userName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-8'>
            <input
              type='password'
              placeholder='New Password'
              autoComplete='off'
              {...formik.getFieldProps('newPass')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.newPass && formik.errors.newPass},
                {
                  'is-valid': formik.touched.newPass && !formik.errors.newPass
                }
              )}
            />
            {formik.touched.newPass && formik.errors.newPass && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPass}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-8'>
            <input
              type='password'
              placeholder='Confirm Password'
              autoComplete='off'
              {...formik.getFieldProps('confirmPass')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.confirmPass && formik.errors.confirmPass},
                {
                  'is-valid': formik.touched.confirmPass && !formik.errors.confirmPass
                }
              )}
            />
            {formik.touched.confirmPass && formik.errors.confirmPass && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPass}</span>
                </div>
              </div>
            )}
          </div>

          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button type='button' id='kt_login_password_reset_form_cancel_button' className='btn btn-light' disabled={formik.isSubmitting || !formik.isValid}>
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </>
      )}
    </form>
  )
}
