import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {StyledTableCell, StyledTableRow} from '.'
import MobilePaymentsTable from './MobileTable'
import TableCellText from './TableCellText'
import Spinner from 'react-bootstrap/Spinner'

export interface Payment {
  id: number
  entry_date: string
  reason: string
  amount: string
  policy: string
  realdate: string
}

interface PaymentsTableProps {
  displayForMobile?: boolean
}

export default function PaymentsTable({displayForMobile = false}: PaymentsTableProps) {
  //
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Member.actions.fetchPayments())
  }, [dispatch])
  const payments = useSelector((state: any) => state.member.Payments)
  const paymentsCheck = useSelector((state: any) => state.member.PaymentsCheck)
  //

  // Check if data still be retrieved
  if (Object.keys(payments).length === 0 && !paymentsCheck) {
    return (
      <span className='indicator-progress' style={{display: 'block'}}>
        <p className='claim-text mb-5'>
          Please wait while we retrieve your payments history... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
        </p>
      </span>
    )
  }

  // No data is found
  if (Object.keys(payments).length === 0 && paymentsCheck) {
    return (
      <p className='claim-text mb-10'>
        <strong>No Payments found</strong>
      </p>
    )
  }

  if (displayForMobile) {
    return <MobilePaymentsTable incentiveType='Payment' onClickDownload={undefined} data={payments} />
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 700}} aria-label='customized table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Policy</StyledTableCell>
            <StyledTableCell>Amount</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments &&
            payments.map((row: Payment, i: number) => (
              <StyledTableRow key={i}>
                <StyledTableCell component='th' scope='row'>
                  {row.entry_date}
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                  <TableCellText text={row.policy} />
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                  <TableCellText text={row.amount} />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
