import IconButton from '@mui/material/IconButton'
import {useState} from 'react'
import {Link} from 'react-router-dom'
import {Icon} from '../../../../app/components/Icons/Icon'
import MobileMenu from '../../../../app/pages/navigation/MobileMenu'
import COLORS from '../../../assets/mui/colors'
import {toAbsoluteUrl} from '../../../helpers'

export default function MobileHeader() {
  const [menu, setMenu] = useState(false)
  function handleShowMenu() {
    setMenu(true)
  }
  function handleHideMenu() {
    setMenu(false)
  }
  function handleLogout() {
    handleHideMenu()
  }
  return (
    <div
      className=' 
        flex
        px-5
        py-5
        items-stretch
        mx-0
        w-screen
        justify-between
      bg-white-primary
        z-50
        border-b-2
        lg:hidden
    '
    >
      <div className='header-logo'>
        <Link to='/'>
          <img className='max-h-[40px]' alt='AFSPA Logo' src={toAbsoluteUrl('/media/logos/logo-letter-1.png')} />
          <img className='max-h-[40px]' alt='AFSPA Accrediation' src={toAbsoluteUrl('/media/logos/AAAHC_Health_Plan_Accredited_by.fw.png')} style={{paddingLeft: 10}} />
        </Link>
      </div>
      <IconButton aria-label='show 9 new mails' color='inherit' onClick={handleShowMenu}>
        <Icon name='burger-menu' props={{color: COLORS.primary}} />
      </IconButton>
      <MobileMenu onClose={handleHideMenu} onLogout={handleLogout} isShown={menu} />
    </div>
  )
}
