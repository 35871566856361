import {styled} from '@mui/material/styles'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {Button} from '@mui/material'

const cellHeadStyle = {
  backgroundColor: '#F7FCFF',
  color: '#121619',
  fontFamily: 'Roboto',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: 1.1
}
export const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: cellHeadStyle,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: 'white'
  }
}))

export const StyledAlignTopTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: cellHeadStyle,
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: 'white',
    verticalAlign: 'top'
  }
}))

export const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

export function SeeDetailsButton({onClick}: {onClick: () => any}) {
  return (
    <Button variant='contained' size='small' disableElevation className='rounded-full' onClick={onClick}>
      Download Claim PDF
    </Button>
  )
}
