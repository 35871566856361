import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Button} from '@mui/material'
import {Icon} from '../Icons/Icon'
import {useNavigate} from 'react-router-dom'
import * as Member from '../../store/ducks/member.duck'
import {AnyARecord} from 'dns'

export default function RequestsCard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [requestAetnaSSO, setrequestAetnaSSO] = useState(false)
  const aetnasso = useSelector((state: any) => state.member.AETNA)
  const [requestESISSO, setrequestESISSO] = useState(false)
  const esisso = useSelector((state: any) => state.member.ESI)
  //
  useEffect(() => {
    if (!aetnasso && !requestAetnaSSO) {
      dispatch(Member.actions.fetchAETNA())
      setrequestAetnaSSO(true)
    }
  }, [dispatch, aetnasso, requestAetnaSSO, setrequestAetnaSSO])

  return (
    <div className='request-card-box'>
      <Button disableElevation variant='contained' className='card-large-button' color='primary' onClick={() => navigate('/submit-claim')}>
        <Icon name='add-doc' />
        <span>Submit a Claim</span>
      </Button>
      <Button disableElevation variant='contained' className='card-large-button' color='primary' onClick={() => navigate('/submit-incentive')}>
        <Icon name='add-doc' />
        <span>Submit an Incentive</span>
      </Button>
      <Button disableElevation variant='contained' className='card-large-button' color='primary' onClick={() => window.open(aetnasso && aetnasso, '_blank')}>
        <Icon name='add-doc' />
        <span>Connect to AETNA</span>
      </Button>
      <Button disableElevation variant='contained' className='card-large-button' color='primary' onClick={() => window.open(esisso && esisso, '_blank')}>
        <Icon name='add-doc' />
        <span>Connect to ESI</span>
      </Button>
    </div>
  )
}

interface LargeCardButtonProps {
  text: string
  onClick: (event: string) => any
}
