import React, {useEffect, useState, useCallback} from 'react'
import {Link} from 'react-router-dom'
import {Grid, Divider, Button, Dialog, DialogContent, DialogContentText, DialogTitle, TextField, FormControl, Select, MenuItem, InputLabel, Box} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Typography from '@mui/material/Typography'
import Alert from '@mui/material/Alert'
import FormHelperText from '@mui/material/FormHelperText'
import Spinner from 'react-bootstrap/Spinner'
import {Formik, useFormikContext} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import * as Member from '../../store/ducks/member.duck'
import FileUploadIcon from '@mui/icons-material/FileUploadOutlined'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {useDropzone} from 'react-dropzone'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import {FormLabel} from 'react-bootstrap'

export default function ClaimForm() {
  // Define a few variables
  const dispatch = useDispatch()

  // Need to load dependents as they are part of a drop down in the claim process
  useEffect(() => {
    dispatch(Member.actions.fetchDependents())
  }, [dispatch])
  // Need to load HBO as they are part of a drop down in the claim process
  useEffect(() => {
    dispatch(Member.actions.fetchHBO())
  }, [dispatch])
  // Need to load Other Insurance details (if they exist)
  useEffect(() => {
    dispatch(Member.actions.fetchOtherInsurance())
  }, [dispatch])
  // Need to load Countries
  useEffect(() => {
    dispatch(Member.actions.fetchCountries())
  }, [dispatch])

  // Map Dependents and Auth to Redux State
  const Dependents = useSelector((state) => state.member.Dependents)
  const otherInsurance = useSelector((state) => state.member.OtherInsurance)
  const returnStatus = useSelector((state) => state.member.ReturnStatus)
  const countries = useSelector((state) => state.member.Countries)
  const authState = useSelector((state) => state.auth)
  const hbo = useSelector((state) => state.member.HBO)

  // Set form type default
  const [formType, setFormType] = useState('ClaimsNoQuestions')
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function allowMoreEditing() {
    // Close Submitting Modal
    setOpen(false)
    // Reactivate submit for another attempt
    setIsLoading(false)
  }

  // console.log(isLoading);

  function Savesubmit(data) {
    // Open Submitting modal
    setOpen(true)
    setIsLoading(true)
    // Add formtype to form post
    data['FormType'] = formType
    // Create structure to load file
    const formData = new FormData()
    // Add fields
    formData.append('Comments', data['CommentEntry'])
    formData.append('Declaration', data['Declaration'])
    formData.append('FormType', data['FormType'])
    formData.append('HBO', data['HBO'])
    formData.append('PersonClaim', data['PersonClaim'])
    formData.append('ReasonEntry', data['ReasonEntry'])
    formData.append('TrackingInformation', data['TrackingInformation'])
    formData.append('ServiceYear', data['ServiceYear'])
    formData.append('ServiceCountry', data['ServiceCountry'])
    formData.append('ServiceCountryName', countries.find((v) => v.id === data['ServiceCountry']).country)
    formData.append('OtherInsurance', data['otherInsurance'])
    console.log(data['otherInsurance'])
    // Check if other insurance relevant
    if (data['otherInsurance'] === '1') {
      formData.append('OtherInsurancePersonIssued', data['personIssued'])
      formData.append('OtherInsuranceDependentsNames', data['dependentsNames'])
      formData.append('OtherInsuranceCompanyName', data['companyName'])
      formData.append('OtherInsurancePolicyEffectiveDate', data['policyEffectiveDate'])
      formData.append('OtherInsuranceCompanyClaimsAddress', data['companyClaimsAddress'])
      formData.append('OtherInsuranceActiveEmploymentFlag', data['activeEmploymentFlag'])
      formData.append('OtherInsuranceEmploymentEffectiveDate', data['employmentEffectiveDate'])
      formData.append('OtherInsurancePolicyNumber', data['policyNumber'])
      formData.append('OtherInsurancePolicyCoverageType', data['policyCoverageType'])
      formData.append('OtherInsurancePolicyContractType', data['policyContractType'])
    }
    // Add files
    for (const fileUploadItem of data['PersonFiles']) {
      formData.append('files', fileUploadItem)
    }

    // Some debugging
    if (process.env.REACT_APP_DEBUG) {
      console.log(data)
    }
    // Save request/form
    dispatch(Member.actions.putClaimData({formData}))
  }

  function FormFiles() {
    const {setFieldValue, errors, values} = useFormikContext()
    const [myFiles, setMyFiles] = useState([])

    // If form is submitted and fails - re populate the files back into the dropzone
    if (values.PersonFiles && values.PersonFiles.length > 0 && myFiles && myFiles.length === 0) {
      setMyFiles(values.PersonFiles)
    }
    //
    // This question appears for all claim form types
    //
    const fieldName = 'PersonFiles'
    //

    const onDrop = useCallback(
      (acceptedFiles) => {
        setMyFiles([...myFiles, ...acceptedFiles])
        setFieldValue(fieldName, [...myFiles, ...acceptedFiles])
      },
      [myFiles, setFieldValue, fieldName]
    )

    const {getRootProps, getInputProps} = useDropzone({
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
        'application/pdf': []
      },
      multiple: true,
      maxFiles: 20,
      maxFileSize: 6291457,
      onDrop
    })

    const removeFile = (file) => () => {
      const newFiles = [...myFiles]
      newFiles.splice(newFiles.indexOf(file), 1)
      setMyFiles(newFiles)
      setFieldValue(fieldName, [...newFiles])
    }

    const files = myFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes <button onClick={removeFile(file)}>Remove File</button>
      </li>
    ))

    return (
      <Grid item xs={12} md={12}>
        <section className='container px-0'>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <FileUploadIcon color={'primary'} className={'mt-3 mb-3'} sx={{fontSize: 40}} />
            <p>Use camera to capture documents or upload from device</p>
            <p>(max size 6 MB)</p>
          </div>
          {files.length > 0 && (
            <aside>
              <h4>Files</h4>
              <ul>{files}</ul>
            </aside>
          )}
        </section>

        {/* <DropzoneArea
          acceptedFiles={['image/jpeg,image/png,image/jpg,.pdf']}
          showPreviews={true}
          showFileNamesInPreview={true}
          maxFileSize={6291457}
          showPreviewsInDropzone={false}
          filesLimit={20}
          dropzoneText={'Use Camera to capture documents or upload from device (max size 6 MB) '}
          onChange={(files) => {
            props.setFieldValue(fieldName, files)
          }}
          clearOnUnmount={true}
        /> */}
        {errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
      </Grid>
    )
  }

  function FormPersonSelector() {
    const {handleChange, values} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'PersonClaim'
    //
    //
    // Determine list of people (FSBP member plus dependents)
    var people = []
    // Add member to array
    var member = {
      Name: authState.firstName + ' ' + authState.lastName,
      key: 'P' + authState.member_key
    }
    people.push(member)
    // Check if there are dependents
    if (Dependents) {
      // Add dependents to array together with member
      Dependents.forEach((dependents) => {
        var person = {
          Name: dependents.Name,
          key: 'D' + dependents.key
        }
        people.push(person)
      })
    }
    //
    return (
      <Grid item xs={12} md={6}>
        <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0', minWidth: '100%'}}>
          <FormLabel className='gray-1-text' id='claimforperson-label'>
            This claim is for...
          </FormLabel>
          <Select aria-label='Claim for Person' id='claimforperson' labelId='claimforperson-label' name={fieldName} value={values[fieldName] || ''} onChange={handleChange(fieldName)}>
            {people
              ? people.map((People) => {
                  return (
                    <MenuItem key={People.key} value={People.key}>
                      {People.Name}
                    </MenuItem>
                  )
                })
              : 'None'}
          </Select>
        </FormControl>
      </Grid>
    )
  }

  function FormServiceCountrySelector() {
    const {setFieldValue, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'ServiceCountry'
    //
    return (
      <Grid item xs={12} md={6}>
        <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0', minWidth: '100%'}}>
          <FormLabel className='gray-1-text' id='servicecountry'>
            Country where service was performed
          </FormLabel>
          <Autocomplete
            id='servicecountry'
            disableClearable
            options={countries}
            value={countries.find((v) => v.id === values[fieldName]) || null}
            onChange={(e, value) => {
              setFieldValue(fieldName, value.id)
            }}
            getOptionLabel={(option) => option.country}
            renderInput={(params) => (
              <TextField
                {...params}
                type='text'
                variant='outlined'
                margin='dense'
                name={fieldName}
                style={{minWidth: '100%'}}
                className={'mt-0'}
                helperText={touched[fieldName] ? errors[fieldName] : ''}
                error={touched[fieldName] && Boolean(errors[fieldName])}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password'
                }}
              />
            )}
          />
        </FormControl>
      </Grid>
    )
  }

  function FormReasonEntry() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'ReasonEntry'
    let labelDescriptor = ''
    let extraInformation = ''
    //
    // Set label based on formType
    if (formType === 'ClaimsCOVID') {
      extraInformation = 'Please submit a detailed receipt with the date of purchase, the store or supplier from which you made the purchase, the test type, and the number of tests you purchased.'
      labelDescriptor = 'Enter Purchase Information and upload the receipt'
    } else {
      labelDescriptor = 'Diagnosis or Reason for Visit'
    }
    //
    return (
      <Grid item xs={12} md={12}>
        <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0', minWidth: '100%'}}>
          <FormLabel className='gray-1-text' htmlFor={fieldName}>
            {labelDescriptor}
          </FormLabel>
          <label style={{fontWeight: 'bold'}}>{extraInformation}</label>
          <TextField
            style={{minWidth: '100%'}}
            className={'mt-0'}
            id={fieldName}
            type='text'
            variant='outlined'
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
            multiline
            minRows={4}
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
          />
        </FormControl>
      </Grid>
    )
  }

  function FormTrackingInformation() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'TrackingInformation'
    //
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <label>(Optional) Please enter Service Dates and/or any other identifiable information for your own person tracking purposes.</label>
        <TextField
          style={{margin: '14px 0 6px 0', minWidth: '100%'}}
          type='text'
          variant='outlined'
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          size='medium'
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
          multiline
          minRows={2}
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
        />
      </Grid>
    )
  }

  function FormHBOSelector() {
    const {handleChange, values} = useFormikContext()
    // Check if this question is on form type
    if (formType !== 'ClaimsHBO') {
      return null
    }
    //
    const fieldName = 'HBO'
    //
    return (
      <Grid item xs={9} md={9}>
        <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0', minWidth: '100%'}}>
          <InputLabel shrink id='forhbo-label'>
            Please select the Health Benefit Officer
          </InputLabel>
          <Select
            aria-label='Health Benefits Officer'
            id='forhbo'
            labelId='forhbo-label'
            label='Please select the Health Benefit Officer'
            name={fieldName}
            value={values[fieldName] || ''}
            onChange={handleChange(fieldName)}
          >
            {hbo
              ? hbo.map((HBO) => {
                  return (
                    <MenuItem key={HBO.id} value={HBO.id}>
                      {HBO.first_name} {HBO.last_name}.
                    </MenuItem>
                  )
                })
              : 'None'}
          </Select>
        </FormControl>
      </Grid>
    )
  }

  function FormCommentEntry() {
    const {handleChange, values} = useFormikContext()
    // Check if this question is on form type
    if (formType !== 'ClaimsWithQuestions' && formType !== 'ClaimsCOVID') {
      return null
    }
    //
    const fieldName = 'CommentEntry'
    let labelDescriptor = ''
    //
    if (formType === 'ClaimsCOVID') {
      labelDescriptor = 'Enter who the test(s) were purchased for'
    } else {
      labelDescriptor = 'Questions'
    }
    //
    return (
      <Grid item xs={12} md={12}>
        <TextField
          style={{margin: '14px 0 6px 0', minWidth: '100%'}}
          type='text'
          variant='outlined'
          label={labelDescriptor}
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          size='small'
          multiline
          rows={4}
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
        />
      </Grid>
    )
  }

  function FormCommentInformation() {
    //
    // This is not a question - just some additional information for COVID Test Kit claims

    // Check if this question is on form type
    if (formType !== 'ClaimsCOVID') {
      return null
    }
    //
    return (
      <>
        <Grid item xs={12} md={12} style={{paddingTop: 10, fontWeight: 'bold'}}>
          <label>
            Please detail the number of tests purchased for each covered family member in your submission. For example, two tests for Martin, two tests for Brittany, and six tests for Emilio.
          </label>
        </Grid>
      </>
    )
  }

  function FormOtherInsuranceSelector() {
    const {handleChange, values} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'otherInsurance'
    //
    return (
      <>
        <Grid className={'mt-3'} item xs={12} md={12}>
          <FormLabel className='gray-1-text' htmlFor={'declaration'}>
            Are you or any member of your family covered under any other health plan other than FOREIGN SERVICE BENEFIT PLAN?
          </FormLabel>
        </Grid>
        <Grid item xs={3} md={3}>
          <Select aria-label='Claim Declaration' id='declaration' className={'w-100'} name={fieldName} value={values[fieldName]} onChange={handleChange(fieldName)}>
            <MenuItem key='NO' value='0'>
              No
            </MenuItem>
            <MenuItem key='YES' value='1'>
              Yes
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function FormOtherInsuranceHeading() {
    const {values} = useFormikContext()
    //
    // This is not a question - only a header
    //
    return (
      <>
        <Grid item xs={12} md={12} style={{paddingTop: 20, fontWeight: 'bold'}}>
          <Typography gutterBottom variant='h5' className='font-bold' component='h5'>
            Other Insurance Coverage Information
          </Typography>
          {values.otherInsurance === '1' && (
            <>
              <label style={{fontWeight: 'bold', color: 'red'}}>(Please review your existing information. Select No to remove your existing information)</label>
            </>
          )}
        </Grid>
        <Grid item xs={12} md={12} style={{paddingTop: 5}}>
          <label>
            Other insurance coverage information should be provided at least once per year, or if any changes have been made to your other insurance coverage. (See Section on coordination of benefits
            in your Brochure)
          </label>
        </Grid>
      </>
    )
  }

  function FormOtherInsurancePersonIssued() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'personIssued'
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={fieldName}>
          Person in whose name the other plan is issued
        </FormLabel>
        <Grid item xs={6} md={6}>
          <TextField
            id={fieldName}
            style={{minWidth: '100%'}}
            className={'mt-0'}
            type='text'
            variant='outlined'
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </Grid>
    )
  }

  function FormOtherInsuranceDependentsNames() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'dependentsNames'
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={fieldName}>
          Name of all dependents covered under the other plan
        </FormLabel>
        <Grid item xs={12} md={12}>
          <TextField
            id={fieldName}
            className={'mt-0 mb-0'}
            style={{minWidth: '100%'}}
            type='text'
            variant='outlined'
            label=''
            margin='normal'
            name={fieldName}
            value={values[fieldName]}
            autoComplete='new-password'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
          <FormHelperText>Separate names using commas</FormHelperText>
        </Grid>
      </Grid>
    )
  }

  function FormOtherInsuranceCompanyName() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'companyName'
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={fieldName}>
          Name of Insurance Company or Plan
        </FormLabel>
        <TextField
          style={{minWidth: '100%'}}
          className={'mt-0 mb-0'}
          type='text'
          variant='outlined'
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
        />
      </Grid>
    )
  }

  function FormOtherInsuranceCompanyClaimsAddress() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'companyClaimsAddress'
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={fieldName}>
          Address of Claims Office
        </FormLabel>
        <TextField
          style={{minWidth: '100%'}}
          className={'mt-0 mb-0'}
          type='text'
          variant='outlined'
          margin='normal'
          name={fieldName}
          value={values[fieldName]}
          autoComplete='new-password'
          onChange={handleChange(fieldName)}
          InputProps={{classes: {input: 'input-profile'}}}
          helperText={touched[fieldName] ? errors[fieldName] : ''}
          error={touched[fieldName] && Boolean(errors[fieldName])}
        />
      </Grid>
    )
  }

  function FormOtherInsurancePolicyEffectiveDate() {
    const {setFieldValue, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'policyEffectiveDate'
    //
    return (
      <>
        <Grid item className={'mt-3'} xs={12} md={12}>
          <FormLabel className='gray-1-text' htmlFor={fieldName}>
            Policy Effective Date
          </FormLabel>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id={fieldName}
                name={fieldName}
                className={'w-100'}
                onChange={(value) => setFieldValue(fieldName, value, true)}
                value={values[fieldName] ? dayjs(values[fieldName]) : dayjs()}
                slotProps={{
                  textField: {
                    fullWidth: false,
                    variant: 'outlined',
                    error: !!errors[fieldName],
                    helperText: errors[fieldName]?.message
                  }
                }}
                error={errors[fieldName]}
                touched={touched[fieldName]}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </>
    )
  }

  function FormOtherInsuranceActiveEmploymentFlag() {
    const {handleChange, values} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'activeEmploymentFlag'
    //
    return (
      <>
        <Grid item className={'mt-3'} xs={12} md={12}>
          <FormLabel className='gray-1-text' htmlFor={fieldName}>
            Is this insurance through active employment?
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={3}>
          <Select
            aria-label='Other Insurance Policy Active Employment Flag'
            id='OtherInsuranceActiveEmploymentFlag'
            name={fieldName}
            value={values[fieldName]}
            className={'w-100'}
            onChange={handleChange(fieldName)}
          >
            <MenuItem key='No' value='0'>
              No
            </MenuItem>
            <MenuItem key='Yes' value='1'>
              Yes
            </MenuItem>
            <MenuItem key='NotSet' value='2'>
              Not Set
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function FormOtherInsuranceEmploymentEffectiveDate() {
    const {setFieldValue, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'employmentEffectiveDate'
    //
    return (
      <>
        <Grid item className={'mt-3'} xs={12} md={12}>
          <FormLabel className='gray-1-text' htmlFor={fieldName}>
            Employment Effective Date
          </FormLabel>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                id={fieldName}
                className={'w-100'}
                name={fieldName}
                onChange={(value) => setFieldValue(fieldName, value, true)}
                value={values[fieldName] ? dayjs(values[fieldName]) : dayjs()}
                slotProps={{
                  textField: {
                    fullWidth: false,
                    variant: 'outlined',
                    error: !!errors[fieldName],
                    helperText: errors[fieldName]?.message
                  }
                }}
                error={errors[fieldName]}
                touched={touched[fieldName]}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </>
    )
  }

  function FormOtherInsurancePolicyNumber() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'policyNumber'
    //
    return (
      <Grid item className={'mt-3'} xs={12} md={12}>
        <FormLabel className='gray-1-text' htmlFor={fieldName}>
          Policy or Contract Number
        </FormLabel>
        <Grid item xs={12} md={6}>
          <TextField
            style={{minWidth: '100%'}}
            type='text'
            variant='outlined'
            margin='normal'
            name={fieldName}
            className={'mt-0 mb-0'}
            value={values[fieldName]}
            autoComplete='new-password'
            onChange={handleChange(fieldName)}
            InputProps={{classes: {input: 'input-profile'}}}
            helperText={touched[fieldName] ? errors[fieldName] : ''}
            error={touched[fieldName] && Boolean(errors[fieldName])}
          />
        </Grid>
      </Grid>
    )
  }

  function FormOtherInsurancePolicyCoverageType() {
    const {handleChange, values} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'policyCoverageType'
    //
    return (
      <>
        <Grid item className={'mt-3'} xs={12} md={12}>
          <FormLabel className='gray-1-text' htmlFor={fieldName}>
            Plan type
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            aria-label='Other Insurance Policy Coverage Type'
            id='OtherInsurancePolicyCoverageType'
            name={fieldName}
            className={'mt-0 mb-0 w-100'}
            value={values[fieldName]}
            onChange={handleChange(fieldName)}
          >
            <MenuItem key='NotSet' value='0'>
              Not Set
            </MenuItem>
            <MenuItem key='SelfOnly' value='1'>
              Self Only Coverage
            </MenuItem>
            <MenuItem key='Family' value='2'>
              Family Coverage
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function FormOtherInsurancePolicyContractType() {
    const {handleChange, values} = useFormikContext()
    //
    // This question appears for all claim form types however only appears when the OtherInsurance flag is set to 1
    if (values.otherInsurance === '0') {
      return null
    }
    //
    const fieldName = 'policyContractType'
    //
    return (
      <>
        <Grid className={'mt-3'} item xs={12} md={12}>
          <FormLabel className='gray-1-text' htmlFor={fieldName}>
            Issued under a
          </FormLabel>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            aria-label='Other Insurance Policy Contract Type'
            id='OtherInsurancePolicyContractType'
            className={'w-100'}
            name={fieldName}
            value={values[fieldName]}
            onChange={handleChange(fieldName)}
          >
            <MenuItem key='NotSet' value='0'>
              Not Set
            </MenuItem>
            <MenuItem key='Individual' value='1'>
              Individual
            </MenuItem>
            <MenuItem key='Group' value='2'>
              Group
            </MenuItem>
          </Select>
        </Grid>
      </>
    )
  }

  function FormDeclarationSelector() {
    const {handleChange, values, touched, errors} = useFormikContext()
    //
    // This question appears for all claim form types
    //
    const fieldName = 'Declaration'
    //
    return (
      <>
        <Grid item xs={12} md={12}>
          <FormLabel className='gray-1-text' htmlFor={fieldName}>
            I certify the information in this claim submission is complete and accurate.
          </FormLabel>
        </Grid>
        <Grid item xs={6} md={3}>
          <Select aria-label='Claim Declaration' id='declaration' name={fieldName} value={values[fieldName]} onChange={handleChange(fieldName)} className='w-100'>
            <MenuItem key='NO' value='0'>
              No
            </MenuItem>
            <MenuItem key='YES' value='1'>
              Yes
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={12}>
          {touched[fieldName] && !!errors[fieldName] ? <p className='error-help'>{errors[fieldName]}</p> : ''}
        </Grid>
        <Grid item xs={12} md={12} className={'mt-3'}>
          <label className='font-bold'>
            WARNING: Any intentional false statement in this application or willful misrepresentation is a violation of the law punished by a fine not more than $10,000, or imprisonment of not more
            than five years, or both. (18 U.S.C. 1001).
          </label>
        </Grid>
      </>
    )
  }

  const handleClaimTypeChange = ({target: value}) => {
    // Set form type variable based on selection
    // console.log(value)
    switch (value.value) {
      case 'cnq':
        setFormType('ClaimsNoQuestions')
        break
      case 'cwq':
        setFormType('ClaimsWithQuestions')
        break
      case 'cdh':
        setFormType('ClaimsHBO')
        console.log(formType)
        break
      case 'cov':
        setFormType('ClaimsCOVID')
        break
      default:
    }
  }

  const validationSchema = Yup.object().shape({
    ReasonEntry: Yup.string().min(2, 'Reason is too Short').max(12000, 'Reason is too Long').required('Reason is required'),
    TrackingInformation: Yup.string().max(255, 'Tracking Information is too Long'),
    Declaration: Yup.string().oneOf(['1'], 'Only certified claims can be processed'),
    personIssued: Yup.string().when(['otherInsurance'], {
      is: (otherInsurance) => otherInsurance === '1',
      then: (schema) => schema.min(2, 'Too Short').max(20, 'Too Long')
    }),
    dependentsNames: Yup.string().when(['otherInsurance'], {
      is: (otherInsurance) => otherInsurance === '1',
      then: (schema) => schema.max(255, 'Too Long')
    }),
    companyName: Yup.string().when(['otherInsurance'], {
      is: (otherInsurance) => otherInsurance === '1',
      then: (schema) => schema.min(2, 'Too Short').max(100, 'Too Long')
    }),
    policyEffectiveDate: Yup.date().typeError('Policy Effective Date must be a date or left blank').nullable().notRequired(),
    companyClaimsAddress: Yup.string().when(['otherInsurance'], {
      is: (otherInsurance) => otherInsurance === '1',
      then: (schema) => schema.min(5, 'Too Short').max(255, 'Too Long')
    }),
    ServiceCountry: Yup.string().required('Service Country is required'),
    employmentEffectiveDate: Yup.date().typeError('Employment Effective Date must be a date or left blank').nullable().notRequired(),
    policyNumber: Yup.string().when(['otherInsurance'], {
      is: (otherInsurance) => otherInsurance === '1',
      then: (schema) => schema.min(2, 'Too Short').max(20, 'Too Long')
    }),
    PersonFiles: Yup.mixed().test('PersonFiles', 'Please upload at least one attachment', (file) => (file ? true : false))
  })

  function DisplayQuestions() {
    //
    return (
      <React.Fragment>
        <FormHBOSelector />
        <FormPersonSelector />
        <FormServiceCountrySelector />
        <FormReasonEntry />
        <FormCommentInformation />
        <FormCommentEntry />
        <FormFiles />

        <FormOtherInsuranceHeading />
        <FormOtherInsuranceSelector />
        <FormOtherInsurancePersonIssued />
        <FormOtherInsuranceDependentsNames />
        <FormOtherInsuranceCompanyName />
        <FormOtherInsuranceCompanyClaimsAddress />

        <FormOtherInsurancePolicyEffectiveDate />
        <FormOtherInsuranceActiveEmploymentFlag />
        <FormOtherInsuranceEmploymentEffectiveDate />
        <FormOtherInsurancePolicyNumber />
        <FormOtherInsurancePolicyCoverageType />
        <FormOtherInsurancePolicyContractType />

        <Divider style={{width: '100%', marginTop: 20, marginBottom: 20}} />
        <FormDeclarationSelector />
        <FormTrackingInformation />
      </React.Fragment>
    )
  }

  function ErrorsNotification({errors, touched}) {
    if ((errors && Object.keys(errors).length > 0 && touched && Object.keys(touched).length > 0) || (errors && errors.PersonFiles)) {
      return (
        <>
          <Card.Body>
            <div className='errors-list'>
              <h4 className='errors-list-heading'>Please fix the following fields before you submit:</h4>
              <ul>
                {Object.keys(errors).map((msg) => (
                  <React.Fragment key={msg}>{touched[msg] || msg === 'PersonFiles' ? <li>{errors[msg]}</li> : null}</React.Fragment>
                ))}
              </ul>
            </div>
          </Card.Body>
        </>
      )
    } else {
      return null
    }
  }

  // Set defaults
  var defaultV = {}
  defaultV['ReasonEntry'] = ''
  defaultV['Comments'] = ''
  defaultV['CommentEntry'] = ''
  defaultV['ServiceCountry'] = '1'
  defaultV['TrackingInformation'] = ''
  defaultV = {...defaultV, ...otherInsurance}

  // Get first HBO as the default (may need to revisit this)
  if (hbo.length > 0) {
    defaultV['HBO'] = hbo[0].id
  } else {
    defaultV['HBO'] = ''
  }
  // Get current year as default
  // var currentTime = new Date()
  // defaultV['ServiceYear'] = currentTime.getFullYear().toString();
  defaultV['Declaration'] = '0'
  defaultV['PersonClaim'] = 'P' + authState.member_key
  //
  // Display default items to console log
  if (process.env.REACT_APP_DEBUG) {
    console.log(defaultV)
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={defaultV}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true)
          Savesubmit(values)
        }}
      >
        {(props) => {
          const {errors, touched, handleSubmit} = props
          return (
            <form noValidate={true} autoComplete='off' onSubmit={handleSubmit}>
              <Row className='mb-5'>
                <Col xs={12} md={9}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <p className='claim-text'>Please select your claim type then select the name of the person who the claim is being filed for and year claim was serviced.</p>
                      <p className='claim-text mb-5'>Next, take a picture of, or browse for the documents you wish to upload and click Submit to send your claim.</p>
                      <Alert severity='info'>
                        All PDF file submissions are converted to grayscale. Please make sure the PDF document uploaded is in Portable Document Format (PDF). If the claim submission is failing, try
                        submitting one document at a time to identify the failing document.{' '}
                      </Alert>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Card>
                        <Card.Body>
                          <Grid item xs={12} md={6}>
                            <FormControl variant='outlined' margin='dense' style={{margin: '14px 0 6px 0', minWidth: '100%'}}>
                              <InputLabel shrink id='claimselect-label'>
                                Select Claim Type
                              </InputLabel>
                              <Select
                                aria-label='Select Claim Type'
                                id='claimselect'
                                name='claimselect'
                                onChange={handleClaimTypeChange}
                                defaultValue='cnq'
                                label='Select Claim Type'
                                labelId='claimselect-label'
                              >
                                <MenuItem key={'cnq'} value={'cnq'}>
                                  Claim Without Questions
                                </MenuItem>
                                <MenuItem key={'cwq'} value={'cwq'}>
                                  Claim With Questions
                                </MenuItem>
                                <MenuItem key={'cdh'} value={'cdh'}>
                                  Claim Directed to Specific HBO
                                </MenuItem>
                                <MenuItem key={'cov'} value={'cov'}>
                                  Claim for COVID-19 Over the Counter Testing Kits
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Card.Body>
                      </Card>
                    </Grid>
                  </Grid>
                </Col>
              </Row>
              <Row className='mb-5'>
                <Col xs={12} md={9}>
                  <Card>
                    <Card.Body>
                      <Grid container spacing={1}>
                        <DisplayQuestions />
                      </Grid>
                    </Card.Body>
                    <Divider />
                    <ErrorsNotification errors={errors} touched={touched} />

                    <Card.Body>
                      <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                        <Grid container xs={12} gap={3} justifyContent={'end'}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Cancel
                            </Button>
                          </Link>
                          <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                            Submit Claim
                          </Button>
                        </Grid>
                      </Box>

                      <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                        <Grid container xs={12} justifyContent={'space-between'}>
                          <Grid item className={'pr-2'} xs={6}>
                            <Link to='/home'>
                              <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                                Cancel
                              </Button>
                            </Link>
                          </Grid>
                          <Grid item className={'pl-2'} xs={6}>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' type='submit' disableElevation disabled={isLoading} style={{background: '#0074A6'}}>
                              Submit Claim
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </form>
          )
        }}
      </Formik>

      <Dialog open={open} aria-labelledby='Submitting Claim Please Wait' fullWidth={true} maxWidth='sm'>
        <DialogTitle>Submitting Claim</DialogTitle>
        <DialogContent style={{paddingTop: 30, paddingBottom: 30}}>
          {/* Check for result of submitting claim */}
          {returnStatus && returnStatus.length > 0 ? (
            <>
              {returnStatus === 'OK' ? (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-primary p-8 rounded self-stretch'>
                      <div className='text-primary'>
                        <strong>Claim Submitted Successfully</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} color='primary' disableElevation style={{background: '#0074A6'}}>
                            Return to Home
                          </Button>
                        </Link>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}}>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              ) : (
                <Card>
                  <Card.Body>
                    <div className='mb-2 bg-light-danger p-8 rounded self-stretch'>
                      <div className='text-danger'>
                        <strong>{returnStatus}</strong>
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Body>
                    <Box component='div' sx={{display: {xs: 'none', md: 'block'}}}>
                      <Grid container xs={12} gap={3} justifyContent={'end'}>
                        <Link to='/home'>
                          <Button variant='contained' size={'large'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                            Return to Home
                          </Button>
                        </Link>
                        <Button variant='contained' size={'large'} color='primary' type='submit' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                          Edit and Resubmit Claim
                        </Button>
                      </Grid>
                    </Box>

                    <Box component='div' sx={{display: {xs: 'block', md: 'none'}}}>
                      <Grid container xs={12} justifyContent={'space-between'}>
                        <Grid item className={'pr-2'} xs={6}>
                          <Link to='/home'>
                            <Button variant='contained' size={'large'} className={'w-100'} style={{background: '#E6F5FF', color: '#0074A6'}} disableElevation>
                              Return to Home
                            </Button>
                          </Link>
                        </Grid>
                        <Grid item className={'pl-2'} xs={6}>
                          <Button variant='contained' size={'large'} className={'w-100'} color='primary' disableElevation style={{background: '#0074A6'}} onClick={() => allowMoreEditing()}>
                            Edit and Resubmit Claim
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card.Body>
                </Card>
              )}
            </>
          ) : (
            <DialogContentText>
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait while we process your submission... <Spinner animation='border' className='bc-sync-spinner' size='sm' variant='light' />
              </span>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
