import {put, all, takeLatest, select, takeEvery} from 'redux-saga/effects' // takeLatest, select
import axios from 'axios'
import {toast} from 'react-toastify'
import isCached from '../../helpers/Cache'

export const BASE_URL = process.env.REACT_APP_APIIP
export const BASE_URL_VH = process.env.REACT_APP_VHAPIIP

const email = (state) => state.auth.email
const vhtoken = (state) => state.auth.VHToken
const cache = (state) => state.member.cache

// Action Types
export const actionTypes = {
  SET_MEMBERID: 'SET_MEMBERID',
  SET_CACHE: 'SET_CACHE',
  SET_PROFILE: 'SET_PROFILE',
  SET_INITIAL: 'SET_INITIAL',
  FETCH_MemberProfile: 'FETCH_MemberProfile',
  PUT_MemberProfile: 'PUT_MemberProfile',
  SET_PLAN: 'SET_PLAN',
  FETCH_FSBP: 'FETCH_FSBP',
  FETCH_Dependents: 'FETCH_Dependents',
  FETCH_Payments: 'FETCH_Payments',
  SET_Payments: 'SET_Payments',
  FETCH_PaymentsOutstanding: 'FETCH_PaymentsOutstanding',
  SET_PaymentsOutstanding: 'SET_PaymentsOutstanding',
  FETCH_Claims: 'FETCH_Claims',
  FETCH_Claim: 'FETCH_Claim',
  CLEAR_Claim: 'CLEAR_Claim',
  SET_Claims: 'SET_Claims',
  SET_Claim: 'SET_Claim',
  FETCH_1095BREQUESTS: 'FETCH_1095BREQUESTS',
  SET_1095BREQUESTS: 'SET_1095BREQUESTS',
  FETCH_Incentives: 'FETCH_Incentives',
  FETCH_Incentive: 'FETCH_Incentive',
  SET_Incentive: 'SET_Incentive',
  PUT_INCENTIVE: 'PUT_INCENTIVE',
  CLEAR_Incentive: 'CLEAR_Incentive',
  SET_Incentives: 'SET_Incentives',
  FETCH_SLF: 'FETCH_SLF',
  FETCH_IBP: 'FETCH_IBP',
  FETCH_Disability: 'FETCH_Disability',
  FETCH_Dental: 'FETCH_Dental',
  FETCH_Gel: 'FETCH_Gel',
  SET_Gel: 'SET_Gel',
  FETCH_Adnd: 'FETCH_Adnd',
  SET_Adnd: 'SET_Adnd',
  FETCH_CI: 'FETCH_CI',
  SET_CI: 'SET_CI',
  FETCH_HBO: 'FETCH_HBO',
  SET_HBO: 'SET_HBO',
  FETCH_COUNTRIES: 'FETCH_COUNTRIES',
  SET_COUNTRIES: 'SET_COUNTRIES',
  FETCH_Requests: 'FETCH_Requests',
  FETCH_News: 'FETCH_News',
  SET_News: 'SET_News',
  FETCH_Blogs: 'FETCH_Blogs',
  SET_Blogs: 'SET_Blogs',
  SET_ESI: 'SET_ESI',
  FETCH_ELAVONTOKEN: 'FETCH_ELAVONTOKEN',
  SET_ELAVONTOKEN: 'SET_ELAVONTOKEN',
  FETCH_AETNA: 'FETCH_AETNA',
  SET_AETNA: 'SET_AETNA',
  PUT_MESSAGE: 'PUT_MESSAGE',
  PUT_FORM: 'PUT_FORM',
  PUT_CLAIM: 'PUT_CLAIM',
  PUT_DISABILITY: 'PUT_DISABILITY',
  PUT_FORMFILE: 'PUT_FORMFILE',
  GET_UNREAD: 'GET_UNREAD',
  PUT_READ: 'PUT_READ',
  GET_BILLINGUNREAD: 'GET_BILLINGUNREAD',
  FETCH_CREDITCARDTEST: 'FETCH_CREDITCARDTEST',
  SET_CREDITCARDTEST: 'SET_CREDITCARDTEST',
  FETCH_BILLINGMESSAGES: 'FETCH_BILLINGMESSAGES',
  SET_BILLINGMESSAGES: 'SET_BILLINGMESSAGES',
  FETCH_OTHERINSURANCE: 'FETCH_OTHERINSURANCE',
  SET_OTHERINSURANCE: 'SET_OTHERINSURANCE',
  FETCH_FLAGS: 'FETCH_FLAGS',
  SET_FLAGS: 'SET_FLAGS',
  PUT_1095B: 'PUT_1095B',
  PUT_INCENTIVECHECK: 'PUT_INCENTIVECHECK',
  SET_INCENTIVECHECK: 'SET_INCENTIVECHECK',
  PUT_PAYMENTSCHECK: 'PUT_PAYMENTSCHECK',
  SET_PAYMENTSCHECK: 'SET_PAYMENTSCHECK',
  PUT_PAYMENTSOUTSTANDINGCHECK: 'PUT_PAYMENTSOUTSTANDINGCHECK',
  SET_PAYMENTSOUTSTANDINGCHECK: 'SET_PAYMENTSOUTSTANDINGCHECK',
  SET_ELAVONTOKENCHECKOK: 'SET_ELAVONTOKENCHECKOK',
  SET_ELAVONTOKENCHECKCLEAR: 'SET_ELAVONTOKENCHECKCLEAR',
  SET_ELAVONTOKENCHECKERROR: 'SET_ELAVONTOKENCHECKERROR',
  SET_CREDITCARDTESTSTATUS: 'SET_CREDITCARDTESTSTATUS',
  PUT_ERROR: 'PUT_ERROR',
  PUT_APILOG: 'PUT_APILOG',
  SET_RETURNSTATUS: 'SET_RETURNSTATUS',
  PUT_BILLINGMESSAGEREAD: 'PUT_BILLINGMESSAGEREAD',
  PUT_BILLINGMESSAGE: 'PUT_BILLINGMESSAGE',
  FETCH_BILLINGMESSAGEFILE: 'FETCH_BILLINGMESSAGEFILE',
  SET_BILLINGMESSAGEFILE: 'SET_BILLINGMESSAGEFILE',
  CLEAR_BILLINGMESSAGEFILE: 'CLEAR_BILLINGMESSAGEFILE',
  SET_USPSPROFILE: 'SET_USPSPROFILE',
  CLEAR_USPSPROFILE: 'CLEAR_USPSPROFILE'
}

// Initial state for this slice of the store
const initialState = {
  cache: {},
  Flags: {
    AccreditationDisplay: false,
    UpcomingMaintenanceDisplay: false,
    UpcomingMaintenanceMessage: '',
    OutageDisplay: false,
    OutageMessage: '',
    OutageLevel: '',
    BypassStatus: false
  },
  // This variable controls whether any FSBP functions - like add claim etc. work
  fsbpCurrent: false,
  FSBP: null,
  Dependents: [],
  IBP: {Primary: {}, Secondary: {}},
  Disability: {Primary: {}, Secondary: {}},
  Gel: {Primary: {}, Secondary: {}},
  Adnd: {Primary: {}, Secondary: {}},
  CI: {Primary: {}, Secondary: {}},
  Dental: {Primary: {}, Secondary: {}},
  Requests: [],
  BillingMessages: [],
  HBO: [],
  Countries: [],
  SLF: [],
  Incentives: [],
  Claims: [],
  Request1095B: [],
  News: [],
  Blogs: [],
  Profile: {
    first_name: '',
    middle_i: '',
    last_name: '',
    address: '',
    address_1: '',
    address_1_part1: '',
    address_1_part2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    country_of_residence: '',
    email: '',
    fax_phone: '',
    home_phone: '',
    i_phone: '',
    mob_phone: '',
    go_green: '',
    sal: '',
    work_ext_phone: '',
    work_phone: '',
    fsbpBrochure: '',
    username: '',
    password: '',
    recoveryEmail: '',
    aip_gogreen: '',
    primary_phone: '',
    secondary_phone: '',
    primary_type: 'Home',
    secondary_type: 'Other',
    alt_email: ''
  },
  USPSProfile: {},
  Payments: [],
  PaymentsOutstanding: [],
  ESI: undefined,
  AETNA: undefined,
  PaymentDetails: undefined,
  CreditCardTest: undefined,
  Request1095Form: null,
  ClaimForm: null,
  MessageForm: null,
  DisabilityForm: null,
  IncentiveForm: null,
  Form: null,
  CreditCardTestStatus: null,
  ElavonTokenCheck: null,
  Claim: '',
  Incentive: '',
  BillingMessageDownload: '',
  IncentiveCheck: false,
  PaymentsOutstandingCheck: false,
  PaymentsCheck: false,
  Request1095BCheck: false,
  ClaimCheck: false,
  ReturnStatus: '',

  OtherInsurance: {
    otherInsurance: '0',
    personIssued: '',
    dependentsNames: '',
    companyName: '',
    policyEffectiveDate: null,
    companyClaimsAddress: '',
    activeEmploymentFlag: 0,
    employmentEffectiveDate: null,
    policyNumber: '',
    policyCoverageType: 0,
    policyContractType: 0,
    retrieved: null
  }
}

// Reducer with action payloads
export function reducer(state = initialState, {type, ...action}) {
  switch (type) {
    case 'SET_CACHE': {
      return {
        ...state,
        cache: {...state.cache, [action.payload.caller.type]: {payload: action.payload.caller.payload, resultCount: action.payload.count, timestamp: Date.now()}}
      }
    }
    case 'SET_INITIAL': {
      return initialState
    }
    case 'SET_RETURNSTATUS': {
      return {
        ...state,
        ReturnStatus: action.payload
      }
    }
    case 'SET_MEMBERID': {
      return {
        ...state,
        memberID: action.payload
      }
    }
    case 'SET_HBO': {
      return {
        ...state,
        HBO: action.hbo
      }
    }
    case 'SET_COUNTRIES': {
      return {
        ...state,
        Countries: action.countries
      }
    }
    case 'SET_CREDITCARDTEST': {
      return {
        ...state,
        CreditCardTest: action.payload.creditCardTestStatus
      }
    }
    case 'SET_PROFILE': {
      return {
        ...state,
        Profile: action.payload
      }
    }
    case 'SET_USPSPROFILE': {
      return {
        ...state,
        USPSProfile: action.payload
      }
    }
    case 'CLEAR_USPSPROFILE': {
      return {
        ...state,
        USPSProfile: null
      }
    }
    case 'SET_PLAN': {
      if (action.planName === 'FSBP') {
        if (action.plan.h_ter_da) {
          // Convert to JS date
          var rawtermDate = action.plan.h_ter_da.split('/')
          var termDate = new Date(rawtermDate[2], rawtermDate[0] - 1, rawtermDate[1])
          // console.log(termDate)
        }
        var oneYearFromNow = new Date()
        oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1)
        // console.log(oneYearFromNow)
        if (!action.plan.h_ter_da || termDate > oneYearFromNow) {
          return {
            ...state,
            [action.planName]: action.plan,
            fsbpCurrent: true
          }
        } else {
          return {
            ...state,
            [action.planName]: action.plan
          }
        }
      } else {
        return {
          ...state,
          [action.planName]: action.plan
        }
      }
    }
    case 'SET_Claims': {
      return {
        ...state,
        Claims: action.claims
      }
    }
    case 'SET_1095BREQUESTS': {
      return {
        ...state,
        Request1095B: action.request1095b
      }
    }
    case 'SET_Claim': {
      return {
        ...state,
        Claim: action.payload
      }
    }
    case 'SET_BILLINGMESSAGEFILE': {
      return {
        ...state,
        BillingMessageDownload: action.payload
      }
    }
    case 'CLEAR_BILLINGMESSAGEFILE': {
      return {
        ...state,
        BillingMessageDownload: null
      }
    }
    case 'CLEAR_Claim': {
      return {
        ...state,
        Claim: null
      }
    }
    case 'CLEAR_Incentive': {
      return {
        ...state,
        Incentive: null
      }
    }
    case 'SET_Incentive': {
      return {
        ...state,
        Incentive: action.payload
      }
    }
    case 'SET_Incentives': {
      return {
        ...state,
        Incentives: action.incentives
      }
    }
    case 'SET_Payments': {
      return {
        ...state,
        Payments: action.payments
      }
    }
    case 'SET_PaymentsOutstanding': {
      return {
        ...state,
        PaymentsOutstanding: action.paymentsoutstanding
      }
    }
    case 'SET_Gel': {
      return {
        ...state,
        Gel: action.gel
      }
    }
    case 'SET_Adnd': {
      return {
        ...state,
        Adnd: action.adnd
      }
    }
    case 'SET_BILLINGMESSAGES': {
      return {
        ...state,
        BillingMessages: action.billingmessages
      }
    }
    case 'SET_OTHERINSURANCE': {
      return {
        ...state,
        OtherInsurance: action.otherInsurance
      }
    }
    case 'SET_CI': {
      return {
        ...state,
        CI: action.ci
      }
    }
    case 'SET_News': {
      return {
        ...state,
        News: action.news
      }
    }
    case 'SET_Blogs': {
      return {
        ...state,
        Blogs: action.news
      }
    }
    case 'SET_ESI': {
      return {
        ...state,
        ESI: action.esi
      }
    }
    case 'SET_ELAVONTOKEN': {
      return {
        ...state,
        PaymentDetails: action.paymentdetails
      }
    }
    case 'SET_AETNA': {
      return {
        ...state,
        AETNA: action.aetna
      }
    }
    case 'SET_CREDITCARDTESTSTATUS': {
      return {
        ...state,
        CreditCardTestStatus: 1
      }
    }
    case 'SET_ELAVONTOKENCHECKOK': {
      return {
        ...state,
        ElavonTokenCheck: 1
      }
    }
    case 'SET_ELAVONTOKENCHECKCLEAR': {
      // console.log('Changing claim form display to false')
      return {
        ...state,
        ElavonTokenCheck: null
      }
    }
    case 'SET_ELAVONTOKENCHECKERROR': {
      return {
        ...state,
        ElavonTokenCheck: 2
      }
    }
    case 'PUT_INCENTIVECHECK': {
      return {
        ...state,
        IncentiveCheck: false
      }
    }
    case 'SET_INCENTIVECHECK': {
      return {
        ...state,
        IncentiveCheck: true
      }
    }
    case 'PUT_PAYMENTSOUTSTANDINGCHECK': {
      return {
        ...state,
        PaymentsOutstandingCheck: false
      }
    }
    case 'SET_PAYMENTSOUTSTANDINGCHECK': {
      return {
        ...state,
        PaymentsOutstandingCheck: true
      }
    }
    case 'PUT_ELAVONTOKENCHECK': {
      return {
        ...state,
        ElavonTokenCheck: false
      }
    }
    case 'PUT_CLAIMCHECK': {
      return {
        ...state,
        ClaimCheck: false
      }
    }
    case 'SET_CLAIMCHECK': {
      return {
        ...state,
        ClaimCheck: true
      }
    }
    case 'PUT_REQUEST1095BCHECK': {
      return {
        ...state,
        Request1095BCheck: false
      }
    }
    case 'SET_REQUEST1095BCHECK': {
      return {
        ...state,
        Request1095BCheck: true
      }
    }
    case 'PUT_PAYMENTSCHECK': {
      return {
        ...state,
        PaymentsCheck: false
      }
    }
    case 'SET_PAYMENTSCHECK': {
      return {
        ...state,
        PaymentsCheck: true
      }
    }
    case 'SET_FLAGS': {
      console.log(action)
      return {
        ...state,
        Flags: {
          AccreditationDisplay: action.payload.accreditationDisplay,
          UpcomingMaintenanceDisplay: action.payload.upcomingMaintenanceDisplay,
          UpcomingMaintenanceMessage: action.payload.upcomingMaintenanceMessage,
          OutageDisplay: action.payload.outageDisplay,
          OutageMessage: action.payload.outageMessage,
          OutageLevel: action.payload.outageLevel,
          BypassStatus: action.payload.bypassStatus
        }
      }
    }
    default:
      return state
  }
}

export function* fetchFlags(action) {
  // Cache not applied here as this is only called before login to get any notifications etc.
  try {
    const newURL = BASE_URL + '/flags/' + action.payload.bypassToken
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        // toast.error('Member Profile Load Failed (E1)')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchFlags', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        yield put({type: 'SET_FLAGS', payload: retData.data[0]})
      }
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      // toast.error('Member Profile Load Failed (E2)')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchFlags', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    // Report error
    // Not reporting failed fetch information to user - at this stage
    // toast.error('Member Profile Load Failed (E3)')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchFlags', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* fetchMemberProfile(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      const newURL = BASE_URL + '/profile'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Member Profile Load Failed (E1)')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchMemberProfile (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        } else {
          const gridValues = JSON.parse(retData.data[0].result).map((item) => ({...item}))
          if (process.env.REACT_APP_DEBUG) {
            console.log('Something Found for Profile')
            console.log(gridValues[0])
          }
          yield put({type: 'SET_PROFILE', payload: gridValues[0]})
          yield put({type: 'SET_CACHE', payload: {caller: action}})
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Member Profile Load Failed (E2)')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchMemberProfile (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      }
    } catch (error) {
      // Report error
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Member Profile Load Failed (E3)')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchMemberProfile (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    }
  }
}

export function* fetchElavonToken(action) {
  // Cache not applied here as this link has a limited life and thus is not cached
  try {
    // console.log('Fetching Token in Duck')
    const newURL = BASE_URL + '/elavontoken/' + action.payload.prefix + '/' + action.payload.amount
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        toast.error(retData.data.returnText)
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchElavon', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_ELAVONTOKENCHECKERROR'})
      } else {
        // console.log(retData.data)
        yield put({type: 'SET_ELAVONTOKEN', paymentdetails: retData.data})
        yield put({type: 'SET_ELAVONTOKENCHECKOK'})
      }
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      toast.error('Unable Contact Payment Processor (Bad Token)')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchElavon (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      yield put({type: 'SET_ELAVONTOKENCHECKERROR'})
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    toast.error('Unable Contact Payment Processor (Bad Token)')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchElavon (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    yield put({type: 'SET_ELAVONTOKENCHECKERROR'})
  }
}

export function* fetchAETNA() {
  // Cache not applied here as this link has a limited life and thus is not cached
  // Note the fetchAETNA call - also gathers the ESI SSO code (as they mostly the same method)
  try {
    const newURL = BASE_URL + '/aetna'
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        // toast.error('AETNA Load Failed (E1)')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchAETNA (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        // console.log(retData.data)
        yield put({type: 'SET_AETNA', aetna: retData.data[0].aetnalink})
        yield put({type: 'SET_ESI', esi: retData.data[0].esilink})
      }
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      // toast.error('AETNA Load Failed (E2)')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchAETNA (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    // toast.error('AETNA Load Failed (E3)')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchAETNA (E3)', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* putMemberProfile(action) {
  // ok lets update the server
  try {
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting member profile changes: ' + action)
    }
    const newURL = BASE_URL + '/profile'
    const response = yield axios.put(newURL, action.payload)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        yield put({type: 'PUT_ERROR', payload: {Request: 'putMemberProfile (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        // Ok it may be a verification request from USPS - check
        if (retData.data.returnStatus === 'Verify') {
          // Store response from API re USPS which also triggers the modal
          yield put({type: 'CLEAR_USPSPROFILE', payload: null})
          yield put({type: 'SET_USPSPROFILE', payload: retData.data})
          yield put({type: 'SET_RETURNSTATUS', payload: 'VERIFY'})
        } else {
          if (process.env.REACT_APP_DEBUG) {
            console.log('ok saved')
          }
          yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
          yield put({type: 'FETCH_MemberProfile', payload: {popCache: true}})
          yield put({type: 'CLEAR_USPSPROFILE', payload: null})
        }
      }
    } else {
      const retData = response
      yield put({type: 'SET_RETURNSTATUS', payload: 'Save Failed'})
      yield put({type: 'PUT_ERROR', payload: {Request: 'putMemberProfile (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Save Failed'})
    if (process.env.REACT_APP_DEBUG) {
      console.log(error)
    }
    yield put({type: 'PUT_ERROR', payload: {Request: 'putMemberProfile (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* putFormFile(action) {
  // ok lets update the server
  try {
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting form file')
      console.log(action)
    }
    const newURL = BASE_URL_VH + '/data/formfile/' + action.fileHandle
    const response = yield axios.put(newURL, action.formData)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // API has failed gracefully
        // Will need to revisit this as this a second part of the create form feature so if this part fails what does it mean?
        toast.error(retData.data.returnText + '(E1)', {autoClose: false})
        yield put({type: 'SET_FORMERROR'})
        yield put({type: 'PUT_ERROR', payload: {Request: 'putFormFile (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        // Need to add flag here for when form saves correctly
        toast.success('Form Saved', {autoClose: 5000})
        yield put({type: 'SET_FORMOK'})
      }
    } else {
      // Report error
      const retData = response
      toast.error('Form Save Failed (E2)', {autoClose: false})
      yield put({type: 'SET_FORMERROR'})
      yield put({type: 'PUT_ERROR', payload: {Request: 'putFormFile (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    toast.error('Form Save Failed (E3)', {autoClose: false})
    yield put({type: 'SET_FORMERROR'})
    yield put({type: 'PUT_ERROR', payload: {Request: 'putFormFile (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* fetchClaim(action) {
  // This is not cached as it is loading a particular claim
  process.env.REACT_APP_DEBUG && console.log(action)
  try {
    yield put({type: 'SET_CLAIM', payload: ''})
    const newURL = BASE_URL + '/claim'
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // process.env.REACT_APP_DEBUG && console.log(response.data.filerequested)
      yield put({type: 'SET_Claim', payload: response.data.filerequested})
    } else {
      toast.error(`Failed: ${action.type}`)
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (error) {
    toast.error(`Failed: ${action.type}`)
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchBillingMessageDownload(action) {
  // This is not cached as it is loading a particular claim
  process.env.REACT_APP_DEBUG && console.log(action)
  try {
    yield put({type: 'SET_BILLINGMESSAGEFILE', payload: ''})
    const newURL = BASE_URL + '/billingmessagedownload'
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // process.env.REACT_APP_DEBUG && console.log(response.data.filerequested)
      yield put({type: 'SET_BILLINGMESSAGEFILE', payload: response.data.filerequested})
    } else {
      toast.error(`Failed: ${action.type}`)
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (error) {
    toast.error(`Failed: ${action.type}`)
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchIncentive(action) {
  // This is not cached as it is loading a particular incentive claim
  process.env.REACT_APP_DEBUG && console.log(action)
  try {
    const newURL = BASE_URL + '/incentive'
    const response = yield axios.post(newURL, action.payload)
    if (response.status === 200 && response.data.returnStatus !== 'Error') {
      process.env.REACT_APP_DEBUG && console.log(`ok saved: ${action.type}`)
      process.env.REACT_APP_DEBUG && console.log(response.data)
      // process.env.REACT_APP_DEBUG && console.log(response.data.filerequested)
      yield put({type: 'SET_Incentive', payload: response.data.filerequested})
    } else {
      toast.error(`Failed: ${action.type}`)
      yield put({
        type: 'PUT_ERROR',
        payload: {Request: action.type, ErrorType: `REDUX Function, ReturnCode: ${response.status}, ReturnStatus: ${response.returnStatus}`, Message: response.data.returnText}
      })
    }
  } catch (error) {
    toast.error(`Failed: ${action.type}`)
    yield put({type: 'PUT_ERROR', payload: {Request: action.type, ErrorType: 'REDUX Function, API call has errored', Message: error.message}})
  }
}

export function* fetchHBO(action) {
  // This probably needs to be removed however it cached for now
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch HBO')
    }
    try {
      const newURL = BASE_URL + '/hbos'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        console.log(retData)
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching HOB Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error("Payments Load Failed (E1) ");
          yield put({
            type: 'PUT_ERROR',
            payload: {
              Request: 'fetchHBO (E1) ',
              ErrorType: 'REDUX Function',
              Message: retData.data.returnText
            }
          })
          yield put({type: 'SET_HBO', hbo: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for HBO')
              console.log(retData.data)
            }
            yield put({type: 'SET_HBO', hbo: retData.data})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for HBO')
            }
            yield put({type: 'SET_HBO', hbo: ''})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error("Payments Load Failed (E2) ");
        yield put({
          type: 'PUT_ERROR',
          payload: {
            Request: 'fetchHBO (E2) ',
            ErrorType: 'REDUX Function',
            Message: retData.data.returnText
          }
        })
        yield put({type: 'SET_HBO', hbo: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error("Payments Load Failed (E3) ");
      yield put({
        type: 'PUT_ERROR',
        payload: {
          Request: 'fetchHBO (E3)',
          ErrorType: 'REDUX Function',
          Message: error.message
        }
      })
      yield put({type: 'SET_HBO', hbo: ''})
    }
  }
}

export function* fetchCountries(action) {
  // This probably needs to be removed however it cached for now
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch Countries')
    }
    try {
      const newURL = BASE_URL + '/countries'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        // console.log(retData)
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Country Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error("Payments Load Failed (E1) ");
          yield put({
            type: 'PUT_ERROR',
            payload: {
              Request: 'fetchCountries (E1) ',
              ErrorType: 'REDUX Function',
              Message: retData.data.returnText
            }
          })
          yield put({type: 'SET_COUNTRIES', countries: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for Countries')
              console.log(retData.data)
            }
            yield put({type: 'SET_COUNTRIES', countries: retData.data})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for Countries')
            }
            yield put({type: 'SET_COUNTRIES', countries: ''})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error("Payments Load Failed (E2) ");
        yield put({
          type: 'PUT_ERROR',
          payload: {
            Request: 'fetchCountries (E2) ',
            ErrorType: 'REDUX Function',
            Message: retData.data.returnText
          }
        })
        yield put({type: 'SET_COUNTRIES', countries: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error("Payments Load Failed (E3) ");
      yield put({
        type: 'PUT_ERROR',
        payload: {
          Request: 'fetchCountries (E3)',
          ErrorType: 'REDUX Function',
          Message: error.message
        }
      })
      yield put({type: 'SET_Countries', countries: ''})
    }
  }
}

export function* postClaimData(action) {
  // ok lets update the server
  try {
    // Now logging every call as some seem to fail without being sent to the server
    // First convert formdata to JSON
    yield put({type: 'PUT_APILOG', payload: Object.fromEntries(action.payload.formData)})
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    //
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting claim data')
      console.log(Object.fromEntries(action.payload.formData))
    }
    const newURL = BASE_URL + '/claimdata'
    const response = yield axios.post(newURL, action.payload.formData)
    yield put({type: 'PUT_APILOG', payload: response})
    // Check response
    // eslint-disable-next-line no-prototype-builtins
    if (response && response.hasOwnProperty('status') && response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E1)', ErrorType: 'REDUX Function-1', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        // Close processing window and head back to the main menu
        yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
      }
    } else {
      // All getting complex here
      // Firstly update the user
      yield put({type: 'SET_RETURNSTATUS', payload: 'Claim Save Failed'})
      // Now check how much error information exists
      if (response) {
        const retData = response
        // eslint-disable-next-line no-prototype-builtins
        if (response.hasOwnProperty('status')) {
          // Ok we have a response and hopefully some data
          yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-Full Response', Message: response.status}})
          // Lets see how this goes
          yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-2', Message: retData.data.returnText}})
          yield put({type: 'PUT_ERRORCLAIM', payload: action.payload})
        } else {
          // Report response only
          yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-Response Only', Message: response}})
          yield put({type: 'PUT_ERRORCLAIM', payload: action.payload})
        }
      } else {
        // It is all bad - no response at all
        yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-No Response', Message: 'There was no response at all'}})
        yield put({type: 'PUT_ERRORCLAIM', payload: action.payload})
      }
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Claim Save Failed'})
    // toast.error('Claim Save Failed', {autoClose: false})
    yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E3): ', ErrorType: 'REDUX Function-3', Message: error.message}})
    yield put({type: 'PUT_ERRORCLAIM', payload: action.payload})
  }
}

export function* postIncentiveData(action) {
  // ok lets update the server
  try {
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting incentive data')
      console.log(action)
    }
    const newURL = BASE_URL + '/incentivedata'
    const response = yield axios.post(newURL, action.payload.formData)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        yield put({type: 'PUT_ERROR', payload: {Request: 'postIncentiveData (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
      }
    } else {
      // Report error
      const retData = response
      yield put({type: 'SET_RETURNSTATUS', payload: 'Incentive Claim Save Failed'})
      yield put({type: 'PUT_ERROR', payload: {Request: 'postIncentiveData (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Incentive Claim Save Failed'})
    yield put({type: 'PUT_ERROR', payload: {Request: 'postIncentiveData (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* putBillingMessage(action) {
  // ok lets update the server
  try {
    // Now logging every call as some seem to fail without being sent to the server
    // First convert formdata to JSON
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    //
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting billing message response')
      console.log(Object.fromEntries(action.payload.formData))
    }
    const newURL = BASE_URL + '/billingmessagereply'
    const response = yield axios.put(newURL, action.payload.formData)
    // Check response
    // eslint-disable-next-line no-prototype-builtins
    if (response && response.hasOwnProperty('status') && response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        yield put({type: 'PUT_ERROR', payload: {Request: 'postBillingMessage (E1)', ErrorType: 'REDUX Function-1', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        // Close processing window and head back to the main menu
        yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
      }
    } else {
      // All getting complex here
      // Firstly update the user
      yield put({type: 'SET_RETURNSTATUS', payload: 'Reply Save Failed'})
      // Now check how much error information exists
      if (response) {
        const retData = response
        // eslint-disable-next-line no-prototype-builtins
        if (response.hasOwnProperty('status')) {
          // Ok we have a response and hopefully some data
          yield put({type: 'PUT_ERROR', payload: {Request: 'postBillingMessage (E2)', ErrorType: 'REDUX Function-Full Response', Message: response.status}})
          // Lets see how this goes
          yield put({type: 'PUT_ERROR', payload: {Request: 'postBillingMessage (E2)', ErrorType: 'REDUX Function-2', Message: retData.data.returnText}})
        } else {
          // Report response only
          yield put({type: 'PUT_ERROR', payload: {Request: 'postBillingMessage (E2)', ErrorType: 'REDUX Function-Response Only', Message: response}})
        }
      } else {
        // It is all bad - no response at all
        yield put({type: 'PUT_ERROR', payload: {Request: 'postBillingMessage (E2)', ErrorType: 'REDUX Function-No Response', Message: 'There was no response at all'}})
      }
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Reply Save Failed'})
    yield put({type: 'PUT_ERROR', payload: {Request: 'postBillingMessage (E3): ', ErrorType: 'REDUX Function-3', Message: error.message}})
  }
}

export function* postRequest1095BData(action) {
  // ok lets update the server
  try {
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting Request 1095B data')
      console.log(action)
    }
    const newURL = BASE_URL + '/request1095bdata'
    const response = yield axios.post(newURL, action.payload)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        // The process will return an error if someone puts too many requests in for the same information - don't really want to log this
        if (retData.data && retData.data.returnText === 'Your request is currently on file. If you do not receive your 1095b within 5 business days, please contact us again. Thank you!') {
          // Ok it is not an error only a notification
        } else {
          // Ok it is a real error
          yield put({type: 'PUT_ERROR', payload: {Request: 'postRequest1095Data', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        }
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        // Close processing window and head back to the main menu
        yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
      }
    } else {
      // Report error
      const retData = response
      yield put({type: 'SET_RETURNSTATUS', payload: 'Request Save Failed'})
      yield put({type: 'PUT_ERROR', payload: {Request: 'postRequest1095Data', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Request Save Failed'})
    yield put({type: 'PUT_ERROR', payload: {Request: 'postRequest1095Data', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* postDisabilityData(action) {
  // ok lets update the server
  try {
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting disability data')
      console.log(action)
    }
    const newURL = BASE_URL + '/disabilitydata'
    const response = yield axios.post(newURL, action.payload.formData)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        yield put({type: 'PUT_ERROR', payload: {Request: 'postDisabilityData (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        // Close processing window and head back to the main menu
        yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
      }
    } else {
      // Report error
      // Will need to revisit this as this a second part of the create form feature so if this part fails what does it mean?
      const retData = response
      yield put({type: 'SET_RETURNSTATUS', payload: 'Enrollment Save Failed'})
      yield put({type: 'PUT_ERROR', payload: {Request: 'postDisabilityData (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    // Will need to revisit this as this a second part of the create form feature so if this part fails what does it mean?
    yield put({type: 'SET_RETURNSTATUS', payload: 'Enrollment Save Failed'})
    yield put({type: 'PUT_ERROR', payload: {Request: 'postDisabilityData (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* putFormData(action) {
  // ok lets update the server
  try {
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting form data')
      console.log(Object.fromEntries(action.payload.formData))
      //
    }
    yield put({type: 'SET_RETURNSTATUS', payload: ''})
    const newURL = BASE_URL_VH + '/data/form'
    const response = yield axios.put(newURL, action.payload.formData)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        yield put({type: 'PUT_ERROR', payload: {Request: 'postFormData (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        // Ok sending formData and a CSV string of GUID
        yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
      }
    } else {
      // Report error
      const retData = response
      yield put({type: 'SET_RETURNSTATUS', payload: 'Form Save Failed'})
      yield put({type: 'PUT_ERROR', payload: {Request: 'postFormData (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Form Save Failed'})
    yield put({type: 'PUT_ERROR', payload: {Request: 'postFormData (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* putMessage(action) {
  // Set VH Token variable
  let vhtokenState = yield select(vhtoken)
  yield put({type: 'SET_RETURNSTATUS', payload: ''})
  // ok lets update the server
  try {
    if (process.env.REACT_APP_DEBUG) {
      console.log('Putting new message: ' + action)
    }
    const newURL = BASE_URL_VH + '/data/message'
    const response = yield axios.put(newURL, action.payload)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // API has failed gracefully
        yield put({type: 'SET_RETURNSTATUS', payload: retData.data.returnText})
        yield put({type: 'PUT_ERROR', payload: {Request: 'putMessage (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        yield put({type: 'SET_RETURNSTATUS', payload: 'OK'})
        yield put({type: 'FETCH_Requests', payload: {email: action.payload.emailFrom, authVHToken: vhtokenState}})
      }
    } else {
      // Report error
      const retData = response
      yield put({type: 'SET_RETURNSTATUS', payload: 'Message Save Failed'})
      yield put({type: 'PUT_ERROR', payload: {Request: 'putMessage (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    yield put({type: 'SET_RETURNSTATUS', payload: 'Message Save Failed'})
    yield put({type: 'PUT_ERROR', payload: {Request: 'putMessage (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* putMessageRead(action) {
  // ok lets update the server
  try {
    if (process.env.REACT_APP_DEBUG) {
      console.log('Marking Message as Read: ' + action)
    }
    const newURL = BASE_URL_VH + '/data/readmessage'
    const response = yield axios.put(newURL, {requestID: action.payload.requestID})
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // API has failed gracefully
        // Do not report error as message read action is silent
        // toast.error(retData.data.returnText + ' (E1)',{ autoClose:false })
        yield put({type: 'PUT_ERROR', payload: {Request: 'putMessageRead (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        yield put({type: 'GET_UNREAD', payload: {vhtoken: action.payload.vhtoken, email: action.payload.email, popCache: true}})
      }
    } else {
      // Report error
      const retData = response
      // Do not report error as message read action is silent
      // toast.error('Read Message Update Failed (E2)',{ autoClose:false })
      yield put({type: 'PUT_ERROR', payload: {Request: 'putMessageRead (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    // toast.error('Read Message Update Failed (E3)',{ autoClose:false })
    // Do not report error as message read action is silent
    yield put({type: 'PUT_ERROR', payload: {Request: 'putMessageRead (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* putBillingMessageRead(action) {
  // ok lets update the server
  try {
    if (process.env.REACT_APP_DEBUG) {
      console.log('Marking Billing Message as Read: ' + action)
    }
    const newURL = BASE_URL + '/billingmessageread'
    const response = yield axios.put(newURL, action.payload)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // API has failed gracefully
        // Do not report error as message read action is silent
        yield put({type: 'PUT_ERROR', payload: {Request: 'putBillingMessageRead (E1)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
        yield put({type: 'FETCH_BILLINGMESSAGES', payload: {popCache: true}})
        yield put({type: 'GET_BILLINGUNREAD', payload: {popCache: true}})
      }
    } else {
      // Report error
      const retData = response
      // Do not report error as message read action is silent
      // toast.error('Read Message Update Failed (E2)',{ autoClose:false })
      yield put({type: 'PUT_ERROR', payload: {Request: 'putBillingMessageRead (E2)', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
    }
  } catch (error) {
    // toast.error('Read Message Update Failed (E3)',{ autoClose:false })
    // Do not report error as message read action is silent
    yield put({type: 'PUT_ERROR', payload: {Request: 'putBillingMessageRead (E3): ', ErrorType: 'REDUX Function', Message: error.message}})
  }
}

export function* fetchRequests(action) {
  // At this stage this is not cached as new requests can come in during the session so a live feed is needed
  const planName = 'Requests'
  try {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In fetchRequests')
      console.log(action)
    }
    if (action.payload.authVHToken) {
      const newURL = BASE_URL_VH + '/data/requestsByEmail/' + encodeURI(action.payload.email)
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Request Data for ' + planName)
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Request Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchRequests (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_PLAN', planName: planName, plan: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            const planValues = retData.data
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for ' + planName)
              console.log(planValues)
            }
            yield put({type: 'SET_PLAN', planName: planName, plan: planValues})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for ' + planName)
            }
            yield put({type: 'SET_PLAN', planName: planName, plan: ''})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Request Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchRequests (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_PLAN', planName: planName, plan: ''})
      }
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    // toast.error('Request Load Failed (E3) ')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchRequests (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    yield put({type: 'SET_PLAN', planName: planName, plan: ''})
  }
}

export function* fetchUnread(action) {
  const planName = 'Unread'
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      // Get members email
      let emailState = yield select(email)
      let vhtokenState = yield select(vhtoken)
      if (process.env.REACT_APP_DEBUG) {
        console.log('In fetchUnread')
        console.log(emailState)
        console.log(vhtokenState)
        console.log(action)
      }
      if (vhtokenState) {
        const newURL = BASE_URL_VH + '/data/unreadmessages/' + encodeURI(emailState)
        const response = yield axios.get(newURL)
        // Check response
        if (response.status === 200) {
          const retData = response
          if (process.env.REACT_APP_DEBUG) {
            console.log('Fetching Unread Data for ' + planName)
          }
          if (retData.returnStatus === 'Error') {
            // Report error
            // Not reporting failed fetch information to user - at this stage
            // toast.error('Request Load Failed (E1) ')
            yield put({type: 'SET_UNREAD', unreadcount: '0'})
          } else {
            // Check if something was found - this check is rudimentary - may need a better one
            if (retData.data.length > 0) {
              if (process.env.REACT_APP_DEBUG) {
                console.log('Something Found for ' + planName)
                console.log(retData.data[0])
              }
              yield put({type: 'SET_UNREAD', unreadcount: retData.data[0].unreadCount})
              yield put({type: 'SET_CACHE', payload: {caller: action}})
            } else {
              // Nothing found
              if (process.env.REACT_APP_DEBUG) {
                console.log('Nothing Found for ' + planName)
              }
              yield put({type: 'SET_UNREAD', unreadcount: '0'})
            }
          }
        } else {
          // Not reporting failed fetch information to user - at this stage
          const retData = response
          // toast.error('Request Load Failed (E2) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchUnread (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_UNREAD', unreadcount: null})
        }
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Request Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchUnread (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_UNREAD', unreadcount: null})
    }
  }
}

export function* fetchBillingUnread(action) {
  const planName = 'BillingUnread'
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    try {
      // Get members email
      const newURL = BASE_URL + '/billingunreadmessages'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching BillingUnread Data for ' + planName)
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          yield put({type: 'SET_BILLINGUNREAD', unreadcount: '0'})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for ' + planName)
              console.log(retData.data[0])
            }
            yield put({type: 'SET_BILLINGUNREAD', unreadcount: retData.data[0].unreadCount})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for ' + planName)
            }
            yield put({type: 'SET_BILLINGUNREAD', unreadcount: '0'})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Request Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchUnread (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_BILLINGUNREAD', unreadcount: null})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchUnread (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_BILLINGUNREAD', unreadcount: null})
    }
  }
}

export function* fetchNews(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch News')
    }
    try {
      const newURL = BASE_URL + '/news'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        console.log(retData)
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching News Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('News Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchNews (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_News', news: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for News')
              console.log(retData.data)
            }
            yield put({type: 'SET_News', news: retData.data})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for News')
            }
            yield put({type: 'SET_News', news: ''})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('News Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchNews (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_News', news: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('News Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchNews (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_News', news: ''})
    }
  }
}

export function* fetchBlogs(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch Blogs')
    }
    try {
      const newURL = BASE_URL + '/blogs'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        console.log(retData)
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Blogs Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('News Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchBlogs (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_Blogs', news: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for Blogs')
              console.log(retData.data)
            }
            yield put({type: 'SET_Blogs', news: retData.data})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for Blogs')
            }
            yield put({type: 'SET_Blogs', news: ''})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('News Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchBlogs (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_Blogs', news: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('News Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchBlogs (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_Blogs', news: ''})
    }
  }
}

export function* fetchClaims() {
  // Ideally this should be cached however claims can also be added from the Member Portal - perhaps when the member portal is removed this can be cached.
  if (process.env.REACT_APP_DEBUG) {
    console.log('In Fetch Claims')
  }
  try {
    yield put({type: 'PUT_CLAIMCHECK'})
    const newURL = BASE_URL + '/claims'
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (process.env.REACT_APP_DEBUG) {
        console.log('Fetching Claims Data')
        console.log(retData)
      }
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        // toast.error('Claim Load Failed (E1) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchClaims (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_Claims', claims: ''})
      } else {
        // Check if something was found - this check is rudimentary - may need a better one
        if (retData.data.length > 0) {
          if (process.env.REACT_APP_DEBUG) {
            console.log('Something Found for Claims')
            console.log(retData.data)
          }
          yield put({type: 'SET_Claims', claims: retData.data})
        } else {
          // Nothing found
          if (process.env.REACT_APP_DEBUG) {
            console.log('Nothing Found for Claims')
          }
          yield put({type: 'SET_Claims', claims: ''})
        }
      }
      yield put({type: 'SET_CLAIMCHECK'})
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      // toast.error('Claims Load Failed (E2) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchClaims (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      yield put({type: 'SET_Claims', claims: ''})
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    // toast.error('Claims Load Failed (E3) ')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchClaims (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    yield put({type: 'SET_Claims', claims: ''})
  }
}

export function* fetch1095BRequests() {
  // Ideally this should be cached however 1095B Requests can also be added from the Member Portal - perhaps when the member portal is removed this can be cached.
  if (process.env.REACT_APP_DEBUG) {
    console.log('In Fetch 1095B Requests')
  }
  try {
    yield put({type: 'PUT_REQUEST1095BCHECK'})
    const newURL = BASE_URL + '/requests1095b'
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (process.env.REACT_APP_DEBUG) {
        console.log('Fetching 1095B Request Data')
        console.log(retData)
      }
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        // toast.error('Claim Load Failed (E1) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetch1095BRequests (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_1095BREQUESTS', request1095b: ''})
      } else {
        // Check if something was found - this check is rudimentary - may need a better one
        if (retData.data.length > 0) {
          if (process.env.REACT_APP_DEBUG) {
            console.log('Something Found for 1095B Requests')
            console.log(retData.data)
          }
          yield put({type: 'SET_1095BREQUESTS', request1095b: retData.data})
        } else {
          // Nothing found
          if (process.env.REACT_APP_DEBUG) {
            console.log('Nothing Found for 1095B Requests')
          }
          yield put({type: 'SET_1095BREQUESTS', request1095b: ''})
        }
      }
      yield put({type: 'SET_REQUEST1095BCHECK'})
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetch1095BRequests (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      yield put({type: 'SET_1095BREQUESTS', request1095b: ''})
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetch1095BRequests (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    yield put({type: 'SET_1095BREQUESTS', request1095b: ''})
  }
}

export function* fetchIncentives() {
  // Ideally this should be cached however incentives can also be added from the Member Portal - perhaps when the member portal is removed this can be cached.
  if (process.env.REACT_APP_DEBUG) {
    console.log('In Fetch Incentives')
  }
  try {
    yield put({type: 'PUT_INCENTIVECHECK'})
    const newURL = BASE_URL + '/incentives'
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      if (process.env.REACT_APP_DEBUG) {
        console.log('Fetching Incentives Data')
        console.log(retData)
      }
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        // toast.error('Claim Load Failed (E1) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchIncentives(E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_Incentives', incentives: ''})
      } else {
        // Check if something was found - this check is rudimentary - may need a better one
        if (retData.data.length > 0) {
          if (process.env.REACT_APP_DEBUG) {
            console.log('Something Found for Incentives')
            console.log(retData.data)
          }
          yield put({type: 'SET_Incentives', incentives: retData.data})
        } else {
          // Nothing found
          if (process.env.REACT_APP_DEBUG) {
            console.log('Nothing Found for Incentives')
          }
          yield put({type: 'SET_Incentives', incentives: ''})
        }
        yield put({type: 'SET_INCENTIVECHECK'})
      }
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      // toast.error('Incentives Load Failed (E2) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchIncentives (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      yield put({type: 'SET_Incentives', incentives: ''})
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    // toast.error('Incentives Load Failed (E3) ')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchIncentives (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    yield put({type: 'SET_Incentives', incentives: ''})
  }
}

export function* fetchPayments(action) {
  // This probably needs to be removed however it cached for now
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch Payments')
    }
    try {
      yield put({type: 'PUT_PAYMENTSCHECK'})
      const newURL = BASE_URL + '/payments'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        console.log(retData)
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Payments Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Payments Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPayments (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_Payments', payments: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for Payments')
              console.log(retData.data)
            }
            yield put({type: 'SET_Payments', payments: retData.data})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for Payments')
            }
            yield put({type: 'SET_Payments', payments: ''})
          }
        }
        yield put({type: 'SET_PAYMENTSCHECK'})
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Payments Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPayments (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_Payments', payments: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Payments Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPayments (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_Payments', payments: ''})
    }
  }
}

export function* fetchBillingMessages(action) {
  // Not overly sure about caching this as they entered from dashboard however they are rare so only updating per session is probably ok
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch Billing Messages')
    }
    try {
      const newURL = BASE_URL + '/billingmessages'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        // console.log(retData)
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Billing Messages Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Payments Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchBillingMessages (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_BILLINGMESSAGES', billingmessages: null})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data.length > 0) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for Billing Messages')
              console.log(retData.data)
            }
            yield put({type: 'SET_BILLINGMESSAGES', billingmessages: retData.data})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for Billing Messages')
            }
            yield put({type: 'SET_BILLINGMESSAGES', billingmessages: null})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Payments Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchBillingMessages  (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_BILLINGMESSAGES', billingmessages: null})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Payments Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchBillingMessages (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_BILLINGMESSAGES', billingmessages: null})
    }
  }
}

export function* fetchOtherInsurance() {
  // This is not cached at the moment as it can be updated/deleted as part of claims
  if (process.env.REACT_APP_DEBUG) {
    console.log('In Fetch Other Insurance')
  }
  try {
    const newURL = BASE_URL + '/otherinsurance'
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      console.log(retData)
      if (process.env.REACT_APP_DEBUG) {
        console.log('Fetching Other Insurance Data')
      }
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        // toast.error('Payments Load Failed (E1) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchOtherInsurance (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_OTHERINSURANCE', otherInsurance: initialState.OtherInsurance})
      } else {
        // Check if something was found - this check is rudimentary - may need a better one
        console.log(initialState.OtherInsurance)
        if (retData.data[0].personIssued !== null) {
          if (process.env.REACT_APP_DEBUG) {
            console.log('Something Found for Other Insurance')
            console.log(retData.data)
          }
          yield put({type: 'SET_OTHERINSURANCE', otherInsurance: retData.data[0]})
        } else {
          // Nothing found
          if (process.env.REACT_APP_DEBUG) {
            console.log('Nothing Found for Other Insurance Messages')
          }
          yield put({type: 'SET_OTHERINSURANCE', otherInsurance: initialState.OtherInsurance})
        }
      }
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      // toast.error('Payments Load Failed (E2) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchOtherInsurance  (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      yield put({type: 'SET_OTHERINSURANCE', otherInsurance: initialState.OtherInsurance})
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    // toast.error('Payments Load Failed (E3) ')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchOtherInsurance (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    yield put({type: 'SET_OTHERINSURANCE', otherInsurance: initialState.OtherInsurance})
  }
}

export function* fetchPaymentsOutstanding() {
  // This is not cached as it alters as payments are made
  if (process.env.REACT_APP_DEBUG) {
    console.log('In Fetch PaymentsOutstanding')
  }
  try {
    yield put({type: 'PUT_PAYMENTOUTSTANDINGCHECK'})
    const newURL = BASE_URL + '/paymentsoutstanding'
    const response = yield axios.get(newURL)
    // Check response
    if (response.status === 200) {
      const retData = response
      // console.log(retData)
      if (process.env.REACT_APP_DEBUG) {
        console.log('Fetching Payments Outstanding Data')
      }
      if (retData.returnStatus === 'Error') {
        // Report error
        // Not reporting failed fetch information to user - at this stage
        // toast.error('Payments Load Failed (E1) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPaymentsOutstanding (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_PaymentsOutstanding', paymentsoutstanding: ''})
      } else {
        // Check if something was found - this check is rudimentary - may need a better one
        if (retData.data.length > 0) {
          if (process.env.REACT_APP_DEBUG) {
            console.log('Something Found for PaymentsOutstanding')
            console.log(retData.data)
          }
          yield put({type: 'SET_PaymentsOutstanding', paymentsoutstanding: retData.data})
        } else {
          // Nothing found
          if (process.env.REACT_APP_DEBUG) {
            console.log('Nothing Found for PaymentsOutstanding')
          }
          yield put({type: 'SET_PaymentsOutstanding', paymentsoutstanding: ''})
          yield put({type: 'SET_PAYMENTSOUTSTANDINGCHECK'})
        }
      }
    } else {
      // Not reporting failed fetch information to user - at this stage
      const retData = response
      // toast.error('Payments Load Failed (E2) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPaymentsOutstanding (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
      yield put({type: 'SET_PaymentsOutstanding', paymentsoutstanding: ''})
    }
  } catch (error) {
    // Not reporting failed fetch information to user - at this stage
    // toast.error('Payments Load Failed (E3) ')
    yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPaymentsOutstanding (E3)', ErrorType: 'REDUX Function', Message: error.message}})
    yield put({type: 'SET_PaymentsOutstanding', paymentsoutstanding: ''})
  }
}

export function* fetchGel(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch Gel')
    }
    try {
      const newURL = BASE_URL + '/gel'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Gel Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Gel Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchGel (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_Gel', gel: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data[0].Primary.Age) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for Gel')
              console.log(retData.data[0])
            }
            yield put({type: 'SET_Gel', gel: retData.data[0]})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for Gel')
            }
            yield put({type: 'SET_Gel', gel: ''})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Gel Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchGel (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_Gel', gel: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Gel Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchGel (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_Gel', gel: ''})
    }
  }
}

export function* fetchAdnd(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch Adnd')
    }
    try {
      const newURL = BASE_URL + '/adnd'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Adnd Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Gel Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchAdnd (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_Adnd', adnd: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data[0].adndState) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for Adnd')
              console.log(retData.data[0])
            }
            yield put({type: 'SET_Adnd', adnd: retData.data[0]})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for Adnd')
            }
            yield put({type: 'SET_Adnd', adnd: ''})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Gel Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchAdnd (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_Adnd', adnd: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Gel Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchAdnd (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_Adnd', adnd: ''})
    }
  }
}

export function* fetchCI(action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch CI')
    }
    try {
      const newURL = BASE_URL + '/ci'
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching CI Data')
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Gel Load Failed (E1) ')
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchCI (E1) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_CI', ci: ''})
        } else {
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data[0].Primary.Age) {
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for CI')
              console.log(retData.data[0])
            }
            yield put({type: 'SET_CI', ci: retData.data[0]})
            yield put({type: 'SET_CACHE', payload: {caller: action}})
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for CI')
            }
            yield put({type: 'SET_CI', gel: ''})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Gel Load Failed (E2) ')
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchCI (E2) ', ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_CI', ci: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Gel Load Failed (E3) ')
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchCI (E3)', ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_CI', ci: ''})
    }
  }
}

export function* fetchPlan(planName, setasArray, action) {
  let currentCache = yield select(cache)
  if (!isCached(currentCache, action)) {
    if (process.env.REACT_APP_DEBUG) {
      console.log('In Fetch Plan' + planName)
    }
    try {
      const newURL = BASE_URL + '/plan/' + planName
      const response = yield axios.get(newURL)
      // Check response
      if (response.status === 200) {
        const retData = response
        if (process.env.REACT_APP_DEBUG) {
          console.log('Fetching Plan Data for ' + planName)
        }
        if (retData.returnStatus === 'Error') {
          // Report error
          // Not reporting failed fetch information to user - at this stage
          // toast.error('Plan Load Failed (E1) :' + planName)
          yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPlan (E1) :' + planName, ErrorType: 'REDUX Function', Message: retData.data.returnText}})
          yield put({type: 'SET_PLAN', planName: planName, plan: ''})
        } else {
          if (process.env.REACT_APP_DEBUG) {
            console.log('Set as Array : ' + setasArray)
          }
          // Check if something was found - this check is rudimentary - may need a better one
          if (retData.data[0].result.length > 0) {
            const planValues = JSON.parse(retData.data[0].result).map((item) => ({...item}))
            if (process.env.REACT_APP_DEBUG) {
              console.log('Something Found for ' + planName)
              console.log(planValues)
            }
            if (setasArray) {
              yield put({type: 'SET_PLAN', planName: planName, plan: planValues})
              yield put({type: 'SET_CACHE', payload: {caller: action}})
            } else {
              yield put({type: 'SET_PLAN', planName: planName, plan: planValues[0]})
              yield put({type: 'SET_CACHE', payload: {caller: action}})
            }
          } else {
            // Nothing found
            if (process.env.REACT_APP_DEBUG) {
              console.log('Nothing Found for ' + planName)
            }
            yield put({type: 'SET_PLAN', planName: planName, plan: ''})
          }
        }
      } else {
        // Not reporting failed fetch information to user - at this stage
        const retData = response
        // toast.error('Plan Load Failed (E2): ' + planName)
        yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPlan (E2): ' + planName, ErrorType: 'REDUX Function', Message: retData.data.returnText}})
        yield put({type: 'SET_PLAN', planName: planName, plan: ''})
      }
    } catch (error) {
      // Not reporting failed fetch information to user - at this stage
      // toast.error('Plan Load Failed (E3): ' + planName)
      yield put({type: 'PUT_ERROR', payload: {Request: 'fetchPlan (E3)' + planName, ErrorType: 'REDUX Function', Message: error.message}})
      yield put({type: 'SET_PLAN', planName: planName, plan: ''})
    }
  }
}

export function* putError(action) {
  if (process.env.REACT_APP_DEBUG) {
    console.log('Saving Error content')
  }
  // ok lets update the server
  try {
    const newURL = BASE_URL + '/error'
    const response = yield axios.post(newURL, action.payload)
    if (process.env.REACT_APP_DEBUG) {
      console.log(response)
    }
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // Note in this case as the error logging failed, the errors will be written to console
        console.log('error: Warning Cannot Save Error (Code - ' + retData.data.returnText + ')')
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
      }
    } else {
      // Report error
      const retData = response
      // Note in this case as the error logging failed, the errors will be written to console
      console.log('error: Warning Cannot Save Error (Code - ' + retData.data.returnText + ')')
    }
  } catch (error) {
    // Note in this case as the error logging failed, the errors will be written to console
    console.log('error: Warning Cannot Save Task Grid (Code - SVR)')
    console.log(error)
  }
}

export function* putErrorClaim(action) {
  if (process.env.REACT_APP_DEBUG) {
    console.log('Saving Error content')
    console.log(Object.fromEntries(action.payload.formData))
  }
  // ok lets update the server
  try {
    const newURL = BASE_URL + '/errorclaim'
    const response = yield axios.post(newURL, action.payload.formData)
    if (process.env.REACT_APP_DEBUG) {
      console.log(response)
    }
    // Check response
    if (response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // Report error
        // Note in this case as the error logging failed, the errors will be written to console
        console.log('error: Warning Cannot Save Error (Code - ' + retData.data.returnText + ')')
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
      }
    } else {
      // Report error
      const retData = response
      // Note in this case as the error logging failed, the errors will be written to console
      console.log('error: Warning Cannot Save Error (Code - ' + retData.data.returnText + ')')
    }
  } catch (error) {
    // Note in this case as the error logging failed, the errors will be written to console
    console.log('error: Warning Cannot Save Error (Code - ' + error.message + ')')
    console.log(error)
  }
}

export function* putApiLog(action) {
  if (process.env.REACT_APP_DEBUG) {
    console.log('Saving API Log')
    console.log(action.payload)
  }
  // ok lets update the server
  try {
    const newURL = BASE_URL + '/apilog'
    const response = yield axios.post(newURL, JSON.stringify(action.payload))
    if (process.env.REACT_APP_DEBUG) {
      console.log(response)
    }
    // Check response
    // eslint-disable-next-line no-prototype-builtins
    if (response && response.hasOwnProperty('status') && response.status === 200) {
      const retData = response
      if (retData.data.returnStatus === 'Error') {
        // API has failed gracefully
        yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E1)', ErrorType: 'REDUX Function-1', Message: retData.data.returnText}})
      } else {
        if (process.env.REACT_APP_DEBUG) {
          console.log('ok saved')
        }
      }
    } else {
      // All getting complex here
      // Now check how much error information exists
      if (response) {
        const retData = response
        // eslint-disable-next-line no-prototype-builtins
        if (response.hasOwnProperty('status')) {
          // Ok we have a response and hopefully some data
          yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-Full Response', Message: response.status}})
          // Lets see how this goes
          yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-2', Message: retData.data.returnText}})
        } else {
          // Report response only
          yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-Response Only', Message: response}})
        }
      } else {
        // It is all bad - no response at all
        yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E2)', ErrorType: 'REDUX Function-No Response', Message: 'There was no response at all'}})
      }
    }
  } catch (error) {
    yield put({type: 'PUT_ERROR', payload: {Request: 'postClaimData (E3): ', ErrorType: 'REDUX Function-3', Message: error.message}})
  }
}

// Action Creators, return action objects with payloads
export const actions = {
  setMemberInitialState: () => ({type: actionTypes.SET_INITIAL}),
  setProfile: (payload) => ({type: actionTypes.SET_PROFILE, payload: payload}),
  fetchMemberProfile: (payload) => ({type: actionTypes.FETCH_MemberProfile, payload: payload}),
  putMemberProfile: (payload) => ({type: actionTypes.PUT_MemberProfile, payload: payload}),
  fetchFSBP: (payload) => ({type: actionTypes.FETCH_FSBP, payload: payload}),
  fetchDependents: (payload) => ({type: actionTypes.FETCH_Dependents, payload: payload}),
  fetchClaims: () => ({type: actionTypes.FETCH_Claims}),
  fetchRequest1095B: () => ({type: actionTypes.FETCH_1095BREQUESTS}),
  fetchClaim: (payload) => ({type: actionTypes.FETCH_Claim, payload: payload}),
  clearClaim: () => ({type: actionTypes.CLEAR_Claim}),
  fetchIncentives: () => ({type: actionTypes.FETCH_Incentives}),
  fetchIncentive: (payload) => ({type: actionTypes.FETCH_Incentive, payload: payload}),
  clearIncentive: () => ({type: actionTypes.CLEAR_Incentive}),
  fetchPayments: (payload) => ({type: actionTypes.FETCH_Payments, payload: payload}),
  fetchPaymentsOutstanding: () => ({type: actionTypes.FETCH_PaymentsOutstanding}),
  fetchNews: (payload) => ({type: actionTypes.FETCH_News, payload: payload}),
  fetchBlogs: (payload) => ({type: actionTypes.FETCH_Blogs, payload: payload}),
  fetchIBP: (payload) => ({type: actionTypes.FETCH_IBP, payload: payload}),
  fetchDisability: (payload) => ({type: actionTypes.FETCH_Disability, payload: payload}),
  fetchDental: (payload) => ({type: actionTypes.FETCH_Dental, payload: payload}),
  fetchGel: (payload) => ({type: actionTypes.FETCH_Gel, payload: payload}),
  fetchAdnd: (payload) => ({type: actionTypes.FETCH_Adnd, payload: payload}),
  fetchCI: (payload) => ({type: actionTypes.FETCH_CI, payload: payload}),
  fetchSLF: (payload) => ({type: actionTypes.FETCH_SLF, payload: payload}),
  fetchRequests: (payload) => ({type: actionTypes.FETCH_Requests, payload: payload}),
  fetchHBO: (payload) => ({type: actionTypes.FETCH_HBO, payload: payload}),
  fetchCountries: (payload) => ({type: actionTypes.FETCH_COUNTRIES, payload: payload}),
  fetchAETNA: () => ({type: actionTypes.FETCH_AETNA}),
  fetchElavonToken: (payload) => ({type: actionTypes.FETCH_ELAVONTOKEN, payload: payload}),
  putMessage: (payload) => ({type: actionTypes.PUT_MESSAGE, payload: payload}),
  putFormData: (payload) => ({type: actionTypes.PUT_FORM, payload: payload}),
  putIncentiveData: (payload) => ({type: actionTypes.PUT_INCENTIVE, payload: payload}),
  putClaimData: (payload) => ({type: actionTypes.PUT_CLAIM, payload: payload}),
  putBillingMessage: (payload) => ({type: actionTypes.PUT_BILLINGMESSAGE, payload: payload}),
  putDisabilityEnrolmentData: (payload) => ({type: actionTypes.PUT_DISABILITY, payload: payload}),
  fetchBillingUnread: (payload) => ({type: actionTypes.GET_BILLINGUNREAD, payload: payload}),
  fetchUnread: (payload) => ({type: actionTypes.GET_UNREAD, payload: payload}),
  messageRead: (payload) => ({type: actionTypes.PUT_READ, payload: payload}),
  fetchBillingMessages: (payload) => ({type: actionTypes.FETCH_BILLINGMESSAGES, payload: payload}),
  fetchOtherInsurance: () => ({type: actionTypes.FETCH_OTHERINSURANCE}),
  fetchFlags: (payload) => ({type: actionTypes.FETCH_FLAGS, payload: payload}),
  // fetchCreditCardTest:        (payload) =>  ({ type: actionTypes.FETCH_CREDITCARDTEST, payload : payload }),
  putRequest1095BFormData: (payload) => ({type: actionTypes.PUT_1095B, payload: payload}),
  fetchBillingMessageDownload: (payload) => ({type: actionTypes.FETCH_BILLINGMESSAGEFILE, payload: payload}),
  clearBillingMessageDownload: (payload) => ({type: actionTypes.CLEAR_BILLINGMESSAGEFILE, payload: payload}),

  disabilityformok: () => ({type: actionTypes.SET_DISABILITYFORMOK}),
  disabilityformclear: () => ({type: actionTypes.SET_DISABILITYFORMCLEAR}),
  disabilityformerror: () => ({type: actionTypes.SET_DISABILITYFORMERROR}),

  messageformok: () => ({type: actionTypes.SET_MESSAGEFORMOK}),
  messageformclear: () => ({type: actionTypes.SET_MESSAGEFORMCLEAR}),
  messageformerror: () => ({type: actionTypes.SET_MESSAGEFORMERROR}),

  creditCardTestStatusSet: () => ({type: actionTypes.SET_CREDITCARDTESTSTATUS}),

  elavontokencheckok: () => ({type: actionTypes.SET_ELAVONTOKENCHECKOK}),
  elavontokencheckclear: () => ({type: actionTypes.SET_ELAVONTOKENCHECKCLEAR}),
  elavontokencheckerror: () => ({type: actionTypes.SET_ELAVONTOKENCHECKERROR}),
  billingMessageRead: (payload) => ({type: actionTypes.PUT_BILLINGMESSAGEREAD, payload: payload}),
  putAPILog: (payload) => ({type: actionTypes.PUT_APILOG, payload: payload}),
  clearUSPSProfile: () => ({type: actionTypes.CLEAR_USPSPROFILE})
}

// Action Watchers
function* actionWatcher() {
  // yield takeLatest('SET_MEMBERID', doSomething())
  yield takeLatest('FETCH_MemberProfile', fetchMemberProfile)
  yield takeLatest('FETCH_AETNA', fetchAETNA)
  yield takeLatest('FETCH_BILLINGMESSAGES', fetchBillingMessages)
  yield takeLatest('FETCH_OTHERINSURANCE', fetchOtherInsurance)
  yield takeLatest('FETCH_ELAVONTOKEN', fetchElavonToken)
  yield takeLatest('PUT_MemberProfile', putMemberProfile)
  yield takeEvery('FETCH_FSBP', fetchPlan, 'FSBP', false)
  yield takeEvery('FETCH_Dependents', fetchPlan, 'Dependents', true)
  yield takeLatest('FETCH_Claims', fetchClaims)
  yield takeLatest('FETCH_1095BREQUESTS', fetch1095BRequests)
  yield takeLatest('FETCH_Claim', fetchClaim)
  yield takeLatest('FETCH_Incentives', fetchIncentives)
  yield takeLatest('FETCH_Incentive', fetchIncentive)
  yield takeLatest('FETCH_Payments', fetchPayments)
  yield takeLatest('FETCH_PaymentsOutstanding', fetchPaymentsOutstanding)
  yield takeLatest('FETCH_News', fetchNews)
  yield takeLatest('FETCH_Blogs', fetchBlogs)
  yield takeEvery('FETCH_SLF', fetchPlan, 'SLF', true)
  yield takeEvery('FETCH_IBP', fetchPlan, 'IBP', false)
  yield takeEvery('FETCH_Disability', fetchPlan, 'Disability', false)
  yield takeEvery('FETCH_Dental', fetchPlan, 'Dental', false)
  yield takeLatest('FETCH_Gel', fetchGel)
  yield takeLatest('FETCH_Adnd', fetchAdnd)
  yield takeLatest('FETCH_CI', fetchCI)
  yield takeEvery('FETCH_HBO', fetchHBO)
  yield takeEvery('FETCH_COUNTRIES', fetchCountries)
  yield takeLatest('FETCH_Requests', fetchRequests)
  yield takeLatest('PUT_MESSAGE', putMessage)
  yield takeLatest('PUT_FORM', putFormData)
  yield takeLatest('PUT_FORMFILE', putFormFile)
  yield takeLatest('PUT_CLAIM', postClaimData)
  yield takeLatest('PUT_INCENTIVE', postIncentiveData)
  yield takeLatest('PUT_DISABILITY', postDisabilityData)
  yield takeLatest('PUT_1095B', postRequest1095BData)
  yield takeLatest('PUT_ERROR', putError)
  yield takeLatest('PUT_APILOG', putApiLog)
  yield takeLatest('PUT_ERRORCLAIM', putErrorClaim)
  yield takeLatest('PUT_READ', putMessageRead)
  yield takeLatest('SET_VHLOGIN', fetchUnread)
  yield takeLatest('GET_UNREAD', fetchUnread)
  yield takeLatest('SET_UNREAD', fetchBillingUnread)
  yield takeLatest('GET_BILLINGUNREAD', fetchBillingUnread)
  yield takeLatest('FETCH_FLAGS', fetchFlags)
  yield takeLatest('PUT_BILLINGMESSAGEREAD', putBillingMessageRead)
  yield takeLatest('PUT_BILLINGMESSAGE', putBillingMessage)
  yield takeLatest('FETCH_BILLINGMESSAGEFILE', fetchBillingMessageDownload)
  // yield takeLatest('FETCH_CREDITCARDTEST',fetchCreditCardTest)
}

// Saga
export function* saga() {
  yield all([actionWatcher()])
}
