import {useEffect} from 'react'
import {Navigate, Routes, Route} from 'react-router-dom'
// import {useAuth} from './core/Auth'
import {useDispatch} from 'react-redux'
import * as Auth from '../../store/ducks/auth.duck'
import * as Member from '../../store/ducks/member.duck'
import * as Entity from '../../store/ducks/entity.duck'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(Auth.actions.logout())
    dispatch(Member.actions.setMemberInitialState())
    dispatch(Entity.actions.setEntityInitialState())
    // document.location.reload()
  }, [dispatch])

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/auth/login' />} />
    </Routes>
  )
}
